<ng-container *ngIf="isLoading">
  <div *ngFor="let item of dummyItems">
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let card of allCard">
  <div>
    <div class="all_card_list_main">
      <div class="icon_sec">
        <img src="../../../assets/images/icon-payment.svg" alt="icon-payment">
      </div>
      <div class="details_sec">
        <div class="details_sec_left">
          <h3>{{card.billing_details.name | titlecase}}</h3>
          <span>{{card.card.brand | uppercase}}</span>
          <span><strong>****</strong>{{card.card.last4}}</span>
        </div>
        <div class="details_sec_right">
          <button nz-button class="default" nz-tooltip nzTooltipTitle="Default"
            *ngIf="card.metadata.default !== undefined && card.metadata.default">
            <span nz-icon nzType="check" nzTheme="outline"></span>
            <!-- Default -->
          </button>
          <button nz-button class="make-default" nz-tooltip nzTooltipTitle="Make Default"
            *ngIf="card.metadata.default === undefined || !card.metadata.default" (click)="makeCardDefault(card.id)">
            <span nz-icon nzType="check" nzTheme="outline"></span>
            <!-- Make Default -->
          </button>
          <button nz-button nz-tooltip nzTooltipTitle="Delete" class="delete-card margin-top-1"
            *ngIf="card.metadata.default === undefined || !card.metadata.default"
            (click)="deleteCard(card.id)" [class.disabled_while_loading]="isLoading">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            <!-- Delete -->
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
</ng-container>
