<nz-tabset class="tabset_default my_work_tabset" [nzSelectedIndex]="selectedTab" (nzSelectedIndexChange)="tabChanged($event)">
    <nz-tab nzTitle="Assigned to me">
        <ng-container *ngIf="assignedPosts.length != 0; else noData">
            <div class="my_work_list" nz-menu>
                <div class="my_work_list_in" nz-menu-item *ngFor="let post of assignedPosts">
                    <div class="my_work_list_left">
                        <div class="img_found" *ngIf="post.post_gallery.length > 0">
                            <app-image-preview
                                [class]="post.post_gallery[0].file_type ==='video' ? 'manage-post-video':''"
                                [isVideo]="post.post_gallery[0].file_type ==='video'"
                                [imageUrl]="post.post_gallery[0].media_file">
                            </app-image-preview>
                        </div>
                        <div class="img_not_found" *ngIf="post.post_gallery.length === 0">
                            <img src="../../assets/images/placeholder_image.png" alt="">
                        </div>
                    </div>
                    <div class="my_work_list_right">
                        <div class="my_work_top">
                            <div class="my_work_top_left">
                                <app-provider [className]="'social-icon'" [iconSize]="sharedService.mediumIcon" [provider]="post.provider"></app-provider>
                                <span>Post</span>
                            </div>
                            <div class="my_work_top_right">{{post.post_time * 1000 | dateConvertPipe}}</div>
                        </div>
                        <div class="my_work_title">
                            <h5>{{post.content}}</h5>
                        </div>
                        <div class="my_work_status">
                            <span>{{post.post_status | titlecase}}</span>
                            <span>
                                <img [src]="'../../../assets/images/'+sharedService.priorities[post?.priority-1]?.image"
                                    alt="">
                                {{sharedService.priorities[post?.priority-1]?.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="custome_default_msg">
                <img src="../../../assets/images/icon-my-work.svg" alt="">
                <span>No<strong>#My work</strong>Yet!</span>
            </div>
        </ng-template>
    </nz-tab>
    <nz-tab nzTitle="Recent">
        <ng-container *ngIf="recentPosts.length != 0; else noData">
            <div class="my_work_list" nz-menu>
                <div class="my_work_list_in" nz-menu-item *ngFor="let post of recentPosts">
                    <div class="my_work_list_left">
                        <div class="img_found" *ngIf="post.post_gallery.length > 0">
                            <app-image-preview
                                [class]="post.post_gallery[0].file_type ==='video' ? 'manage-post-video':''"
                                [isVideo]="post.post_gallery[0].file_type ==='video'"
                                [imageUrl]="post.post_gallery[0].media_file">
                            </app-image-preview>
                        </div>

                        <div class="img_not_found" *ngIf="post.post_gallery.length === 0">
                            <img src="../../assets/images/placeholder_image.png" alt="">
                        </div>

                    </div>
                    <div class="my_work_list_right">
                        <div class="my_work_top">
                            <div class="my_work_top_left">
                                <app-provider [className]="'social-icon'" [iconSize]="sharedService.mediumIcon" [provider]="post.provider"></app-provider>
                                <span>Post</span>
                            </div>
                            <div class="my_work_top_right">{{post.post_time * 1000 | dateConvertPipe}}</div>
                        </div>
                        <div class="my_work_title">
                            <h5>{{post.content}}</h5>
                        </div>
                        <div class="my_work_status">
                            <span>{{post.post_status | titlecase }}</span>
                            <span>
                                <img [src]="'../../../assets/images/'+sharedService.priorities[post?.priority-1]?.image"
                                    alt="">
                                {{sharedService.priorities[post?.priority-1]?.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="custome_default_msg">
                <img src="../../../assets/images/icon-my-work.svg" alt="">
                <span>No<strong>#My work</strong>Yet!</span>
            </div>
        </ng-template>
    </nz-tab>
</nz-tabset>