<nz-card nzTitle="Preview Post" class="card sliderBtnWrapper" *ngIf="from!==''">
  <div class="preview_post_wrp_data">
    <div class="preview_post_header">
      <div class="preview_post_hdr_left">
        <div class="img_wrp">
          <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false">
          </app-image-preview>
          <app-provider [className]="'social-icon'" [iconSize]="sharedService.mediumIcon" [provider]="provider"></app-provider>
        </div>
        <div class="title_wrp">
          <strong>{{name}}</strong>
        </div>
        <div class="time_zone">
          <span>{{time}}</span>
        </div>
      </div>
    </div>
    <div class="preview_post_body emoji_text">
      <p *ngIf="content" class="contentBody">{{content}} </p>
      <br>
      <p *ngIf="title"><b>Title :</b> {{title}}</p>
      <br>

      <!--*ngIf="type === 'image' || type === 'gif'"-->
      <div class="post_img_structure">
        <div class="post_img_structure_in" [ngClass]="post_img_class">
          <ng-container *ngFor="let media of postMedias; let i = index">
            <div *ngIf="i < 4" (click)="preview(i + 1)">
              <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="media.file_type==='video'"></app-image-preview>
              <span>+{{postMedias.length - 3}}</span>
            </div>
          </ng-container>
        </div>
      </div>

      <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
        <div class="upload_video">
          <div class="upload_video_in">
            <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-video" [isVideo]="true">
            </app-image-preview>
          </div>
        </div>
      </ng-container>-->

    </div>

    <ng-container *ngIf="comment !== null &&  comment !== ''&& comment !== undefined">
      <div class="comment-section">
        <div nz-row class="comment-inner">
          <div nz-col nzSpan="3">
            <div class="img_wrp">
              <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false">
              </app-image-preview>
            </div>
          </div>
          <div nz-col nzSpan="21" class="comment-wrap">
            <div class="title">
              <strong>{{name}}</strong>
            </div>
            <div class="comment">
              <p>{{comment}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</nz-card>
<!-- card end -->
<nz-carousel class="nzcarouselCNcustom" [nzEffect]="effect"
             [nzDots]="displaySliderItem && userData.length > 1 ? true  : false" [nzAutoPlay]="false"
             [nzEnableSwipe]="userData.length > 1 ? true  : false" *ngIf="from===''"
             (nzBeforeChange)="previewSliderChanges($event)">

  <div nz-carousel-content *ngFor="let item of userData; let i = index">

    <div class="pre_content_social" *ngIf="displayPlatformDetails">
      <div *ngIf="item.provider === sharedService.twitterProvider">
        <img src="../../../assets/images/providers/medium/TWITTER.png" alt="Twitter Logo" class="social-icon">
        <span>X</span>
      </div>
      <div *ngIf="item.provider === sharedService.linkedinProvider">
        <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="social-icon">
        <span>LinkedIn</span>
      </div>
      <div *ngIf="item.provider === sharedService.facebookProvider">
        <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="social-icon">
        <span>Facebook</span>
      </div>
      <div *ngIf="item.provider === sharedService.instagramProvider">
        <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="social-icon">
        <span>Instagram</span>
      </div>
      <div *ngIf="item.provider === sharedService.youtubeProvider">
        <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="social-icon">
        <span>YouTube</span>
      </div>
      <div class="tiktok-beta-preview" *ngIf="item.provider === sharedService.tiktokProvider">
        <img src="assets/images/tiktok.svg" alt="tiktok" class="social-icon">
        <span>TikTok</span>
        <span class="beta-tag">Beta</span>
      </div>
      <div *ngIf="item.provider === sharedService.pinterestProvider">
        <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="social-icon">
        <span>Pinterest</span>
      </div>
      <div *ngIf="item.provider === sharedService.threadsProvider">
        <img src="../../../assets/images/providers/medium/THREADS.png" alt="Threads Logo" class="social-icon">
        <span>Threads</span>
      </div>

      <div class="btn_main" *ngIf="displayAction">
        <button class="ant-btn btn_edit" title="Edit Post" (click)="editPost(id)" *ngIf="post.isEdit">
          <img src="../../../assets/images/edit_icon_2.svg" alt="">
        </button>
        <button class="ant-btn btn_delete" title="Delete Post" (click)="deletePost(id)" *ngIf="post.isDelete">
          <img src="../../../assets/images/trash-icon.svg" alt="">
        </button>
      </div>

    </div>

    <div class="pre_sec"
      *ngIf="item && (item.provider !== sharedService.instagramProvider && item.provider !== sharedService.facebookProvider) || (instagramType === 'feed_post' && item.provider === sharedService.instagramProvider) || (facebookType === 'feed_post' && item.provider === sharedService.facebookProvider) "
      [class]="platformClass[item.provider]">
      <!-- [class]="platformClass[item.provider]" -->

      <ng-container *ngIf="item.provider === sharedService.youtubeProvider">
        <div class="youtube_video_sec">

          <!--*ngIf="postMedias.length > 0 && !isYoutubePost  && (type === 'video' || type==='multi')"-->
          <ng-container *ngIf="postMedias.length > 0 && !isYoutubePost">
            <div class="upload_video">
              <div class="upload_video_in yt_media_main">
                <a href="javascript:;" class="play_icon" (click)="playYTVideo()" *ngIf="YTThumbnail!==''">
                  <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube">
                </a>
                <ng-container *ngIf="YTThumbnail!==''">
                  <app-image-preview [imageUrl]="YTThumbnail" class="preview-post-video" [isVideo]="false"></app-image-preview>
                </ng-container>
                <ng-container *ngIf="YTThumbnail===''">
                  <app-image-preview [imageUrl]="postMedias[0]?.media_file" class="preview-post-video" [isVideo]="true" [forceAutoPlay]="autoPlay"></app-image-preview>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video' && isYoutubePost && postId !== ''">-->
          <ng-container *ngIf="postMedias.length > 0 && isYoutubePost && postId !== ''">
            <ng-container *ngIf="!isIframeLoaded && YTThumbnail===''">
              <div class="single_row_skeleton">
                <nz-skeleton [nzParagraph]="{ rows: 0 }" [nzActive]="true"></nz-skeleton>
              </div>
            </ng-container>
            <div class="yt_media_main">
              <a href="javascript:;" class="play_icon" (click)="playYTVideo()" *ngIf="YTThumbnail!==''">
                <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube">
              </a>
              <ng-container *ngIf="YTThumbnail!==''">
                <app-image-preview [imageUrl]="YTThumbnail" class="preview-post-video" [isVideo]="false"></app-image-preview>
              </ng-container>
              <ng-container *ngIf="YTThumbnail===''">
                <iframe width="100%" height="345" [src]="youtubeVideoUrl" (load)="isIframeLoaded=true" [class.hide_div]="!isIframeLoaded"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
          </div>
          <div class="pre_user_right">
            <div class="youtube_video_title">
              <p *ngIf="title"><strong>{{title}}</strong></p>
              <!--<p *ngIf="content"> {{content}} </p>-->
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <p *ngIf="otherContent" [innerHTML]="otherContent"></p>
              </ng-container>
            </div>
            <div class="title">
              <strong>{{item.name }}</strong>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.tiktokProvider">
        <div class="tiktok-trends">
          <div class="content-main">
            <div class="content-header">
              <p class="time-sec">2:11 pm</p>
              <div class="action-display">
                <img src="../../../assets/images/tiktok-action1.svg" alt="">
                <img src="../../../assets/images/tiktok-action2.svg" alt="">
                <img src="../../../assets/images/tiktok-action3.svg" alt="">
              </div>
            </div>
            <div class="content-body">
              <div class="content-area">
                <div class="user-info">
                  <span>@{{item.name}}</span>
                  <ng-container *ngIf="isContentLoading">
                    <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
                  </ng-container>
                  <ng-container *ngIf="!isContentLoading">
                    <span [innerHTML]="tiktokContent"></span>
                  </ng-container>
                </div>
                <div class="post-music">
                   <img src="../../../assets/images/tiktok-action4.svg" alt="">
                  <p>Post music will appear here</p>
                </div>
              </div>
              <div class="tiktok-info">
                <div class="user-profile">
                  <span *ngIf="item.image !== ''; else userProfile">
                    <div class="pre_user_left">
                      <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
                    </div>
                  </span>
                  <ng-template #userProfile>
                    <span>{{item.namecharAt(0)}}</span>
                  </ng-template>
                  <div class="plus-icon">
                     <img src="../../../assets/images/tiktok-action5.svg" alt="">
                  </div>
                </div>
                <div class="info-icons">
                  <img src="../../../assets/images/tiktok-action6.svg" alt="">
                  <img src="../../../assets/images/tiktok-action7.svg" alt="">
                  <img src="../../../assets/images/tiktok-action8.svg" alt="">
                  <img src="../../../assets/images/tiktok-action9.svg" alt="">
                </div>
              </div>
            </div>
            <div class="content-footer">
              <img src="../../../assets/images/tiktok-action.svg" alt="">
            </div>
          </div>
          <div class="img_sec" [class.no_tiktok_media]="postMedias.length === 0">
            <ng-container *ngIf="postMedias.length > 0">
              <nz-carousel [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'video'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'video'">
                    <app-image-preview *ngIf="media.file_type=== 'video'" [imageUrl]="media.media_file" [isVideo]="true"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>
              <nz-carousel class="tiktok-trends-image" [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'image'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'image'">
                    <app-image-preview *ngIf="media.file_type=== 'image'" [imageUrl]="media.media_file" class="preview-post-video" [isVideo]="false"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>
            </ng-container>
          </div>
        </div>


        <!-- <div class="tiktok_preview">
          <div class="img_sec" [class.no_tiktok_media]="postMedias.length === 0">
            <ng-container *ngIf="postMedias.length > 0">
              <nz-carousel [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'video'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'video'">
                    <app-image-preview *ngIf="media.file_type=== 'video'" [imageUrl]="media.media_file" class="manage-post-video" [isVideo]="true"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>

              <nz-carousel [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'image'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'image'">
                    <app-image-preview *ngIf="media.file_type=== 'image'" [imageUrl]="media.media_file" class="preview-post-video" [isVideo]="false"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>
            </ng-container>
          </div>

          <div class="content_sec">
            <div class="content_left">
              <span>@{{item.name}}</span>
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <span [innerHTML]="tiktokContent"></span>
              </ng-container>
            </div>
            <div class="content_right">
              <div class="content_right_in">
                <div class="tiktok_user_top">
                  <span *ngIf="item.image !== ''; else userProfile">
                    <div class="pre_user_left">
                      <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
                    </div>
                  </span>
                  <ng-template #userProfile>
                    <span>{{item.namecharAt(0)}}</span>
                  </ng-template>
                </div>
                <div class="tiktok_icons">
                  <img src="../../../assets/images/icon-heart-white.svg" alt="">
                  <img src="../../../assets/images/icon-comment.svg" alt="">
                  <img src="../../../assets/images/icon-share.svg" alt="">
                </div>
                <div class="tiktok_user_bottom">
                  <span *ngIf="item.image !== ''; else userProfile">
                    <div class="pre_user_left">
                      <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.pinterestProvider">
        <div class="pinterest_img_sec" [class.default_active]="postMedias.length === 0">

          <!--*ngIf="type === 'image' || type === 'gif'"-->
          <ng-container>
            <!--instagram-->
            <!-- <ng-container *ngIf="item.provider === sharedService.instagramProvider">
              <nz-carousel nzEffect="transform-no-loop" [nzDots]="postMedias.length > 1">
                <div nz-carousel-content *ngFor="let media of postMedias; let i = index">
                  <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="media.file_type==='video'"></app-image-preview>
                </div>
              </nz-carousel>
            </ng-container> -->

            <!--Other then instagram-->
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="post_img_structure_in" [ngClass]="post_img_class">
                <!-- <ng-container *ngIf="postMedias.length > 0">
                    <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-img" [isVideo]="postMedias[0].file_type==='video'"></app-image-preview>
                </ng-container> -->
                <ng-container *ngIf="postMedias.length > 0">
                  <ng-container *ngIf="postMedias[0].file_type=== 'image'">
                    <app-image-preview [imageUrl]="postMedias[0]?.media_file" class="preview-post-img" [isVideo]="false"></app-image-preview>
                  </ng-container>
                  <ng-container *ngIf="postMedias[0].file_type=== 'video'">
                    <app-image-preview [imageUrl]="postMedias[0]?.media_file" class="preview-post-img" [isVideo]="true"></app-image-preview>
                  </ng-container>
                </ng-container>

              </div>
            </ng-container>
          </ng-container>


          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
            <div class="upload_video">
              <div class="upload_video_in">
                <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-video" [isVideo]="true">
                </app-image-preview>
              </div>
            </div>
          </ng-container>-->

          <div class="pinterest_img_sec_over_sec">
            <div class="over_sec_left">
              <span> 
                <img src="../../../assets/images/icon-pinterest-check.svg" alt="">
              </span>
              <span> 
                <img src="../../../assets/images/icon-pinterest-edit.svg" alt="">
              </span>
            </div>
            <div class="over_sec_right">
              <span>
                <img src="../../../assets/images/icon-pinterest-ping.svg" alt="">
                Save
              </span>
            </div>
          </div>
        </div>

        <div class="des_sec emoji_text">
          <h1>{{title}}</h1>
          <!--<p *ngIf="content">{{content}} </p>-->
          <ng-container *ngIf="isContentLoading">
            <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
          </ng-container>
          <ng-container *ngIf="!isContentLoading">
            <p *ngIf="otherContent" [innerHTML]="otherContent"></p>
          </ng-container>
          <!-- <p *ngIf="title">{{title}}</p> -->
        </div>

        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
          </div>
          <div class="pre_user_right">
            <div class="title">
              <strong>{{item.name }}</strong>
            </div>
            <div class="board_name">
              <span>{{ boardName[item.name] }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.pinterestProvider
      && item.provider !== sharedService.youtubeProvider && item.provider !== sharedService.tiktokProvider">
        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            <div class="social-icon-main" *ngIf="!displayActions">
              <!-- <img src="assets/images/Twitter.png" alt="Twitter Logo" class="social-icon"
                *ngIf="post.provider === sharedService.twitterProvider"> -->
              <img *ngIf="item.provider === sharedService.linkedinProvider" src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="social-icon" />
              <img *ngIf="item.provider === sharedService.facebookProvider" src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="social-icon" />
              <img *ngIf="item.provider === sharedService.instagramProvider" src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="social-icon" />
            </div>
          </div>

          <div class="pre_user_right">
            <div class="title">
              <strong>{{item.name }}</strong>

              <!--Location-->
              <span *ngIf="item.provider === sharedService.facebookProvider && selectedLocation">
                is at <a href="javascript:;">{{ selectedLocation.name }}</a>
              </span>

              <span *ngIf="item.provider === sharedService.instagramProvider && selectedLocation">
                <br><p>{{ selectedLocation.name }}</p>
              </span>
            </div>
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="time_zone"> <span>{{time}}</span> </div>
            </ng-container>

            <div class="post_action_only_top">
              <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
                <img src="../../../assets/images/icon-platform-option.svg" alt="">
              </ng-container>
              <ng-container *ngIf="item.provider === sharedService.instagramProvider">
                <img src="../../../assets/images/ig-menu.svg" alt="">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.instagramProvider && item.provider !== sharedService.pinterestProvider
              && item.provider !== sharedService.youtubeProvider &&  item.provider !== sharedService.tiktokProvider">
        <div class="des_sec emoji_text">
          <!--<p *ngIf="content"> {{content}} </p>-->

          <ng-container *ngIf="isContentLoading">
            <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
          </ng-container>
          <ng-container *ngIf="!isContentLoading">
            <ng-container *ngIf="availablePlatformForMention.includes(item.provider)">
              <ng-container *ngIf="item.provider === this.sharedService.facebookProvider">
                <p *ngIf="content" [innerHTML]="facebookContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.instagramProvider">
                <p *ngIf="content" [innerHTML]="instagramContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.twitterProvider">
                <p *ngIf="content" [innerHTML]="twitterContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.threadsProvider">
                <p *ngIf="content" [innerHTML]="threadsContent"></p>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!availablePlatformForMention.includes(item.provider)">
              <p *ngIf="item.provider == this.sharedService.linkedinProvider" [innerHTML]="linkedinContent"></p>
              <p *ngIf="content && item.provider !== this.sharedService.linkedinProvider" [innerHTML]="otherContent"></p>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.pinterestProvider && item.provider !== sharedService.youtubeProvider
      && item.provider !== sharedService.tiktokProvider">
        <div class="img_sec">
          <!--*ngIf="type === 'image' || type === 'gif' || type === 'multi'"-->
          <ng-container>
            <!--instagram-->
            <ng-container *ngIf="item.provider === sharedService.instagramProvider">
              <!-- <nz-carousel nzEffect="transform-no-loop"> -->
                <!-- <div nz-carousel-content *ngFor="let img of images; let i = index">
                  <app-image-preview [imageUrl]="img" class="preview-post-img" [isVideo]="false"></app-image-preview>
                </div>-->
                <div *ngIf="isFeedPost">
                  <nz-carousel nzEffect="transform-no-loop">
                    <div nz-carousel-content *ngFor="let media of images; let i = index">
                      <app-image-preview [imageUrl]="media" [class]="type==='video' ? 'preview-post-video' : 'preview-post-img' " [isVideo]="type==='video'"></app-image-preview>
                    </div>
                  </nz-carousel>
                </div>
                <div *ngIf="!isFeedPost">
                  <nz-carousel nzEffect="transform-no-loop">
                    <div nz-carousel-content *ngFor="let media of postMedias; let i = index">
                      <app-image-preview [imageUrl]="media.media_file" [class]="media.file_type==='video' ? 'preview-post-video' : 'preview-post-img' " [isVideo]="media.file_type==='video'"></app-image-preview>
                    </div>
                  </nz-carousel>
                </div> 
               <!-- </nz-carousel> -->
            </ng-container>


            <!--Other then instagram-->
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="post_img_structure_in" [class]="platformClass[item.provider]">
                <ng-container *ngIf="images.length>0 && isFeedPost">
                  <ng-container *ngIf="type==='image'">
                    <ng-container *ngFor="let media of images; let i = index">                    
                        <app-image-preview [imageUrl]="media" class="preview-post-img" [isVideo]="false"></app-image-preview>         
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="type==='video'">
                    <div (click)="preview(i + 1)">
                      <app-image-preview [imageUrl]="images[0]" class="preview-post-img" [isVideo]="true"></app-image-preview>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="postMedias.length>0 && !isFeedPost">
                  <ng-container *ngIf="postMedias[0].file_type==='image'">
                    <ng-container *ngFor="let media of filteredPostMedias; let i = index">
                      <div class="image-preview" *ngIf="i < 4 && media.file_type === 'image'" (click)="preview(i + 1)">
                        <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="false"></app-image-preview>
                        <span *ngIf="postMedias.length > 4"><b>+{{postMedias.length - 4}}</b></span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="postMedias[0].file_type==='video'">
                    <div class="video-wrapper" (click)="preview(i + 1)">
                      <app-image-preview [imageUrl]="postMedias[0]?.media_file" class="preview-post-img" [isVideo]="true"></app-image-preview>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
            <div class="upload_video">
              <div class="upload_video_in">
                <app-image-preview [imageUrl]="postMedias[0]" class="preview-post-video" [isVideo]="true"></app-image-preview>
              </div>
            </div>
          </ng-container>-->
        </div>
      </ng-container>

      <ng-container [class]="platformClass[item.provider]">
        <ng-container *ngIf="displayActions && item.provider === sharedService.facebookProvider">
          <div class="post_action_viewonly">
            <div class="like_sec">
              <img src="../../../assets/images/fb-like.svg" alt="Like" />
              <span>Like</span>
            </div>
            <div class="comnt_sec">
              <img src="../../../assets/images/fb-comment.svg" alt="Comment" />
              <span>Comment</span>
            </div>
            <div class="share_sec">
              <img src="../../../assets/images/fb-share.svg" alt="Share" />
              <span>Share</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.twitterProvider">
          <div class="twitter_action_view_only">
            <div> <img src="../../../assets/images/tw-reply.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-retweet.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-like.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-direct-message.svg" alt="" /> </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.linkedinProvider">
          <div class="linkedin_action_view_only">
            <div>
              <img src="../../../assets/images/li-like.svg" alt="Like" />
              <span>Like</span>
            </div>
            <div>
              <img src="../../../assets/images/li-comment.svg" alt="Comment" />
              <span>Comment</span>
            </div>
            <div>
              <img src="../../../assets/images/li-share.svg" alt="Share" />
              <span>Share</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.instagramProvider">
          <div class="instagram_action_view_only">
            <div class="instagram_action_view_only_left">
              <div> <img src="../../../assets/images/ig-like-heart.svg" alt="" /> </div>
              <div> <img src="../../../assets/images/ig-comment.svg" alt="" /> </div>
              <div> <img src="../../../assets/images/ig-share.svg" alt="" /> </div>
            </div>
            <div class="instagram_action_view_only_right">
              <div> <img src="../../../assets/images/ig-bookmark.svg" alt="" /> </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.threadsProvider">
          <div class="twitter_action_view_only threds_icon">
            <div> <img src="../../../assets/images/th-like.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-comment.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-repost.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-share.svg" alt="" /> </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.instagramProvider">
        <div class="insta_content">
          <div class="title">
            <strong>{{item.name }}</strong>
          </div>
          <div class="des_sec emoji_text">
            <!--<p *ngIf="content">{{content}} </p>-->

            <ng-container *ngIf="isContentLoading">
              <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </ng-container>
            <ng-container *ngIf="!isContentLoading">
              <p *ngIf="instagramContent" [innerHTML]="instagramContent"></p>
            </ng-container>
          </div>
          <div class="time_zone">
            <span>{{time}}</span>
          </div>
        </div>
      </ng-container>

      <nz-comment *ngIf="((item.provider === sharedService.youtubeProvider && audience === 'false')  && firstComment) ||
          ((item.provider === sharedService.facebookProvider)  && firstComment && item.type !== sharedService.group) ||
          ((item.provider === sharedService.instagramProvider)  && firstComment) ||
          ((item.provider === sharedService.linkedinProvider)  && firstComment)  " class="commentBox">
        <div class="preview_post_wrp">
          <div class="preview_post_header">
            <div class="preview_post_hdr_left">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
            <div class="preview_post_hdr_right">
              <div class="title">
                <strong>{{item.name }}</strong>
              </div>
              <nz-comment-content>
                <p class="contentBody">{{ firstComment.trim() }}</p>
              </nz-comment-content>
            </div>
          </div>
        </div>
      </nz-comment>

      <ng-container *ngIf="comment !== null &&  comment !== ''&& comment !== undefined">
        <div class="comment-section">
          <div nz-row class="comment-inner">
            <div nz-col nzSpan="3">
              <div class="img_wrp">
                <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
            </div>
            <div nz-col nzSpan="21" class="comment-wrap">
              <div class="title"> <strong>{{name}}</strong> </div>
              <div class="comment"> <p>{{comment}}</p> </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="item && instagramType === 'reel_post' && item.provider === sharedService.instagramProvider">
      <div class="insta_reel_main">
        <a href="javascript:;" class="play_reel_icon" (click)="playReel()" *ngIf="!autoPlay">
          <img src="../../../assets/images/icon-reel-play.svg" alt="">
        </a>

        <div class="img_sec">
          <!--Instagram Reel Thumbnail-->
          <ng-container *ngIf="instaReelThumbnail!==''">
            <app-image-preview [imageUrl]="instaReelThumbnail" class="manage-post-video" [isVideo]="false" ></app-image-preview>
          </ng-container>

          <ng-container *ngIf="instaReelThumbnail===''" && postMedias.length>
            <app-image-preview class="manage-post-video" [isVideo]="true" [imageUrl]="(isFeedPost)? images[0] :postMedias[0]?.media_file" [forceAutoPlay]="autoPlay"></app-image-preview>
          </ng-container>
        </div>

        <div class="con_main">
          <div class="con_left">
            <div class="reel_user">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              <a href="javascript:;"><h5>{{item.name }}</h5></a>
            </div>
            <div class="reel_des emoji_text">
              <!--<p *ngIf="content">{{content}} </p>-->
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <p *ngIf="instagramContent" [innerHTML]="instagramContent"></p>
              </ng-container>
            </div>
            <div class="reel_music">
              <img src="../../../assets/images/icon-ig-music-view.svg" alt="">
              <span>{{item.name}}</span>
              <span>Original Audio</span>
            </div>
          </div>

          <div class="con_right">
            <img src="../../../assets/images/icon-ig-actions.svg" alt="">
            <div class="con_right_user">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="comment">
        <nz-comment class="commentBox reel_commentBox">
          <div class="preview_post_wrp">
            <div class="preview_post_header">
              <div class="preview_post_hdr_left">
                <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
              <div class="preview_post_hdr_right">
                <div class="title"><strong>{{item.name }}</strong></div>
                <nz-comment-content><p class="contentBody">{{comment}}</p></nz-comment-content>
              </div>
            </div>
          </div>
        </nz-comment>
      </ng-container>

      <ng-container *ngIf="firstComment">
        <nz-comment class="commentBox reel_commentBox">
          <div class="preview_post_wrp">
            <div class="preview_post_header">
              <div class="preview_post_hdr_left">
                <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
              <div class="preview_post_hdr_right">
                <div class="title"><strong>{{item.name }}</strong></div>
                <nz-comment-content><p class="contentBody">{{firstComment}}</p></nz-comment-content>
              </div>
            </div>
          </div>
        </nz-comment>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item && instagramType === 'story_post' && item.provider === sharedService.instagramProvider">
      <div class="insta_story_wrapper">
        <!-- {{ type }} -->
        <nz-carousel nzEffect="transform-no-loop" [nzDots]="postMedias.length > 1" (nzAfterChange)="onInstaStoryChange($event)">
          <div nz-carousel-content *ngFor="let media of (isFeedPost) ? images : postMedias; let i = index">
            <div class="insta_story">
              <app-image-preview [imageUrl]="(isFeedPost) ? images[0] : media.media_file" class="manage-post-video" [isVideo]="(isFeedPost) ? type==='video' : media.file_type==='video'" [forceAutoPlay]="postId !== ''"></app-image-preview>
            </div>
          </div>
        </nz-carousel>

        <div class="insta_content">
          <div class="insta_story_indicator_main">
            <div class="insta_story_indicator_in" *ngFor="let media of postMedias;let j = index" [class.active]="currentStoryIndex === j"></div>
          </div>
          <div class="insta_user">
            <div class="img_sec">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
            <div class="content_sec"><p>{{item.name}}</p></div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="item && facebookType === 'story_post' && item.provider === sharedService.facebookProvider">
      <div class="insta_story_wrapper">
        <!-- {{ type }} -->
        <nz-carousel nzEffect="transform-no-loop" [nzDots]="postMedias.length > 1" (nzAfterChange)="onInstaStoryChange($event)">
          <div nz-carousel-content *ngFor="let media of (isFeedPost) ? images : postMedias; let i = index">
            <div class="insta_story">
              <app-image-preview [imageUrl]="(isFeedPost) ? images[0] : media.media_file" class="manage-post-video" [isVideo]="(isFeedPost) ? type==='video' : media.file_type==='video'" [forceAutoPlay]="postId !== ''"></app-image-preview>
            </div>
          </div>
        </nz-carousel>

        <div class="insta_content">
          <div class="insta_story_indicator_main">
            <div class="insta_story_indicator_in" *ngFor="let media of postMedias;let j = index" [class.active]="currentStoryIndex === j"></div>
          </div>
          <div class="insta_user">
            <div class="img_sec">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
            <div class="content_sec"><p>{{item.name}}</p></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</nz-carousel>


<!--Validation Error-->
<div *ngIf="validationErrors.length>0 && !isSinglePlatformMode" class="social_error_main">
  <div class="social_error_in" *ngFor="let validationError of validationErrors">
    <div *ngIf="validationError.errors.length > 0">
      <ul [class]="'social_error_list ul_'+validationError.type">
        <li *ngFor="let error of validationError.errors" class="error-color">
          <app-provider [iconSize]="sharedService.mediumIcon" [className]="'preview_post_social_error_icon'" [provider]="validationError.type"></app-provider>
          <!-- <img src="../../../assets/images/error-info.png" alt=""> -->
          <span>{{ error }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="validationErrors.length>0 && isSinglePlatformMode" class="social_error_main">
  <div class="social_error_in" *ngFor="let validationError of validationErrors">
    <div *ngIf="validationError.errors.length > 0">
      <ul class="social_error_list new_social_error_list">
        <li *ngFor="let error of validationError.errors" class="error-color">
          <div class="info-icon">

            <img src="../../../assets/images/error-info.png" alt="">
          </div>
          <span>{{ error }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>


<!--Post Error-->
<div *ngIf="postErrors?.length>0" class="social_error_main">
  <div class="social_error_in" *ngFor="let validationError of postErrors">
    <div *ngIf="validationError.errors.length > 0">
      <ul [class]="'social_error_list ul_'+validationError.type">
        <li *ngFor="let error of validationError.errors" class="error-color">
          <app-provider [iconSize]="sharedService.mediumIcon" [className]="'preview_post_social_error_icon'" [provider]="validationError.type"></app-provider>
          <strong>{{validationError.name}}</strong>
          <p>{{ error }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
