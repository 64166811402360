<nz-layout class="app-layout" (window:resize)="onResize($event)">
  <nz-sider [class.subscriptionSidebar]="!subscription" class="menu-sidebar" nzCollapsible nzWidth="250px"
            nzBreakpoint="lg" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <div class="res_hdr_sidebar">
      <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
        <i class="trigger" nz-icon nzTheme="outline" [nzType]="isCollapsed ? 'right' : 'left'"></i>
      </span>
    </div>


    <ng-container *ngIf="!sharedService.isTablet">
      <div class="app-sidebar-user" >
        <div class="app-sidebar-user-in" nz-dropdown nzTrigger="click" [nzDropdownMenu]="orgPopover"
          (click)="isOrgPopoverOpen()" [class.multiple-org]="allOrganizations.length > 1"
           joyrideStep="org_s1" [stepContent]="customContent">
           <!-- [nzVisible]="isVisible" (nzVisibleChange)="handleVisibleChange($event)" -->
          <div class="img_sec">
            <app-image-preview [imageUrl]="selectedOrganization?.icon" [organizationProfile]="true" [isVideo]="false">
            </app-image-preview>
          </div>
          <div class="content_sec" >
            <!-- (click)="SortModal()" -->
            <h5 class="text-16-bold">
              {{ selectedOrganization?.organization_name }}
            </h5>
          </div>
        </div>
        <a href="javascript:;" class="res_btn_close" (click)="isCollapsed = !isCollapsed"></a>
                
        <nz-dropdown-menu #orgPopover="nzDropdownMenu">
          <div class="org-popover"  joyrideStep="org_s2" [stepContent]="customContentOrg_s2">
            <ng-container *ngFor="let organization of allOrganizations">
              <nz-radio-group class="org_optn_list_wrap" [(ngModel)]="selectedOrganizationModal">
                <div class="org_optn_list" >
                  <div class="org_optn_list_in">
                    <div class="org_optn_list_in_left">
                      <label nz-radio [nzValue]="organization">
                        <div class="org_optn_list_main">
                          <div class="org_optn_list_left">
                            <app-image-preview [imageUrl]="organization?.icon" [organizationProfile]="true"
                                              [class]="'organization-list-profile'" [isVideo]="false">
                            </app-image-preview>
                          </div>
                          <div class="org_optn_list_right">
                            <h3>{{ organization?.organization_name }}</h3>
                            <span *ngIf="organization.owner_id === user.id">
                              <span nz-icon nzType="star" nzTheme="fill"></span>
                              Super Admin
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="org_optn_list_in_right">
                      <a *ngIf="organization.owner_id !== user.id" (click)="leaveOrganization(user.id, organization.id)">
                        <img src="../../assets/images/icon-switch.svg" alt="">
                      </a>
                      <ng-container *ngIf="organization.owner_id === user.id">
                        <a (click)="showOrganizationModal(organization)">
                          <img src="../../../../assets/images/edit_icon_2.svg" alt="" />
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </nz-radio-group>
            </ng-container>
            <div class="org-popover-action">
              <button type="button" class="btn_switch btn_primary" (click)="closeOrganisation($event)"
              [disabled]="allOrganizations.length === 1">Switch Organization</button>
            </div>
          </div>
        </nz-dropdown-menu>

        <ng-template #customContentOrg_s2>
          <div class="step_content_wrapper align-items-center">
            <div class="icon_sec">
              <img src="../../../assets/images/change-management.png" alt="">
            </div>
            <div class="detail_sec">
              <p>To switch organizations, simply select one from the list.</p>
            </div>
          </div>
        </ng-template>

      </div>
    </ng-container>
    <ng-template #customContent>
      <div class="step_content_wrapper align-items-center">
        <div class="icon_sec">
          <img src="../../../assets/images/teamwork.png" alt="">
        </div>
        <div class="detail_sec">
          <p>To update or change your organization, please do so from here.</p>
        </div>
      </div>
    </ng-template>


    <ng-container *ngIf="sharedService.isMobile || sharedService.isTablet">
      <div class="app-sidebar-user">
        <div class="img_sec">
          <app-image-preview [imageUrl]="null" [class]="'profile-image'" [isVideo]="false" [profilePic]="true"
                             nz-tooltip [nzTooltipTitle]="isCollapsed == true ?  sharedService.username: ''">
          </app-image-preview>
        </div>
        <div class="content_sec">
          <h5 class="text-16-bold">
            {{sharedService.username}}
          </h5>
          <span>{{ sharedService.email }}</span>
        </div>
        <a href="javascript:;" class="res_btn_close" (click)="isCollapsed = !isCollapsed"></a>
      </div>
    </ng-container>

    <perfect-scrollbar style="max-height: calc(100% - 80px)">
      <div class="sidebar_nav_main">

        <ul class="sidebar_nav" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
          <ng-container *ngIf="subscription">
            <li nz-menu-item [nzSelected]="router.isActive('/dashboard', false) ? true : false" (click)="changePage()">
              <a routerLink="/dashboard">
                <img src="../../assets/images/icon-dashboard.svg" alt="" style="max-height: 20px;">
                <span>Dashboard</span>
              </a>
            </li>
            <ng-container *ngIf="sharedService?.isMobile">
              <li class="m_accounts" nz-menu-item (click)="changePage()"
                  [nzSelected]="router.isActive('/post/create-post', false) ? true : false">
                <a routerLink="post/create-post">
                  <img src="../../assets/images/icon-create-post.svg" alt="">
                  <span>Create Post</span>
                </a>
              </li>
              <li class="m_accounts" nz-menu-item (click)="changePage()"
                  [nzSelected]="router.isActive('/bulk-schedule', false) ? true : false">
                <a (click)="goToBulkSchedule()">
                  <img src="../../assets/images/icon-bulk-post.svg" alt="">
                  <span>Bulk Upload</span>
                </a>
              </li>
            </ng-container>
            <li class="m_accounts" nz-menu-item [nzSelected]="router.isActive('/accounts', false) ? true : false"
                (click)="changePage()">
              <a routerLink="/accounts">
                <img src="../../assets/images/icon-accounts.svg" alt="">
                <span>Accounts
                  <ng-container *ngIf="sharedService.reconnectCount > 0 && user.is_super_admin">
                    <nz-space><nz-badge nzStandalone [nzCount]="sharedService.reconnectCount" [nzStyle]="{paddingTop:'2px'}"></nz-badge></nz-space>
                  </ng-container>
                </span>
              </a>
            </li>
            <li nz-menu-item
                [nzSelected]="isMobile && router.isActive('/post/create-post', false) ? false : router.isActive('/post', false) ? true : false"
                (click)="changePage()">
              <a routerLink="post/manage-post" [queryParams]="{ post: 'scheduled-post' }">
                <img src="../../assets/images/icon-post.svg" alt="">
                <span>Posts</span>
              </a>
            </li>
            <li class="m_social_feed" nz-menu-item [nzSelected]="router.isActive('/feed', false) ? true : false"
                (click)="changePage()">
              <a routerLink="feed">
                <img src="../../assets/images/icon-social-feed.svg" alt="">
                <span>Social Feed</span>
              </a>
            </li>
            <li nz-menu-item [nzSelected]="router.isActive('/calendar', false) ? true : false" (click)="changePage()">
              <a routerLink="calendar">
                <img src="../../assets/images/icon-scheduler.svg" alt="">
                <span>Calendar</span>
              </a>
            </li>
            <li nz-menu-item [nzSelected]="router.isActive('/analytics', false) ? true : false" (click)="changePage()">
              <a routerLink="/analytics">
                <img src="../../assets/images/analytics.svg" alt="">
                <span>Analytics</span>
              </a>
            </li>
            <li nz-menu-item [nzSelected]="router.isActive('/gallery', false) ? true : false" (click)="changePage()">
              <a routerLink="/gallery">
                <img src="../../assets/images/icon-gallery.svg" alt="">
                <span>Gallery</span>
              </a>
            </li>
            <li class="m_messages" nz-menu-item [nzSelected]="router.isActive('/inbox', false) ? true : false"
                (click)="changePage()" *ngIf="sharedService.can('messages') || sharedService.can('comment')">
              <a routerLink="inbox">
                <img src="../../assets/images/icon-messages.svg" alt="">
                <span>Messages
                  <ng-container *ngIf="sharedService.unreadInboxCount > 0">
                    <nz-space>
                      <nz-badge nzStandalone [nzCount]="sharedService.unreadInboxCount"
                                [nzStyle]="{paddingTop:'1px',backgroundColor:'#423FD1'}">
                      </nz-badge>
                    </nz-space>
                  </ng-container>
                </span>
              </a>
              <!-- <ng-container *ngIf="unreadInboxCount > 0">
                <span nz-tooltip nzTooltipTitle="Unread Messages" nzTooltipTrigger="hover" class="notification_count">
                  {{unreadInboxCount}}
                </span>
              </ng-container> -->
            </li>
            <li class="m_notifications" nz-menu-item
                [nzSelected]="router.isActive('/notifications', false) ? true : false" (click)="changePage()">
              <a routerLink="/notifications">
                <img src="../../assets/images/icon-notifications.svg" alt="" style="max-height: 28px;">
                <span>Notifications
                  <ng-container *ngIf="sharedService.notificationCount > 0">
                    <nz-space>
                      <nz-badge nzStandalone [nzCount]="sharedService.notificationCount"
                                [nzStyle]="{paddingTop:'1px',backgroundColor:'#EB0014'}">
                      </nz-badge>
                    </nz-space>
                  </ng-container>
                </span>
              </a>
              <!-- <ng-container *ngIf="notificationCount > 0">
                <span nz-tooltip nzTooltipTitle="Unread Notification" nzTooltipTrigger="hover" class="notification_count">
                  {{notificationCount}}
                </span>
              </ng-container> -->
            </li>
            <li *ngIf="sharedService.canAccessTeam()" class="m_team" nz-menu-item
                [nzSelected]="router.isActive('/organization', false) ? true : false" (click)="changePage()">
              <a routerLink="/organization/teams">
                <img src="../../assets/images/icon-teams.svg" alt="">
                <span>Teams</span>
              </a>
            </li>

            <li class="m_team" nz-menu-item [nzSelected]="router.isActive('/campaigns', false) ? true : false" (click)="changePage()">
              <a routerLink="/campaigns">
                <img src="../../assets/images/campaigns.svg" alt="" width="30" height="30" style="max-height: 30px;">
                <span>Campaigns</span>
              </a>
            </li>
            <ng-container *ngIf="sharedService.isMobile || sharedService.isTablet">
              <li nz-menu-item [nzSelected]="router.isActive('/settings', false) ? true : false" (click)="changePage()">
                <a routerLink="settings" nz-tooltip [nzTooltipTitle]="!sharedService.isMobile ? 'Settings' : ''">
                  <img src="../../assets/images/icon-settings.svg" alt="">
                  <span>Settings</span>
                </a>
              </li>
              <li nz-menu-item nz-tooltip [nzTooltipTitle]="!sharedService.isMobile ? 'Logout' : ''">
                <a (click)="signOut()">
                  <img src="../../assets/images/icon-logouts.svg" alt="">
                  <span>Logout</span>
                </a>
              </li>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!subscription">
            <li nz-menu-item nz-tooltip [nzTooltipTitle]="!sharedService.isMobile ? 'Logout' : ''">
              <a (click)="signOut()">
                <img src="../../assets/images/icon-logouts.svg" alt="">
                <span>Logout</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </perfect-scrollbar>

  </nz-sider>
  <nz-layout class="main_layout" [class.subscriptionLayout]="!subscription">
    <nz-header>
      <div class="app-header">

        <!-- Start subscription organization -->
        <ng-container *ngIf="!sharedService.isTablet">
          <div class="app-sidebar-user" *ngIf="!subscription">
            <div class="img_sec" (click)="SortModal()">
              <app-image-preview [imageUrl]="selectedOrganization?.icon" [organizationProfile]="true" [isVideo]="false">
              </app-image-preview>
            </div>
            <div class="content_sec" (click)="SortModal()">
              <h5 class="text-16-bold">
                {{ selectedOrganization?.organization_name }}
              </h5>
            </div>
          </div>
        </ng-container>
        <!-- End subscription organization -->

        <div class="res_hdr_main">
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <img src="../../assets/images/res_menu_icon.svg" alt="" />
          </span>
          <div class="res_user_img" (click)="SortModal()" *ngIf="allOrganizations.length>0" [class.multiple-org]="allOrganizations.length > 1">
            <!-- <app-image-preview [imageUrl]="null" [class]="'profile-image'" [isVideo]="false" [profilePic]="true">
            </app-image-preview> -->
            <app-image-preview [imageUrl]="organizations[0]?.icon" [organizationProfile]="true"
                               [class]="'profile-image'" [isVideo]="false">
            </app-image-preview>
          </div>
        </div>

        <a routerLink="/" class="dash_hdr_logo" href="javascript:;" aria-label="One For All Social">
          <img src="./assets/images/logo.png" alt="" />
        </a>

        <div class="my_work_main">
          <div class="my_work_hdr" nz-dropdown nzTrigger="click" [nzDropdownMenu]="myWork"
               *ngIf="subscription && sharedService.canAccessTeam()">
            <!-- (click)="openMyWork()" -->
            <span>My work</span>
          </div>
          <nz-dropdown-menu #myWork="nzDropdownMenu">
            <div class="my_work_body">
              <!-- [class.active]="isOpenMywork" -->

              <bs-my-work (selectedTabEvent)="changeWorkIndex($event)"></bs-my-work>

              <div class="my_work_nav" nz-menu-item>
                <!--<button type="submit" nz-button class="btn_post_page" routerLink="post/manage-post">-->
                <button type="submit" nz-button class="btn_post_page" (click)="goToYourPost()">
                  <img src="../../assets/images/icon-plus-blue.svg" alt="">
                  Go to your Posts
                </button>
              </div>
            </div>
          </nz-dropdown-menu>
        </div>
        <div class="hdr_post_main">
          <div class="hdr_btn_group" *ngIf="subscription">
            <a href="javascript:;" class="btn" (click)="goToBulkSchedule()">Bulk Upload</a>
            <a class="btn btn_create" routerLink="post/create-post">Create Post</a>
          </div>


          <div class="hdr_notify_main" *ngIf="subscription">
            <a class="hdr_notify_icon" href="javascript:;" nz-dropdown nzTrigger="click"
               [nzDropdownMenu]="notification" aria-label="One For All Social">
              <!-- (click)="openNotification()" -->
              <nz-badge [nzCount]="sharedService.notificationCount" [nzStyle]="{paddingTop:'2px'}">
                <img src="../../assets/images/icon-notifications.svg" alt="">
              </nz-badge>
            </a>
            <nz-dropdown-menu #notification="nzDropdownMenu">
              <div class="hdr_notify_wrap">
                <div class="hdr_notify_hdr">
                  <h5>Notifications</h5>
                  <!--<a href="javascript:;">Mark all read</a>-->
                </div>
                <ng-container *ngIf="sharedService.notifications.length != 0; else noNotification">
                  <div class="hdr_notify_body">
                    <div class="hdr_notify_list" nz-menu>
                      <ng-container *ngFor="let notification of sharedService.notifications; let i = index">
                        <div class="hdr_notify_list_in" (click)="goToPage(notification)" nz-menu-item *ngIf="i<4">
                          <div class="img_area">
                            <app-image-preview [imageUrl]="getNotificationText(notification.data,'icon')"
                                               [isVideo]="false">
                            </app-image-preview>
                          </div>
                          <div class="content_area">
                            <div class="content_area_left">
                              <p [innerHtml]="getNotificationText(notification.data,'text')"></p>
                            </div>
                            <div class="content_area_right">
                              <span>{{sharedService.timeAge(notification.created_at)}}</span>
                              <button nz-button nzType="primary" *ngIf="notification.read_at==null">New</button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noNotification>
                  <div class="custome_default_msg">
                    <img src="../../assets/images/icon-default-notification.svg" alt="">
                    <span>No unread<strong>#Notifications</strong>Yet!</span>
                  </div>
                </ng-template>
                <div class="hdr_notify_ftr">
                  <button type="submit" nz-button class="btn_post_page" nz-menu-item routerLink="/notifications">
                    <img src="../../assets/images/icon-plus-blue.svg" alt="">
                    Go to my Notifications page
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </div>

          <div class="hdr_notify_main" *ngIf="subscription">
            <a class="hdr_notify_icon" [href]="sharedService.linkContact" target="_blank"
               nz-tooltip nzTooltipTitle="Help" aria-label="One For All Social">
              <span class="help_icon" nz-icon nzType="question-circle" nzTheme="outline"></span>
            </a>
          </div>
          <!-- *ngIf="allOrganizations.length>0" -->
          <div class="org_option_main">
            <!-- (click)="openMyAccount()" -->
            <div class="org_option_left" nz-dropdown nzTrigger="click" [nzDropdownMenu]="myProfile" (click)="onDropdownVisibleChange()">
              <app-image-preview
                [imageUrl]="null" [class]="'profile-image'" [isVideo]="false"
                [profilePic]="true" nz-tooltip
                [nzTooltipTitle]="isCollapsed == true ?  sharedService.username: ''">
              </app-image-preview>
            </div>
            <nz-dropdown-menu #myProfile="nzDropdownMenu">
              <div class="org_drop_down">
                <!-- [class.active]="isOpenMyAccount" -->
                <h5>Account</h5>
                <div class="org_drop_down_hdr">
                  <div class="img_area" *ngIf="isAccountDropdownOpen">
                    <app-image-preview
                      [imageUrl]="null" [class]="'profile-image'" [isVideo]="false"
                      [profilePic]="true" nz-tooltip
                      [nzTooltipTitle]="isCollapsed == true ?  sharedService.username: ''">
                    </app-image-preview>
                  </div>
                  <div class="content_sec">
                    <h4>{{sharedService.username}}</h4>
                    <span>{{ sharedService.email }}</span>
                  </div>
                </div>
                <div class="org_drop_down_list" nz-menu *ngIf="subscription">
                  <a nz-menu-item routerLink="settings">
                    <img src="/assets/images/carbon_settings.png" alt="">
                    <span>Settings</span>
                  </a>
                </div>
                <div class="org_drop_down_ftr">
                  <a (click)="signOut()">
                    <img src="../../assets/images/icon-switch.svg" alt="">
                    <span>Log out</span>
                  </a>
                </div>
              </div>
            </nz-dropdown-menu>
            <!-- {{organizations | json}} -->
            <!-- <div class="org_option_right">
              <h3>{{ sharedService.username }}</h3>
              <span>{{ sharedService.email }}</span>
            </div> -->
          </div>
        </div>
      </div>
    </nz-header>

    <nz-content>
      <div class="inner-content">

        <div class="main_page_header" *ngIf="pageName && sharedService.canAccessTeam()">
          <h5>{{pageName}}</h5>
          <app-select-team *ngIf="sharedService.getTeamList()"></app-select-team>
        </div>

        <router-outlet></router-outlet>

      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<!-- Start select organization modal -->
<nz-modal [(nzVisible)]="visible" nzClassName="org_option_modal">
  <ng-container *nzModalContent >
    <div style="padding-bottom: 24px;" >
      <div class="org_optn_hdr">
        <h4>Organizations</h4>
      </div>
      <div class="org_optn_body" >
        <!--{{allOrganizations | json}}  -->
        <div class="org_optn_body_in">
          <ng-container *ngFor="let organization of allOrganizations">
            <nz-radio-group class="org_optn_list_wrap" [(ngModel)]="selectedOrganizationModal">
              <div class="org_optn_list" >
                <div class="org_optn_list_in">
                  <div class="org_optn_list_in_left">
                    <label nz-radio [nzValue]="organization">
                      <div class="org_optn_list_main">
                        <div class="org_optn_list_left">
                          <app-image-preview [imageUrl]="organization?.icon" [organizationProfile]="true"
                                            [class]="'organization-list-profile'" [isVideo]="false">
                          </app-image-preview>
                        </div>
                        <div class="org_optn_list_right">
                          <h3>{{ organization?.organization_name }}</h3>
                          <span *ngIf="organization.owner_id === user.id">
                            <span nz-icon nzType="star" nzTheme="fill"></span>
                            Super Admin
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="org_optn_list_in_right">
                    <a *ngIf="organization.owner_id !== user.id" (click)="leaveOrganization(user.id, organization.id)">
                      <img src="../../assets/images/edit_icon_2.svg" alt="" />
                    </a>
                    <ng-container *ngIf="organization.owner_id === user.id">
                      <a (click)="showOrganizationModal(organization)">
                        <img src="../../assets/images/edit_icon_2.svg" alt="" />
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </nz-radio-group>
          </ng-container>  
        </div>
                
        <div class="btn_wrap">
          <button type="button" class="btn" (click)="closeOrgModel()">Cancel</button>
          <button type="button" class="btn btn_switch" (click)="closeOrganisation($event)"
                  [disabled]="allOrganizations.length === 1">Switch Organization</button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End select organization modal -->

<!-- Start Save Organization -->
<nz-modal nzClassName="organization_form_modal" [(nzVisible)]="organization_modal_preview" nzTitle="{{ modalTitle }}"
          [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="create-team-modal">
      <form nz-form [formGroup]="organizationForm">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Organization Name</nz-form-label>
          <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Team name is required">
            <input nz-input formControlName="organization_name" required [class.disabled_while_loading]="isSaveOrg" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Organization Icon</nz-form-label>
          <div *ngIf="!iconUrl || iconUrl === 'null'">
            <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false"
                       [nzBeforeUpload]="beforeUpload" [nzDisabled]="loading">
              <ng-container>
                <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'" *ngIf="loading"></i>
                <img src="../../../assets/images/upload_media_icon.png" *ngIf="!loading" alt="">
                <div class="ant-upload-text">Upload</div>
              </ng-container>
            </nz-upload>
          </div>
          <div *ngIf="iconUrl && iconUrl !== 'null'" class="create-team-image-wrap">
            <div class="img">
              <app-image-preview [imageUrl]="iconUrl" [class]="'organization-icon'" [isVideo]="false">
              </app-image-preview>
              <div class="icon" [class.disabled_while_loading]="isSaveOrg">
                <img (click)="deleteImage()" src="../../assets/images/icon-close.svg" alt="">
              </div>
            </div>
          </div>
        </nz-form-item>
      </form>
      <div class="organization_form_action">
        <span>Are you sure you want to make changes?</span>
        <div class="btn_group">
          <button type="button" class="btn" (click)="closeOrganization()" [class.disabled_while_loading]="isSaveOrg">
            Cancel
          </button>
          <button type="button" class="btn btn_apply" (click)="saveOrganization()"
                  [class.disabled_while_loading]="isSaveOrg">
            Apply Changes
            <ng-container *ngIf="isSaveOrg">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End Save Organization -->

<!-- Start delete Organization -->
<nz-modal [(nzVisible)]="deleteOrgvisible" nzClassName="delete_team_modal" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Leave Organisation?</h4>
    </div>
    <div class="delete_team_body">
      <p>
        <b>Please Note : </b> When you leave this organization, your posting
        history and future scheduled posts will no longer be available and will
        not be sent. Are you sure you want to remove this organization?
      </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="" />

      <div class="organization_form_action">
        <span>Are you sure you want to leave this organization?</span>

        <div class="btn_group">
          <button type="button" class="btn" nz-button [disabled]="isDisableOrg" (click)="deleteOrgModalClose()">
            Cancel
          </button>
          <button type="button" class="btn btn_apply" nz-button nzBlock
                  [disabled]="isDisableOrg" (click)="deleteOrg(member_org_id, team_org_id)">
            <ng-container *ngIf="!isDisableOrg; else NotLogin">
              Yes, Leave it
            </ng-container>
            <ng-template #NotLogin>Please Wait...</ng-template>
          </button>
        </div>

      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End delete Organization -->
