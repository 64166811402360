<div class="create_content" *ngIf="!isSinglePlatformMode">

  <div class="create_content_left">

    <form  *ngIf="postForm" id="createPost" [formGroup]="postForm" (dragover)="onDocumentDragOver($event)" (drop)="onDrop($event)"
      (dragleave)="onDragLeave($event)">


      <!-- <div class="form_input_wrp">
        <div class="title_sec">
          <h5 class="text-24">Tagging</h5>
        </div>
        <div class="tabbing_input">

          <textarea [mentionConfig]="{items:items, labelKey:'name'}"
          [mentionListTemplate]="mentionListTemplate"></textarea>

          <ng-template #mentionListTemplate let-item="item">
            <img class="mr-3" src="https://avatars1.githubusercontent.com/u/{{item.img}}?s=180&v=4" alt="" width="30" height="30">
            &nbsp;
            <span>{{item.name}}</span>
          </ng-template>
        </div>
      </div> -->



      <!--<div class="form_input_wrp" *ngIf="!user.is_super_admin && !id">-->
      <div class="form_input_wrp intro_select_team" *ngIf="!id && sharedService.currentPlan?.plan_id!== 'plan_1'"
        joyrideStep="post_team" stepPosition="bottom" [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/choose_the_team.svg" alt="">
            </div>
            <div class="detail_sec">
              <p>Here you can choose the team you'd like to share and work on this post with. O4A will start with your
                default team.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>
        <div class="title_sec">
          <div>
            <h5 class="text-24">Teams</h5>
            <span class="text-16">Please select the team you desire!</span>
          </div>
        </div>
        <div>
          <nz-form-item class="post_to_input cp_team_wrp">
            <nz-form-control>
              <nz-select [nzCustomTemplate]="cp_team" formControlName="team" [(ngModel)]="selectedTeam"
                [nzPlaceHolder]="'select the team'" (ngModelChange)="updateTeams($event)"
                [nzDropdownClassName]="ddTeamClass" (nzOpenChange)="sharedService.openNzSelect($event)">

                <nz-option *ngFor="let team of teams" nzCustomContent [nzValue]="team">
                  <div class="cp_team_main">
                    <div class="img_sec">
                      <app-image-preview [imageUrl]="team?.icon" [organizationProfile]="true"
                        [class]="'organization-list-profile'" [isVideo]="false">
                      </app-image-preview>
                    </div>
                    <div class="content_sec">
                      <h5>{{team.team_name}}</h5>
                    </div>
                  </div>
                </nz-option>
              </nz-select>
            </nz-form-control>
            <ng-template #cp_team let-selected>
              <div class="ant-select-selection-item-content">
                <div class="cp_team_main">
                  <div class="img_sec">
                    <app-image-preview [imageUrl]="selected.nzValue?.icon" [organizationProfile]="true"
                      [class]="'organization-list-profile'" [isVideo]="false">
                    </app-image-preview>
                  </div>
                  <div class="content_sec">
                    <h5>{{selected.nzValue?.team_name}}</h5>
                  </div>
                </div>
              </div>
            </ng-template>
          </nz-form-item>
        </div>
      </div>

      <div class="form_input_wrp intro_post_to" *ngIf="!id" joyrideStep="post_postTo" stepPosition="bottom"
        [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/selection_icon.svg" alt="">
            </div>
            <div class="detail_sec">
              <p>Select your preferred social media platforms to publish this post. Share across multiple accounts for
                maximum exposure.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <div class="title_sec">
          <div>
            <h5 class="text-24">Post to</h5>
            <span class="text-16">Select one or more platforms (Facebook,Instagram etc)</span>
          </div>
        </div>

        <div>

          <div class="profile-selection" *ngIf="selectedAccounts.length>0">
            <div class="profile-acount">
              <span>
                Profiles ({{ selectedAccounts.length }})
              </span>
            </div>
            <div class="profile-action">
              <a href="javascript:;" (click)="selectAllAccounts()">
                <span nz-icon nzType="check" nzTheme="outline"></span>
                <span>Select All Platforms</span>
              </a>
              <a href="javascript:;" class="btn-clear" (click)="clearAllAccounts()">
                <span nz-icon nzType="delete" nzTheme="outline"></span>
                <span>Clear All</span>
              </a>
              <!-- <a href="javascript:;" class="btn-done" >
                <span>Done</span>
              </a> -->
            </div>
          </div>

          <nz-form-item class="post_to_input">
            <nz-form-control>
              <nz-select
                [(nzOpen)]="isOpenSelect"
                [nzDropdownRender]="renderTemplateAccountConnect"
                (nzOpenChange)="sharedService.openNzSelect($event)" nzDropdownClassName="post_accounts"
                [nzOptionOverflowSize]="10" [nzCustomTemplate]="multipleTemplate"
                formControlName="accounts_id"
                [nzShowArrow]="true" nzMode="multiple"
                [nzPlaceHolder]="isLoadingPost ? 'Accounts Loading..' : 'Select Account(s)'"
                (ngModelChange)="updateAccounts($event)"
                [(ngModel)]="selectedAccounts" 
                [nzDisabled]="isLoading || isLoadingPost">
                <nz-option-group *ngFor="let provider of groupedAccounts | keyvalue" [nzLabel]="groupLabelTemplate">
                    <nz-option *ngFor="let account of provider.value" nzCustomContent [nzLabel]="account.name" [nzValue]="account">
                      <div class="social-accounts">
                        <div class="social-accounts-left">
                          <app-image-preview [imageUrl]="account.image" [class]="'profile-image'" [isVideo]="false">
                          </app-image-preview>
                          <div class="social-accounts-icon">
                            <app-provider [provider]="account?.provider"></app-provider>
                          </div>
                        </div>
                        <div class="social-accounts-right">
                          <div class="account-info">
                            <span>{{ account.name }}</span>
                          </div>
                          <div class="account-type">
                            <span>{{account.type | titlecase}}</span>
                          </div>
                        </div>
                      </div>
                    </nz-option>
                    <ng-template #groupLabelTemplate>
                      <div class="platform-info">
                        <div class="platform-info-left">
                          <app-provider [provider]="provider.key"></app-provider>
                          <span>{{ provider.key === 'TWITTER' ? 'X' : (provider.key | titlecase) }}</span>
                          <span class="beta-tag-option" *ngIf="provider.key === 'TIKTOK'">Beta</span>
                        </div>
                        <div class="platform-info-right">
                          <label class="provider-check">
                            <input type="checkbox"
                              [checked]="areAllProviderAccountsSelected(provider.key)"
                             (change)="onProviderCheckboxChange(provider.key, $event)" >
                            All {{ provider.key === 'TWITTER' ? 'X' : (provider.key | titlecase) }}
                            <span class="checkbox_check"></span>
                          </label>
                        </div>
                      </div>
                    </ng-template>
                </nz-option-group>
              </nz-select>

              <ng-template #renderTemplateAccountConnect>
                <div class="dd-footer-template">
                  <div class="select_input_container cursor-pointer" *ngIf="sharedService.onlyAccessAdmin()" (click)="connectNewAccount()">
                    <span class="btn_add_social" >
                      <span nz-icon nzType="plus" nzTheme="outline"></span>
                      Add a social account
                    </span>
                    <div class="btn-close">
                      <a href="javascript:;" (click)="closeDropdown()">Close</a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </nz-form-control>
            <ng-template #multipleTemplate let-selected>
              <div class="ant-select-selection-item-content">
                  <app-provider [provider]="selected.nzValue.provider"></app-provider>
                  <span>{{ selected.nzLabel }} {{ selected.nzValue.provider === sharedService.facebookProvider &&
                  selected.nzValue.type === sharedService.group ? '(' + selected.nzValue.type + ')' : '' }}</span>
              </div>
            </ng-template>
          </nz-form-item>

          <!-- Connect Accounts modal-->
          <nz-modal [(nzVisible)]="isAccountConnectModal" nzTitle="Connect Accounts" [nzFooter]="null"
            (nzOnCancel)="isAccountConnectModal = false" nzMaskClosable="false" [nzClassName]="'account_connect_modal'">
            <ng-container *nzModalContent>
              <div class="account_permissions_body app_account_connect_main">
                <!-- Start Connected Accounts -->
                <app-account-connect [isCreatePost]="true"></app-account-connect>
                <!-- End Connected Accounts -->
              </div>
            </ng-container>
          </nz-modal>

        </div>
      </div>

      <div class="form_input_wrp form_post_desc intro_description" joyrideStep="post_description" stepPosition="right"
        [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/get_creative.png" alt="">
            </div>
            <div class="detail_sec">
              <p>Get creative and write your post. Make sure to include relevant links, and don't forget to use the
                #Auto Hashtags button to generate #hashtags to reach a wider audience.
                  Writer’s block? Use the “Write for Me” button to help write your post.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <div class="title_sec description_title_sec">
          <div>
            <h5 class="text-24">Description</h5>
            <span class="text-16">Enter your post text, or click the 'Write for me' button to open ChatGPT.</span>
          </div>

          <div class="content-length">
            <ul>
              <li>
                {{ postForm?.get("content")?.value === null ? 0 : tweetWordCount(postForm?.get("content")?.value) }}
              </li>
              <ng-container *ngIf="allAccountProvider.length > 0">
                <span>/</span>
                <ng-container *ngFor="let accProvider of allAccountProvider">
                  <li *ngIf="accProvider === sharedService.twitterProvider">
                    <img src="../../../assets/images/providers/medium/TWITTER.png" class="icon" alt="Twitter Logo" />
                    <!--<span [class.warning-color]="postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.twitterProvider) -
                              postForm.get('content')?.value?.trimStart().length <= 0">
                      {{ this.sharedService.getContentLength(sharedService.twitterProvider) -
                      postForm.get("content")?.value?.trimStart().length }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.linkedinProvider">
                    <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
                    <!--<span [class.warning-color]="
                                postForm.get('content')?.value !== null &&
                                sharedService.getContentLength(sharedService.linkedinProvider) -
                                postForm.get('content')?.value?.trimStart().length <= 0">
                      {{
                      this.sharedService.getContentLength(sharedService.linkedinProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.facebookProvider">
                    <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon" />
                   <!-- <span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.facebookProvider) -
                                postForm.get('content')?.value?.trimStart().length <= 0">
                      {{
                      this.sharedService.getContentLength(sharedService.facebookProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.instagramProvider">
                    <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon" />
                    <!--<span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.instagramProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.instagramProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.youtubeProvider">
                    <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                    <!--<span [class.warning-color]="postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.youtubeProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.youtubeProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.pinterestProvider">
                    <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
                    <!--<span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.pinterestProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.pinterestProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.tiktokProvider">
                    <img src="assets/images/tiktok.svg" class="icon" />
                    <!--<span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.tiktokProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.tiktokProvider) -
                      postForm?.get("content")?.value?.trimStart().length
                      }}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.threadsProvider">
                    <img src="../../../assets/images/providers/medium/THREADS.png" class="icon" />
                    <!--<span [class.warning-color]="postForm.get('content')?.value !== null && this.sharedService.getContentLength(sharedService.threadsProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{ this.sharedService.getContentLength(sharedService.threadsProvider) - postForm.get("content")?.value?.trimStart().length}}
                    </span>-->
                    <span [class.warning-color]="isContentWarning[accProvider]">
                       {{ remainingCharacters[accProvider] }}
                    </span>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>

        <div class="form_input_in">
            <div class="post_text_input">
            <!--[mentionConfig]="{items:items, labelKey:'name',mentionSelect: mentionSelect }" [mentionListTemplate]="mentionListTemplate"-->

            <textarea class="content" nz-input placeholder="Start to Write" formControlName="content" (keyup)="setValidation()" #inputContent
                      [class.disabled_while_loading]="schedulePostDetailLoading || isLoading"></textarea>

            <div class="post_input_action">
              <div class="post_input_action_left">
                <button class="btn_primary" (click)="writeForMe()" [disabled]="selectedAccounts?.length===0" nz-tooltip
                  [nzTooltipTitle]="selectedAccounts?.length===0 ? 'Please select an account before creating any post' : ''">
                  <!-- *ngIf="sharedService?.currentPlan?.plan_id=== 'plan_3'" -->
                  <img src="../../../assets/images/icon-chatgpt-white.svg" alt="">
                  <span>Write for Me</span>
                </button>
                <nz-drawer [nzClosable]="false" [nzVisible]="isWriteForMe" nzPlacement="right"
                  nzWrapClassName="wfm_drawer">
                  <ng-container *nzDrawerContent>
                    <div class="wfm_header">
                      <div class="wfm_header_left">
                        <h3>Write for Me</h3>
                      </div>
                      <div class="wfm_header_right">
                        <div class="powred_by">
                          <img src="../../../assets/images/icon-chatgpt-blue.svg" alt="">
                          <div class="powred_by_dtl">
                            <span>Powered by</span>
                            <h4>ChatGPT</h4>
                          </div>
                        </div>
                        <a href="javascript:;" class="close_wfm_drawer" (click)="closeWfmDrawer()">
                          <img src="../../../assets/images/close_icon.svg" alt="">
                        </a>
                      </div>
                    </div>

                    <div class="wfm_body">
                      <div class="wfm_form" *ngIf="articles.length===0">
                        <ng-container *ngIf="total_chatgpt_article_limit !== -1">
                          <div class="wfm_warning" [class.noplan]="remaining_chatgpt_article_limit === 0">
                            <div class="wfm_warning_chart">
                              <ng-container *ngIf="remaining_chatgpt_article_limit > 0;else noPlan">
                                <nz-progress [nzStrokeColor]="'#423fd1'" nzType="circle" [nzFormat]="formatProgress"
                                  [nzPercent]="getPercentage()" [nzWidth]="70"></nz-progress>
                              </ng-container>
                              <ng-template #noPlan>
                                <img src="../../../assets/images/icon-chatgpt-red.svg" alt="">
                              </ng-template>
                            </div>
                            <div class="wfm_warning_msg">
                              <label *ngIf="remaining_chatgpt_article_limit === 0">You have reached the prompt limit for
                                this month. Buy Addon to get more prompts.</label>
                              <label *ngIf="remaining_chatgpt_article_limit > 0">Remaining number of prompts you can use
                                this month</label>
                            </div>
                          </div>
                        </ng-container>
                        <!-- <div class="wfm_form_in">
                          <label>What type of content are you looking to create?</label>
                          <nz-select nzPlaceHolder="Facebook post, Twitter post, Insta caption, etc."
                            [(ngModel)]="selectedPlatformText" [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let platformText of platformTexts" [nzLabel]="platformText"
                              [nzValue]="platformText"></nz-option>
                          </nz-select>
                        </div> -->
                        <div class="wfm_form_in">
                          <label>
                            What are the main points or ideas you would like to cover in your post?
                            <span nz-icon nzType="info-circle" nzTheme="fill" nz-tooltip nzTooltipTitle="For ChatGPT, the more detail you give it on what you want, the better it will do. If you want to limit character count, give it that as part of the prompt. Example: Tell me a knock knock joke in 200 characters or less."></span>
                          </label>
                          <textarea nz-input [(ngModel)]="articleText" [ngModelOptions]="{standalone: true}"
                                    placeholder="Describe up to 3 key points for your post. Add the character limit for brevity and/or the target platform(s) (X, TikTok, etc.). You may want to leave additional room for hashtags. The more descriptive you are, the more effective your post will be.">
                          </textarea>

                          <span class="wfm_count">{{articleText.length + '/' + articleTextLimit}}</span>
                          <span class="wfm_content_error" *ngIf="articleText.length>articleTextLimit">
                            Text description exceeds the limit of {{articleTextLimit}} characters. Please shorten your
                            input and try again.
                          </span>
                        </div>
                        <div class="wfm_form_in">
                          <label>What type of content are you looking to create?</label>
                          <nz-select nzPlaceHolder="😄 Positive & Upbeat" nzDropdownClassName="tone_of_voice_main"
                            [(ngModel)]="selectedTone" [nzCustomTemplate]="defaultTemplate"
                            [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let tone of toneTypes" nzCustomContent [nzLabel]="tone" [nzValue]="tone">
                              <div class="tone_of_voice">
                                <span>{{tone}}</span>
                              </div>
                            </nz-option>
                          </nz-select>
                            <ng-template #defaultTemplate let-selected>
                                {{ selected.nzLabel }}
                            </ng-template>
                        </div>
                        <div class="wfm_form_in">
                          <ng-container *ngIf="remaining_chatgpt_article_limit !== 0 else subscription">
                            <button class="btn_primary"
                              [disabled]="isArticleLoading || articleText.length==0 || articleText.length>articleTextLimit"
                              (click)="getArticles()">
                              Write Now
                            </button>
                          </ng-container>
                          <ng-template #subscription>
                            <button class="btn_primary" *ngIf="!sharedService.currentSubscription?.is_trial"
                              (click)="isPaymentModalVisible=true">
                              Buy Addon
                            </button>
                          </ng-template>
                        </div>
                      </div>

                      <div class="wfm_loader" *ngIf="isArticleLoading">
                        <video playsinline="" autoplay="" muted="" loop="">
                          <source src="../../../assets/images/wfm_loader.mp4" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>

                      <div class="writing_list" *ngIf="!isArticleLoading">
                        <div class="writing_list_in" *ngFor="let article of articles">
                            <ng-container *ngIf="article.text.length>0">
                                <div class="content_dtl">
                                    <p>{{article.text}}</p>
                                </div>
                                <div class="content_action">
                                    <button class="btn_primary" (click)="copyText(article.text)">
                                        <span nz-icon nzType="snippets" nzTheme="outline"></span>
                                        <span>Add to Post</span>
                                    </button>
                              <div class="dtl_count">
                                <span>{{article.text.length}}</span>
                                <!--<span><img src="../../../assets/images/Facebook.png" alt=""><span>63,206</span></span>-->
                              </div>
                            </div>
                          </ng-container>
                        </div>

                          <div class="writing_list_in" *ngIf="articles.length>0">
                            <button class="btn_primary" (click)="regenerateArticles()">Try Again</button>
                          </div>
                      </div>
                    </div>
                  </ng-container>
                </nz-drawer>
                <button class="btn-shorten" (click)="openShortenUrlModal()" *ngIf="matches?.length > 0">
                  <img src="../../../assets/images/icon-shorten.svg" alt="">
                  <span>Short Link</span>
                  <span *ngIf="shortUrls.length > 0">({{shortUrlCount}})</span>
                </button>
                <button class="btn-shorten reset-shorten" (click)="resetShortUrls()"
                nz-tooltip nzTooltipTitle="Reset Shorten URL"
                 *ngIf="matches?.length > 0 && shortUrls.length > 0">
                  <span>Reset</span>
                </button>
              </div>

              <div class="post_input_action_right">
                <!--Auto Hashtag-->
                <ng-container>
                  <!-- *ngIf="!sharedService.isMobile" -->
                  <!-- <button nz-popover [nzPopoverContent]="contentTemplate"
                          [(nzPopoverVisible)]="autoHashtagVisible" nzPopoverTrigger="click" class="btn_auto_hash" [class.disabled_while_loading]="isLoading"
                    [disabled]='selectedAccounts.length===0'>
                    <svg id="Layer_2" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m30 12.5c.82813 0 1.5-.67139 1.5-1.5s-.67187-1.5-1.5-1.5h-5.74921l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80859-.14404-1.59473.39697-1.74023 1.21289l-1.38636 7.76367h-8.08551l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80664-.14404-1.59375.39697-1.74023 1.21289l-1.38636 7.76367h-8.07068c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h7.53497l-1.25 7h-6.28497c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h5.74921l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.08551l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.07068c.82813 0 1.5-.67139 1.5-1.5s-.67188-1.5-1.5-1.5h-7.53497l1.25-7zm-10.58221 7h-8.08557l1.25-7h8.08557z"/></g></svg>
                    <span>Auto Hashtags web</span>
                  </button> -->
                  <button type="button" class="btn btn_auto_hash" [class.disabled_while_loading]="isLoading"
                    [disabled]="selectedAccounts?.length===0 || postForm.get('content')?.value?.length==0"
                    (click)="autoHashTagDrawer()">
                    <img src="../../../assets/images/auto-hashtag-icon.svg" alt="">
                    <span>Auto Hashtags</span>
                  </button>
                  <ng-template #contentTemplate>
                    <div class="cus_has_tag_main">
                      <div class="cus_has_tag_in">
                        <label>Max number of hashtags</label>
                        <nz-input-number [(ngModel)]="hashtagLimit" [nzMin]="1" [nzMax]="20" [nzStep]="1"
                          nzPlaceHolder="Max Hashtags" [ngModelOptions]="{standalone: true}"></nz-input-number>
                      </div>
                      <div class="cus_has_tag_in">
                        <label>Hashtag position</label>
                        <nz-radio-group [(ngModel)]="hashtagPosition" [ngModelOptions]="{standalone: true}">
                          <label nz-radio-button nzValue="auto"><span>Anywhere</span></label>
                          <label nz-radio-button nzValue="end"><span>at the end</span></label>
                        </nz-radio-group>
                      </div>
                      <div class="cus_has_btn">
                        <button class="btn_secondary" nz-button nzType="primary" (click)="closeModal()"
                          [disabled]="isAutoHashtagLoading">
                          Cancel
                        </button>
                        <button class="btn_primary" nz-button nzType="primary" (click)="getAutoHashtagText()"
                          [disabled]="isAutoHashtagLoading">
                          Apply
                          <ng-container *ngIf="isAutoHashtagLoading">
                            <span nz-icon nzType="loading"></span>
                          </ng-container>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>

                <!-- <ng-container *ngIf="sharedService.isMobile">
                  <button type="button" class="btn_auto_hash" [class.disabled_while_loading]="isLoading"
                          [disabled]='selectedAccounts.length===0' (click)="autoHashtagModal()">
                    <svg id="Layer_2" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512"
                        xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="m30 12.5c.82813 0 1.5-.67139 1.5-1.5s-.67187-1.5-1.5-1.5h-5.74921l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80859-.14404-1.59473.39697-1.74023 1.21289l-1.38636 7.76367h-8.08551l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80664-.14404-1.59375.39697-1.74023 1.21289l-1.38636 7.76367h-8.07068c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h7.53497l-1.25 7h-6.28497c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h5.74921l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.08551l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.07068c.82813 0 1.5-.67139 1.5-1.5s-.67188-1.5-1.5-1.5h-7.53497l1.25-7zm-10.58221 7h-8.08557l1.25-7h8.08557z"/>
                      </g>
                    </svg>
                    <span>Auto Hashtags</span>
                  </button>
                  <nz-modal [(nzVisible)]="isVisibleHashtagModal" [nzFooter]=null [nzClassName]="'isVisibleHashtagModal'">
                    <ng-container *nzModalContent>
                      <div class="cus_has_tag_main">
                        <div class="cus_has_tag_in">
                          <label>Max number of hashtags</label>
                          <nz-input-number [(ngModel)]="hashtagLimit" [nzMin]="1" [nzMax]="20" [nzStep]="1"
                                          nzPlaceHolder="Max Hashtags"
                                          [ngModelOptions]="{standalone: true}"></nz-input-number>
                        </div>
                        <div class="cus_has_tag_in">
                          <label>Hashtag position</label>
                          <nz-radio-group [(ngModel)]="hashtagPosition" [ngModelOptions]="{standalone: true}">
                            <label nz-radio-button nzValue="auto"><span>Anywhere</span></label>
                            <label nz-radio-button nzValue="end"><span>at the end</span></label>
                          </nz-radio-group>
                        </div>
                        <div class="cus_has_btn">
                          <button class="btn_secondary" nz-button nzType="primary" (click)="closeModal()">
                            Cancel
                          </button>
                          <button class="btn_primary" nz-button nzType="primary" (click)="getAutoHashtagText()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </nz-modal>
                </ng-container> -->
                <!--Auto Hashtag-->

                <button type="button" class="btn btn_hash" [class.disabled_while_loading]="isLoading"
                  (click)="hashTagDrawer()" [disabled]='selectedAccounts.length===0'>
                  <img src="../../../assets/images/hashtags_templates.svg" alt="">
                  <!-- <span>Hashtags & Templates</span> -->
                  <span>Templates</span>
                </button>
              </div>
            </div>
          </div>
          <!-- <textarea class="content" nz-input placeholder="Start to Write" formControlName="content"
            (keyup)="setValidation()" #inputContent [readOnly]="isFeedPost"
            [class.disabled_while_loading]="isLoading"></textarea> -->


          <div class="social_max_legth_msg">
            <div *ngIf="allAccountProvider.includes(this.sharedService.twitterProvider)"
            class="error-color error-msg-style Twitter_error">
            <img src="../../../assets/images/providers/medium/TWITTER.png" alt="Twitter Logo" class="icon" />
              The system removes '#' symbols from X posts, leaving only the keyword.  X has stated that they will downgrade posts that continue to use the hashtag symbol.
            </div>
            <div *ngIf="postForm?.get('content')?.value !== null
            && postForm.get('content').value.trimStart().length > 280
            && allAccountProvider.includes(this.sharedService.twitterProvider)"
                 class="error-color error-msg-style Twitter_error">
              <img src="../../../assets/images/providers/medium/TWITTER.png" alt="Twitter Logo" class="icon" />
                Max limit of Twitter is 280 characters, and exceeding that limit will convert the rest of the text into Twitter thread.
            </div>

            <!--(postForm.get('content').touched || postForm.get('content').dirty) &&-->
            <div *ngIf="postForm?.get('content').hasError('linkedinMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style LinkedIn_error">
              <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
              {{ postForm?.get("content").getError("linkedinMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('instagramMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style Instagram_error">
              <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon" />
              {{ postForm?.get("content").getError("instagramMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('facebookMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style Facebook_error">
              <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon" />
              {{ postForm?.get("content").getError("facebookMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('youtubeMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style YouTube_error">
              <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
              {{ postForm?.get("content").getError("youtubeMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('pinterestMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style Pinterest_error">
              <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
              {{ postForm?.get("content").getError("pinterestMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('tiktokMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style Tiktok_error">
              <img src="assets/images/tiktok.svg" class="icon" />
              {{ postForm?.get("content").getError("tiktokMaxlength") }}
            </div>
            <div *ngIf="postForm?.get('content').hasError('threadsMaxlength') &&
              postForm.get('content').invalid" class="error-color error-msg-style Threads_error">
              <img src="../../../assets/images/providers/medium/THREADS.png" class="icon" />
              {{ postForm?.get("content").getError("threadsMaxlength") }}
            </div>
          </div>

            <div>
              <div class="des_action" *ngIf="!isFeedPost">
                  <button type="button" href="javascript:;" (click)="openUploadModel()" nz-tooltip nzTooltipTitle="Gallery"
                          [disabled]="currentUploading.length>0 || isLoading">
                      <img src="./assets/images/des_action_icon_1.svg" alt="">
                  </button>

                  <button type="button" href="javascript:;" [disabled]="currentUploading.length>0 || isLoading"
                          class="upload_media" nz-tooltip
                          [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload Media : Please select an account before creating any post' : 'Upload'">
                      <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzLimit]="10"
                                 [nzAccept]="supportedMediaFiles" [nzDisabled]="selectedAccounts.length===0">
                          <img src="./assets/images/upload_media.svg" style="height:24px;" alt="">
                  <!-- Start dragging message -->
                  <div class="dragging_msg_main">
                    <div class="dragging_msg_in">
                      <div class="icon_sec">
                        <img src="../../../assets/images/icon-upload.svg" alt="">
                      </div>
                      <div class="content_sec">
                        <span class="msg_error">Please select an account before creating any post</span>
                        <span class="msg_success">Drop file to upload</span>
                      </div>
                    </div>
                  </div>
                  <!-- End dragging message -->
                </nz-upload>
              </button>

              <button type="button" href="javascript:;" nz-tooltip
                      [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload GIF : Please select an account before creating any post' : 'Upload GIF'"
                [disabled]="currentUploading.length>0 || isLoading">
                  <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="false"
                    nzAccept=".gif" [nzDisabled]="selectedAccounts.length===0">
                    <img src="./assets/images/des_action_icon_2.svg" alt="">
                  </nz-upload>
              </button>

              <button *ngIf="allAccountProvider.includes(sharedService.facebookProvider) ||
                     allAccountProvider.includes(sharedService.instagramProvider)"
                     type="button" nz-tooltip nzTooltipTitle="Location" (click)="showLocationModal()">
                <img src="../../../assets/images/location.svg" alt="" style="height: 24px;"
                     [class.active]="this.selectedLocation!=null">
              </button>

              <button type="button" href="javascript:;" nz-dropdown [nzDropdownMenu]="openEmojis" nzTrigger="click"
                nz-tooltip nzTooltipTitle="Emojis" [disabled]="isLoading">
                <img src="../../../assets/images/emoji_icon.svg" alt="">
              </button>

              <nz-dropdown-menu #openEmojis="nzDropdownMenu">
                <div class="btn_publish_dropdown_menu">
                  <emoji-mart [showSingleCategory]="true" [perLine]="8" (emojiClick)="addEmoji($event)"
                    [showPreview]="false"></emoji-mart>
                </div>
              </nz-dropdown-menu>

              <!-- <a href="javascript:;"><img src="./assets/images/des_action_icon_5.svg" alt=""></a> -->
              <!--nz-tooltip nzTooltipTitle="Tag detected! Use platform-specific tagging to add tags"-->

              <!--<ng-container *ngIf="betaUsers.includes(userId)">-->
              <ng-container>
                <button *ngIf="allAccountProvider.includes(sharedService.facebookProvider) ||
                          allAccountProvider.includes(sharedService.instagramProvider) ||
                          allAccountProvider.includes(sharedService.threadsProvider) ||
                          allAccountProvider.includes(sharedService.tiktokProvider) ||
                          allAccountProvider.includes(sharedService.twitterProvider)"
                        type="button"  (click)="platFormSpecificDrawer()">
                    <img src="../../../assets/images/mention_icon.svg" alt="">
                </button>
              </ng-container>

              <button class="beta-label" type="button" [disabled]="selectedAccounts.length===0 || isLoading" (click)="isImageGenerateModal=true">
                <span class="beta-tag" >Beta</span>
                <img src="../../../assets/images/chipset.svg" style="height: 24px;" alt="">
              </button>

            </div>
            <div *ngIf="error" class="error-color text-14 upload_img_error">
              <img src="../../../assets/images/error_icon.svg" alt="">
              <span>{{ error }}</span>
            </div>
            <ng-container *ngIf="uploadImgError?.length > 0">
              <div *ngFor="let errorObj of uploadImgError" class="error-color text-14 upload_img_error">
                <img src="../../../assets/images/error_icon.svg" alt="">
                  <span>{{ errorObj.message }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!--Instagram Story-->
      <!--<div class="post_upload_warning"
        *ngIf="allAccountProvider.includes(sharedService.instagramProvider) &&
          images.length>1 &&
          postForm.value.instagram_type ==='story_post'">
            <nz-alert
              nzType="warning"
              nzMessage="The first media file will be published"
              nzDescription="The Instagram direct publishing workflow only supports one media file per story."
              nzShowIcon
          ></nz-alert>
      </div>-->

      <!--Tiktok Story-->
      <!--<div class="post_upload_warning" *ngIf="allAccountProvider.includes(sharedService.tiktokProvider) &&
            images.length>1">
        <nz-alert nzType="warning" nzMessage="TikTok only supports the publishing of video"
          nzDescription="The TikTok direct publishing workflow only supports one video per post." nzShowIcon></nz-alert>
      </div>-->

      <div class="app_upload_div">

        <!-- <app-upload [imageFile]="uploadFiles" [maxLength]="maxLength" [isForUpdate]="false" [initLoadGallery]="false"
          [allAccountProvider]="allAccountProvider" [images]="images" [mediaData]="mediaData"
          [galleryMediaData]="galleryMediaData" [type]="type" (deleteChange)="deleteChange($event)"
          [youtubeThumbnails]="youtubeThumbnails" *ngIf="images.length > 0" [isFeedPost]="isFeedPost"
          (editImageChanges)="editImageChanges($event)">
        </app-upload> -->


        <!--[imageFile]="uploadFiles"
        [images]="images"
        [mediaData]="mediaData"-->

        <app-gallery-preview *ngIf="postMedias.length > 0"
          [isForUpdate]="false" [initLoadGallery]="false" [allAccountProvider]="allAccountProvider"

          [postMedias]="postMedias"
          [galleryMediaData]="galleryMediaData"
          [maxLength]="maxLength"
          [type]="type"
          (deleteChange)="deleteChange($event)" [youtubeThumbnails]="youtubeThumbnails" [isFeedPost]="isFeedPost"
          [instagramType]="postForm.value.instagram_type" [instaReelThumbnails]="instaReelThumbnails"
          (editImageChanges)="editImageChanges($event)" [page]="'create_post'" [isPosting]="isLoading"
          (imagesChanged)="updateImagesIndex($event)" [multi_images]="multiImages">
        </app-gallery-preview>

        <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false"
          *ngFor="let uploading of currentUploading">
          <ng-container>
            <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
              *ngIf="uploading.loading"></nz-progress>
            <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
          </ng-container>
        </nz-upload>

      </div>

      <!-- instagram reel start -->
      <!-- *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type == 'reel_post'" -->
      <div class="provider_collapse" *ngIf="allAccountProvider.includes(sharedService.instagramProvider)">
        <div class=" provider_collapse_in">
          <nz-collapse class="instagram_form">
            <nz-collapse-panel nzHeader="Instagram" [nzActive]="true">

              <!--*ngIf="allAccountProvider.includes(sharedService.instagramProvider) && type === 'video' "-->
              <div class="form_input_wrp">
                <div class="title_sec">
                  <h5 class="text-24">Instagram Post Type
                    <!-- <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i> -->
                  </h5>
                </div>
                <div class="form_input_in">
                  <nz-form-item class="description_input">
                    <nz-input-group>
                      <nz-form-control>
                        <nz-radio-group formControlName="instagram_type" (ngModelChange)="isCommentDisplay()" [class.disabled_while_loading]="isLoading">
                          <label nz-radio nzValue="feed_post">
                            Standard Post
                          </label>
                          <!-- [class.disabled_while_loading]="isLoading || (type==='image' || type==='gif')" -->
                          <label nz-radio nzValue="reel_post">
                            Reel Post
                          </label>
                          <label nz-radio nzValue="story_post">
                            Story
                          </label>
                        </nz-radio-group>
                      </nz-form-control>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>

              <div class="form_input_wrp"
                *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type ==='reel_post' && (type === 'video' || type==='multi')">
                <div class="title_sec">
                  <h5 class="text-24">In Instagram do you want to post it as a standard post also?
                    <!-- <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i> -->
                  </h5>
                </div>
                <div class="form_input_in">
                  <nz-form-item class="inst_stad_post">
                    <nz-input-group>
                      <nz-form-control>
                        <label nz-checkbox nzDisabled formControlName="is_instagram_reels_post"
                          [class.disabled_while_loading]="isLoading">
                          <span>Reels</span>
                        </label>
                      </nz-form-control>
                      <nz-form-control>
                        <label nz-checkbox formControlName="is_instagram_standard_post"
                          [class.disabled_while_loading]="isLoading">
                          <span>Standard post</span>
                        </label>
                      </nz-form-control>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>

              <!--Collaborators-->
              <div class="form_input_wrp"
                *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type !=='story_post' ">
                <div class="title_sec">
                  <h5 class="text-24">Collaborators</h5>
                  <span>You can use Instagram Collabs to co-author posts with other accounts.
                    The creator of the original post can invite another account as a collaborator.
                    If the account accepts the invite, their username will be added to the post and the post will be shared with their followers.
                    Simply add the co-authors IG username below (without the @ symbol) and they will be notified through Instagram.</span>
                </div>

                <div class="form_input_in">
                    <div class="insta_collaborators post_to_input">
                        <div class="insta_message">
                            <span>Limited to 3 accounts</span>
                        </div>
                        <nz-select class="inst_stad_post" nzPlaceHolder="Enter Instagram username"
                                   formControlName="instagram_collaborators" [nzMaxMultipleCount]="3"
                                   nzMode="tags" nzAllowClear></nz-select>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <!-- instagram reel end -->

      <!-- facebook story started-->
      <div class="provider_collapse" *ngIf="allAccountProvider.includes(sharedService.facebookProvider)">
        <div class=" provider_collapse_in">
          <nz-collapse class="instagram_form">
            <nz-collapse-panel nzHeader="Facebook" [nzActive]="true">

              <!--*ngIf="allAccountProvider.includes(sharedService.instagramProvider) && type === 'video' "-->
              <div class="form_input_wrp">
                <div class="title_sec">
                  <h5 class="text-24">Facebook Post Type
                    <!-- <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i> -->
                  </h5>
                </div>
                <div class="form_input_in">
                  <nz-form-item class="description_input">
                    <nz-input-group>
                      <nz-form-control>
                        <nz-radio-group formControlName="facebook_type" (ngModelChange)="isCommentDisplay()" [class.disabled_while_loading]="isLoading">
                          <label nz-radio nzValue="feed_post">
                            Standard Post
                          </label>
                          <label nz-radio nzValue="story_post">
                            Story
                          </label>
                        </nz-radio-group>
                      </nz-form-control>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <!-- Start Tiktok collapse -->
      <ng-container *ngIf="postForm && creatorInfo.length && allAccountProvider.includes(sharedService.tiktokProvider)"
        formArrayName="tiktok_creator_info">
        <div class="provider_collapse" *ngFor="let account of creatorInfo; let i=index">
          <div class="provider_collapse_in" *ngIf="account" [formGroupName]="i">
            <nz-collapse class="instagram_form">
              <nz-collapse-panel [nzHeader]="'TikTok Options (' + getAccountNameLevel(account.value.social_id) +')'"
                [nzActive]="true">
                <div class="tiktok_input">
                  <nz-form-item>
                    <label>Who can view this video</label>
                    <!--[nzLabel]="getFormatedLable(level)" [nzValue]="level"-->
                    <nz-select formControlName="privacy_level" [nzPlaceHolder]="'Select Privacy Level'" nzSize="large">
                      <nz-option *ngFor="let level of getAccountPrivacyLevel(account.value.social_id)"
                        [nzLabel]="level.name" [nzValue]="level.id">
                      </nz-option>
                    </nz-select>
                    <div class="social_max_legth_msg form-validation" >
                      <div *ngIf="postForm.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.invalid &&
                      (postForm.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.touched)"
                      class="error-msg-style error-color Tiktok_error" style="margin-top: 15px;">
                        <img src="../../../assets/images/providers/medium/TIKTOK.png" alt="TIKTOK Logo" class="icon" />
                        TikTok Privacy level is required.
                      </div>
                    </div>
                  </nz-form-item>
                  <label nz-checkbox formControlName="allow_comment" nzValue="comments">Allow comments</label>
                  <label nz-checkbox formControlName="allow_duet" nzValue="duets">Allow duets</label>
                  <label nz-checkbox formControlName="allow_stitch" nzValue="stitches">Allow stitches</label>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </ng-container>
      <!-- End Tiktok collapse-->

      <div class="form_input_wrp"
        *ngIf="isCommentVisible && isCommentVisibleForYT && isCommentVisibleForLinkedIn && isCommentVisibleForInstagram && isCommentVisibleForFacebook && !isFeedPost">
        <div class="title_sec">
          <h5 class="text-24">First Comment <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add first comment on facebook, instagram, linkedin and youtube."></i>
          </h5>
          <div class="content-length">
            <div class="count_area">
              <ng-container
                *ngIf="allAccountProvider.length > 0 && (allAccountProvider.length > 1 || (allAccountProvider.length === 1 && !allAccountProvider.includes(sharedService.twitterProvider)))">
                <ul>
                  <li><span>{{ postForm.get("first_comment")?.value === null ? 0 :
                      postForm.get("first_comment")?.value.trimStart().length }}</span><span>/</span></li>
                  <ng-container *ngFor="let accProvider of allAccountProvider">
                    <li *ngIf="accProvider === sharedService.facebookProvider">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null &&
                         this.sharedService.getCommentLength(sharedService.facebookProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.facebookProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.linkedinProvider && (type==='image' || type==='gif') ">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
                        <span
                          [class.error-color]="
                                postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.linkedinProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.linkedinProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.instagramProvider">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.instagramProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.instagramProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.youtubeProvider && postForm.value.audience === 'false'">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.youtubeProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.youtubeProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>

                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form_input_in">
          <nz-form-item class="description_input">
            <nz-input-group>
              <nz-form-control>
                <textarea class="margin-top-1" nz-input placeholder="Write Your First Comment"
                  formControlName="first_comment" [class.disabled_while_loading]="isLoading"></textarea>
                <div class="social_max_legth_msg">
                  <div *ngIf="postForm.get('first_comment')?.hasError('instagramCommentMaxlength')" class="error-color error-msg-style Instagram_error">
                    <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon " />
                    {{ postForm?.get("first_comment")?.getError("instagramCommentMaxlength") }}
                  </div>
                  <div *ngIf="postForm.get('first_comment')?.hasError('facebookCommentMaxlength')" class="error-color error-msg-style Facebook_error">
                    <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon " />
                    {{ postForm?.get("first_comment")?.getError("facebookCommentMaxlength") }}
                  </div>
                  <div *ngIf="type==='image' && postForm.get('first_comment')?.hasError('linkedinCommentMaxlength')"
                    class="error-color error-msg-style LinkedIn_error">
                    <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("linkedinCommentMaxlength") }}
                  </div>
                  <div
                    *ngIf="postForm?.get('first_comment')?.hasError('youtubeCommentMaxlength') && postForm.value.audience === 'false'"
                    class="error-color error-msg-style YouTube_error">
                    <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("youtubeCommentMaxlength") }}
                  </div>
                </div>
              </nz-form-control>
            </nz-input-group>
          </nz-form-item>
        </div>
      </div>
      <!---->
      <div class="form_input_wrp" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider) ||
      allAccountProvider.includes(sharedService.pinterestProvider)">
        <div class="title_sec">
          <h5 class="text-24">Title <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i></h5>
          <div class="content-length">
            <div class="count_area" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider) ||
            allAccountProvider.includes(sharedService.pinterestProvider)">
              <ul>
                <li>
                    <span>{{ postForm.get("title")?.value === null ? 0 : postForm.get("title")?.value.trimStart().length }}</span>
                    <span>/</span></li>
                <ng-container *ngIf="allAccountProvider.length > 0">
                    <ng-container *ngFor="let accProvider of allAccountProvider">
                    <li *ngIf="accProvider === sharedService.youtubeProvider">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                        <span *ngIf="postForm?.get('title')?.value !== null" [class.error-color]="
                          postForm.get('title')?.value !== null &&
                          this.sharedService.getTitleLength(sharedService.youtubeProvider) -
                            postForm.get('title')?.value.trimStart().length <= 0">
                            {{ this.sharedService.getTitleLength(sharedService.youtubeProvider) -
                        postForm.get("title")?.value.trimStart().length
                          }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.pinterestProvider">
                      <div class="content-length">
                        <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
                        <span *ngIf="postForm?.get('title')?.value !== null" [class.error-color]="
                          postForm.get('title')?.value !== null &&
                          this.sharedService.getTitleLength(sharedService.pinterestProvider) -
                            postForm.get('title')?.value.trimStart().length <=0">
                          {{ this.sharedService.getTitleLength(sharedService.pinterestProvider) -
                          postForm.get("title")?.value.trimStart().length }}
                        </span>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

        <div class="form_input_in">
          <nz-form-item class="title_input">
            <nz-form-control [nzSm]="24" [nzXs]="24">
                <input nz-input formControlName="title" placeholder="Enter Title" class="form-control-input"
                [class.disabled_while_loading]="isLoading" (keydown.enter)="prevent($event)" />
            </nz-form-control>
          </nz-form-item>
          <div class="social_max_legth_msg form-validation">
            <div class="error-msg-style error-color YouTube_error" *ngIf="postForm.get('title').hasError('youtubeTitleMaxlength')  ">
              <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
              {{ postForm.get("title")?.getError("youtubeTitleMaxlength") }}
            </div>
            <div class="error-msg-style error-color Pinterest_error" *ngIf="postForm.get('title').hasError('pinterestTitleMaxlength') ">
              <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
              {{ postForm.get("title")?.getError("pinterestTitleMaxlength") }}
            </div>
          </div>
        </div>
      </div>

      <div class="provider_collapse">
        <!-- Youtube -->
        <div class="provider_collapse_in" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider)">
          <nz-collapse class="youtube_form">
            <nz-collapse-panel nzHeader="YouTube" [nzActive]="true">
                <nz-form-item class="youtube_form_item">
                <nz-form-label>YouTube Video Tags</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-select nzMode="tags" [nzTokenSeparators]="[',']" [(ngModel)]="listOfVideoTags"
                    nzPlaceHolder="Enter comma-separated values" formControlName="youtube_video_tag"
                    [nzDisabled]="isLoading">
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

                <nz-form-item>
                    <nz-form-label>Privacy Settings</nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-select formControlName="privacy" [nzDisabled]="isLoading">
                            <nz-option nzValue="public" nzLabel="Public"></nz-option>
                            <nz-option nzValue="private" nzLabel="Private"></nz-option>
                            <nz-option nzValue="unlisted" nzLabel="Unlisted"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label>Category</nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-select formControlName="category" [nzDisabled]="isLoading">
                            <nz-option *ngFor="let cat of youtubeCategories" [nzValue]="cat.id" [nzLabel]="cat.title"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

              <nz-form-item>
                <nz-form-label>Audience</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-radio-group formControlName="audience" (ngModelChange)="isCommentDisplay()"
                    [nzDisabled]="isLoading">
                    <label nz-radio nzValue="true">Yes, it's made for kids</label>
                    <label nz-radio nzValue="false">No, it's not made for kids</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </nz-collapse-panel>
          </nz-collapse>
        </div>

        <!-- Pinterest -->
        <div class="provider_collapse_in" *ngIf="allAccountProvider.includes(sharedService.pinterestProvider)">
          <nz-collapse>
            <nz-collapse-panel nzHeader="Pinterest" [nzActive]="true">
                <nz-form-item>
                    <nz-form-label>Link</nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <input nz-input formControlName="pinterest_link" placeholder="Enter Link" class="input_title"
                               [class.disabled_while_loading]="isLoading" (keydown.enter)="prevent($event)" />
                    </nz-form-control>
              </nz-form-item>
              <nz-form-item *ngFor="let account of pinterestAccounts">
                <nz-form-label>Board ({{account.name}})</nz-form-label>
                <nz-select [nzDropdownRender]="renderTemplate" nzPlaceHolder="Select Board"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="account.board_id"
                  (ngModelChange)="changeBoard($event,account.social_id)" [nzDisabled]="isLoading">
                    <nz-option *ngFor="let board of pinterestBoards[account.social_id]" [nzValue]="board.id"
                               [nzLabel]="board.name"></nz-option>
                </nz-select>
                <ng-template #renderTemplate>
                  <nz-divider></nz-divider>
                  <div class="container select_input_container">
                    <input type="text" nz-input #inputElement placeholder="Enter Board Name" [disabled]="isBoardCreating" />
                    <button type="button" nz-button nzType="primary"
                      (click)="createPinterestBoard(inputElement, account.social_id)"
                      [disabled]="isLoading || isBoardCreating">
                      Create Board
                      <ng-container *ngIf="isBoardCreating">
                        <span nz-icon nzType="loading"></span>
                      </ng-container>
                    </button>
                  </div>
                </ng-template>
              </nz-form-item>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <!--Feed-->
      <div class="btn_publish" *ngIf="isFeedPost">
          <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/summery_img_3.png" alt="">
            </div>
            <div class="detail_sec">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>

          <button *ngIf="userRole !== 'Moderator'" type="button" class="btn_publish_in" (click)="openScheduleModel()"
                  [disabled]="isPublishBtnDisabled() || postForm.invalid ||  this.selectedAccounts.length === 0 || isLoading">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
              <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button *ngIf="currentPageRole !== 'Moderator'" type="button" class="btn_publish_in"
          (click)="submit({submit_type:'queue'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isMultipleRoleSelected ||  this.selectedAccounts.length === 0 || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <!-- && loadingPostType=='post' -->
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>

      <!-- <div class="btn_publish intro_post_actions" joyrideStep="post_schedule" stepPosition="top"
        [stepContent]="submitPostContent" *ngIf="!id && !isFeedPost && assigneeUsers.length==0">
        <ng-template #submitPostContent>
              <div class="step_content_wrapper">
                  <div class="icon_sec">
                      <img src="../../../assets/images/calendar_icon.png" alt="">
                  </div>
                  <div class="detail_sec">
                      <p>Schedule your post! Choose a date and time in the future, or publish it right away for instant visibility.
                          You can also choose to repeat the post on a recurring basis, or save a draft for later.
                      </p>
                  </div>
          </div>
        </ng-template>
      </div> -->

      <div class="post-btn-action" *ngIf="!id && !isFeedPost && assigneeUsers.length==0">
        <button type="button" class="btn_draft" (click)="submit({submit_type:'draft'})"
        [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_primary_outline" (click)="singlePlatformMode()" [disabled]="selectedAccounts.length === 0"> 
          Edit By Platform
         </button>
        <div class="dropdown-container" >
          <ul class="dropdown-option" [class.active]="isOpenDropdown">
            <li (click)="changeView('post-now')">Post Now</li>
            <li (click)="changeView('schedule')">Schedule</li>
            <li (click)="changeView('repeat-post')">Repeat Post</li>
          </ul>
          <div class="selected-dropdown">
            <div class="post-action"  *ngIf="btnView === 'post-now'">
              <button type="button" class="btn_publish_in btn_post" (click)="submit({submit_type:'queue'})"
                [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
                Post Now
                <ng-container *ngIf="isLoading && loadingPostType==='post'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
            <div class="post-action"  *ngIf="btnView === 'schedule'">
              <button type="button" class="btn_publish_in "
                [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
                Schedule
                <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
            <div class="post-action" *ngIf="btnView === 'repeat-post'">
              <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
                [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
                Repeat Post
                <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
          </div>
        </div>
      </div>

      <!--Custom-->
      <div class="post-btn-action" *ngIf="!id && !isFeedPost && assigneeUsers.length>0">
        <button type="button" class="btn_draft" (click)="submit({submit_type:'draft'})"
              [disabled]="isPublishBtnDisabled() || isLoading || postForm.invalid">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_primary_outline" (click)="singlePlatformMode()" [disabled]="selectedAccounts.length === 0">
          Edit By Platform
          <ng-container *ngIf="isLoading && loadingPostType === 'post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <div class="dropdown-container" >
          <ul class="dropdown-option" [class.active]="isOpenDropdown">
            <li (click)="changeView('post-now')">Post Now</li>
            <li (click)="changeView('schedule')">Schedule</li>
            <li (click)="changeView('repeat-post')">Repeat Post</li>
          </ul>
          <div class="selected-dropdown">
            <div class="post-action"  *ngIf="btnView === 'post-now'">
              <button type="button" class="btn_publish_in" (click)="submit({submit_type:'queue'})"
            [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
              Post Now
              <ng-container *ngIf="isLoading && loadingPostType==='post'">
                <span nz-icon nzType="loading"></span>
              </ng-container>
            </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
            <div class="post-action"  *ngIf="btnView === 'schedule'">
              <button type="button" class="btn_publish_in"
            [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
                Schedule
                <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
            <div class="post-action" *ngIf="btnView === 'repeat-post'">
              <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
                [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
                Repeat Post
                <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
              <span class="down-arrow" (click)="openDropdown()" nz-icon nzType="down"></span>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="btn_publish" *ngIf="!id && userRole === 'Custom' && !isFeedPost">-->
      <!-- <div class="btn_publish" *ngIf="!id && !isFeedPost && assigneeUsers.length>0">
        <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Repeat Post
          <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'draft'})"
          [disabled]="isPublishBtnDisabled() || isLoading || postForm.invalid">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'post'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="singlePlatformMode()" [disabled]="selectedAccounts.length === 0">
          Next
          <ng-container *ngIf="isLoading && loadingPostType === 'post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div> -->

      <div class="btn_publish" *ngIf="id">
        <button type="button" class="btn_publish_in" (click)="back()" [disabled]="isLoading">
          Back
        </button>
        <button type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading"
          (click)="postStatus===this.sharedService.schedule ? openScheduleModel() : submit({submit_type:'draft'})">
          Update
          <ng-container *ngIf="isLoading">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>

    </form>
  </div>

  <div class="create_content_right">

      <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 8 }" *ngIf="this.selectedAccounts.length === 0"></nz-skeleton>
      <app-preview-post *ngIf="this.selectedAccounts?.length > 0"
                      [page]="'create_post'"
                      [userData]="this.selectedAccounts"
                      [title]="postForm.value.title"
                      [profilePic]="selectedAccounts[0]?.image"
                      [name]="selectedAccounts[0]?.name"
                      [content]="postForm.value.content"
                      [firstComment]="postForm.value.first_comment"

                      [images]="images"
                      [mediaData]="mediaData"
                      [postMedias]="postMedias"
                      [galleryMediaData]="galleryMediaData"
                      [multi_images]="multiImages"
                      [isFeedPost]="isFeedPost"
                      [shortUrls]="shortUrls"
                      [isContentSubscribe] = true
                      [type]="type"
                      [audience]="postForm.value.audience" [edit]="false"
                      [delete]="false"
                      [id]="null"
                      [validationErrors]="validationErrors"
                      [postErrors]="postFailed"
                      [from]="''"
                      [YTThumbnail]="youtubeThumbnails?.length>0 ? youtubeThumbnails[0] : ''"
                      [instaReelThumbnail]="instaReelThumbnails?.length>0 ? instaReelThumbnails[0] : ''"
                      [instagramType]="postForm.value.instagram_type"
                      [facebookType]="postForm.value.facebook_type"
                      [isCommentVisible]="isCommentVisibleForLinkedIn"
                      [boardName]="selectedBoardName"
                      [selectedLocation]="selectedLocation"
                      [facebookMentions]="selectedFacebookMentions"
                      [instagramMentions]="selectedInstagramMentions"
                      [twitterMentions]="selectedTwitterMentions"
                      [threadsMentions]="selectedThreadsMentions"
                      [tiktokMentions]="selectedTiktokMentions"></app-preview-post>


    <!--Assign details-->
    <div class="cp_details_main" *ngIf="sharedService.canAccessTeam()
          && (isAssigneeUsersLoading ||  assigneeUsers.length>0) && !user.is_super_admin">

      <ng-container *ngIf="isAssigneeUsersLoading">
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
      </ng-container>

      <ng-container *ngIf="!isAssigneeUsersLoading && assigneeUsers.length>0">
        <div class="title_wrp">
          <h5>Details</h5>
        </div>
        <div class="cp_details_body">
          <div class="cp_details_in">
            <label>Assignee</label>
            <div class="cp_details_form drop_down_arrow">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Please select Assignee" nzDropdownClassName="cp_assignee"
                    [nzCustomTemplate]="cp_assignee" nzMode="multiple" [(ngModel)]="selectedAssigneeUsers">
                    <nz-option nzCustomContent *ngFor="let user of assigneeUsers" [nzValue]="user">
                      <div class="cp_team_main">
                        <div class="img_sec">
                          <app-image-preview [imageUrl]="user?.profile_pic" [class]="'profile-image'"
                            [isVideo]="false"></app-image-preview>
                        </div>
                        <div class="content_sec">
                          <h5>{{user.name}}</h5>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
                <ng-template #cp_assignee let-selected>
                  <div class="ant-select-selection-item-content">
                    <div class="cp_team_main">
                      <div class="img_sec">
                        <app-image-preview [imageUrl]="selected.nzValue?.profile_pic" [class]="'profile-image'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
          <div class="cp_details_in">
            <label>Created by</label>
            <div class="cp_details_form">
              <div class="crted_by">
                <div class="img_wrp">
                  <app-image-preview [imageUrl]="user.profile_pic" [class]="'profile-image'"
                    [isVideo]="false"></app-image-preview>
                </div>
                <div class="dtl_wrp">
                  <h5>{{user.name}}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="cp_details_in">
            <label>Priority</label>
            <div class="cp_details_form">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Please select priority" nzDropdownClassName="cp_priority"
                    [nzCustomTemplate]="cp_priority" [(ngModel)]="selectedPriority">
                    <nz-option nzCustomContent *ngFor="let priority of sharedService.priorities; let i=index"
                      [nzValue]="priority">
                      <div class="cp_prior_wrp">
                        <img [src]="'../../../assets/images/'+priority.image" [alt]="priority.name">
                        <span [class]="priority.class">{{priority.name}}</span>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
                <ng-template #cp_priority let-selected>
                  <div class="ant-select-selection-item-content">
                    <div class="cp_prior_wrp">
                      <img [src]="'../../../assets/images/'+selected.nzValue.image" [alt]="selected.nzValue.name">
                      <span [class]="selected.nzValue.class">{{selected.nzValue.name}}</span>
                    </div>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!--</form>-->
</div>

<!-- Platform wise flow -->
<ng-container *ngIf="isSinglePlatformMode">
  <div class="post_nz_stepper">
    <!-- [nzCurrent]="1" nzStatus="error" -->
    <nz-steps [nzCurrent]="current" (nzIndexChange)="tabChange($event)">
      <nz-step *ngFor="let provider of previewProviders"
      [nzDisabled]="currentUploading.length>0 || isLoading || isCheckValidation"
      [nzTitle]="provider.provider === 'TWITTER' ? 'X' : (provider.provider | titlecase)"
      [class.ant-steps-item-error]="providerHasError(provider.provider)"
      ></nz-step>
      <nz-step [nzDisabled]="currentUploading.length>0 || isLoading" class="review_step_title" nzTitle="Review & Confirm"></nz-step>
    </nz-steps>
    <div class="post_stepper_content">
      <ng-container *ngIf="current < previewProviders.length">
        <ng-container *ngIf="currentProvider">
          <div class="create_content" >
            <div class="create_content_left">
              <form *ngIf="singleProviderForm" id="singlePlatformForm" [formGroup]="singleProviderForm" (dragover)="onDocumentDragOver($event)" (drop)="onDrop($event)">
                <!-- Post Text -->
                <div class="form_input_wrp form_post_desc intro_description">
                  <div class="title_sec description_title_sec">
                    <div>
                      <h5 class="text-24">Description</h5>
                      <span class="text-16">Enter your post text, or click the 'Write for me' button to open ChatGPT.</span>
                    </div>
        
                    <div class="content-length">
                      <ul>
                        <li> {{ contentControl?.value === null ? 0 : tweetWordCount(contentControl?.value) }} </li>
                        <span>/</span>
                        <ng-container>
                          <li>
                              <img [src]="'assets/images/providers/medium/'+currentProvider.provider+'.png'" class="icon" alt="" />
                              <span [class.warning-color]="isContentWarning[currentProvider.provider]">
                                {{ remainingCharacters[currentProvider.provider] }}
                              </span>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="form_input_in">
                    <div class="post_text_input">
                      <ng-container *ngIf="contentControl">
                        <textarea class="content" nz-input [formControl]="contentControl" placeholder="Start to Write" (keyup)="setValidation()"
                                  [class.disabled_while_loading]="schedulePostDetailLoading || isLoading" #inputContent>
                        </textarea>
                      </ng-container>
                      
                      <div class="post_input_action">
                        <div class="post_input_action_left">
                          <button class="btn_primary" (click)="writeForMe()" [disabled]="selectedAccounts.length===0" nz-tooltip
                            [nzTooltipTitle]="selectedAccounts.length===0 ? 'Please select an account before creating any post' : ''">
                            <img src="../../../assets/images/icon-chatgpt-white.svg" alt="">
                            <span>Write for Me</span>
                          </button>
                          <button class="btn-shorten" (click)="openShortenUrlModal()" *ngIf="matches?.length > 0">
                            <img src="../../../assets/images/icon-shorten.svg" alt="">
                            <span>Short Link</span>
                            <span *ngIf="shortUrls.length > 0">({{shortUrlCount}})</span>
                          </button>
                          <button class="btn-shorten reset-shorten" (click)="resetShortUrls()" nz-tooltip nzTooltipTitle="Reset Shorten URL"
                            *ngIf="matches?.length > 0 && shortUrls.length > 0">
                            <span>Reset</span>
                          </button>
                        </div>
        
                        <div class="post_input_action_right">
                          <!--Auto Hashtag-->
                          <ng-container>
                            <button type="button" class="btn btn_auto_hash" [class.disabled_while_loading]="isLoading"
                              [disabled]="selectedAccounts?.length===0 || contentControl?.value?.length==0"
                              (click)="autoHashTagDrawer()">
                              <img src="../../../assets/images/auto-hashtag-icon.svg" alt="">
                              <span>Auto Hashtags</span>
                            </button>
                            <ng-template #contentTemplate>
                              <div class="cus_has_tag_main">
                                <div class="cus_has_tag_in">
                                  <label>Max number of hashtags</label>
                                  <nz-input-number [(ngModel)]="hashtagLimit" [nzMin]="1" [nzMax]="20" [nzStep]="1"
                                    nzPlaceHolder="Max Hashtags" [ngModelOptions]="{standalone: true}"></nz-input-number>
                                </div>
                                <div class="cus_has_tag_in">
                                  <label>Hashtag position</label>
                                  <nz-radio-group [(ngModel)]="hashtagPosition" [ngModelOptions]="{standalone: true}">
                                    <label nz-radio-button nzValue="auto"><span>Anywhere</span></label>
                                    <label nz-radio-button nzValue="end"><span>at the end</span></label>
                                  </nz-radio-group>
                                </div>
                                <div class="cus_has_btn">
                                  <button class="btn_secondary" nz-button nzType="primary" (click)="closeModal()"
                                    [disabled]="isAutoHashtagLoading">
                                    Cancel
                                  </button>
                                  <button class="btn_primary" nz-button nzType="primary" (click)="getAutoHashtagText()"
                                    [disabled]="isAutoHashtagLoading">
                                    Apply
                                    <ng-container *ngIf="isAutoHashtagLoading">
                                      <span nz-icon nzType="loading"></span>
                                    </ng-container>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                          </ng-container>
        
        
                          <button type="button" class="btn btn_hash" [class.disabled_while_loading]="isLoading"
                            (click)="hashTagDrawer()" [disabled]='selectedAccounts.length===0'>
                            <img src="../../../assets/images/hashtags_templates.svg" alt="">
                            <!-- <span>Hashtags & Templates</span> -->
                            <span>Templates</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <!-- Social media max length erorr messages -->
                    <div class="social_max_legth_msg">
                      <div *ngIf="currentProvider.provider === sharedService.twitterProvider"
                      class="error-color error-msg-style Twitter_error">
                      <img src="../../../assets/images/providers/medium/TWITTER.png" alt="Twitter Logo" class="icon" />
                      The system removes '#' symbols from X posts, leaving only the keyword.  X has stated that they will downgrade posts that continue to use the hashtag symbol.
                    </div>
                      <div *ngIf="currentProviderControls.controls.content?.value.trimStart().length > 280 && currentProvider.provider === sharedService.twitterProvider"
                          class="error-color error-msg-style Twitter_error">
                        <img src="../../../assets/images/providers/medium/TWITTER.png" alt="Twitter Logo" class="icon" />
                          Max limit of Twitter is 280 characters, and exceeding that limit will convert the rest of the text into Twitter thread.
                      </div>
                      <div *ngIf="currentProviderControls.controls.content.errors?.['linkedinMaxlength'] && currentProvider.provider === sharedService.linkedinProvider" class="error-color error-msg-style LinkedIn_error">
                        <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['linkedinMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content?.errors?.['instagramMaxlength'] && currentProvider.provider === sharedService.instagramProvider" class="error-color error-msg-style Instagram_error">
                        <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['instagramMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content?.errors?.['facebookMaxlength']  && currentProvider.provider === sharedService.facebookProvider" class="error-color error-msg-style Facebook_error">
                        <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['facebookMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content?.errors?.['youtubeMaxlength'] && currentProvider.provider === sharedService.youtubeProvider" class="error-color error-msg-style YouTube_error">
                        <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['youtubeMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content?.errors?.['pinterestMaxlength'] && currentProvider.provider === sharedService.pinterestProvider" class="error-color error-msg-style Pinterest_error">
                        <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['pinterestMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content.errors?.['tiktokMaxlength'] && currentProvider.provider === sharedService.tiktokProvider" class="error-color error-msg-style Tiktok_error">
                        <img src="assets/images/tiktok.svg" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['tiktokMaxlength'] }}
                      </div>
                      <div *ngIf="currentProviderControls.controls.content?.errors?.['threadsMaxlength']  && currentProvider.provider === sharedService.threadsProvider" class="error-color error-msg-style Threads_error">
                        <img src="../../../assets/images/providers/medium/THREADS.png" class="icon" />
                        {{ currentProviderControls.controls.content?.errors?.['threadsMaxlength'] }}
                      </div>
                    </div>
        
                    <div>
                      <div class="des_action" *ngIf="!isFeedPost">
                        <button type="button" href="javascript:;" (click)="openUploadModel()" nz-tooltip nzTooltipTitle="Gallery"
                                [disabled]="currentUploading.length>0 || isLoading">
                            <img src="./assets/images/des_action_icon_1.svg" alt="">
                        </button>
        
                        <button type="button" href="javascript:;" [disabled]="currentUploading.length>0 || isLoading"
                                class="upload_media" nz-tooltip
                                [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload Media : Please select an account before creating any post' : 'Upload'">
                                <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzLimit]="10"
                                          [nzAccept]="supportedMediaFiles" [nzDisabled]="selectedAccounts.length===0">
                                    <img src="./assets/images/upload_media.svg" style="height:24px;" alt="">
                            <!-- Start dragging message -->
                            <div class="dragging_msg_main">
                              <div class="dragging_msg_in">
                                <div class="icon_sec">
                                  <img src="../../../assets/images/icon-upload.svg" alt="">
                                </div>
                                <div class="content_sec">
                                  <span class="msg_error">Please select an account before creating any post</span>
                                  <span class="msg_success">Drop file to upload</span>
                                </div>
                              </div>
                            </div>
                            <!-- End dragging message -->
                          </nz-upload>
                        </button>
        
                        <button type="button" href="javascript:;" nz-tooltip
                                [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload GIF : Please select an account before creating any post' : 'Upload GIF'"
                          [disabled]="currentUploading.length>0 || isLoading">
                            <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="false"
                              nzAccept=".gif" [nzDisabled]="selectedAccounts.length===0">
                              <img src="./assets/images/des_action_icon_2.svg" alt="">
                            </nz-upload>
                        </button>
        
                        <button *ngIf="[sharedService.facebookProvider,sharedService.instagramProvider].includes(currentProvider.provider)"
                              type="button" nz-tooltip nzTooltipTitle="Location" (click)="showLocationModal()">
                          <img src="../../../assets/images/location.svg" alt="" style="height: 24px;"
                              [class.active]="this.selectedLocation!=null">
                        </button>
        
                        <button type="button" href="javascript:;" nz-dropdown [nzDropdownMenu]="openEmojis" nzTrigger="click"
                          nz-tooltip nzTooltipTitle="Emojis" [disabled]="isLoading">
                          <img src="../../../assets/images/emoji_icon.svg" alt="">
                        </button>
        
                        <nz-dropdown-menu #openEmojis="nzDropdownMenu">
                          <div class="btn_publish_dropdown_menu">
                            <emoji-mart [showSingleCategory]="true" [perLine]="8" (emojiClick)="addEmoji($event)"
                              [showPreview]="false"></emoji-mart>
                          </div>
                        </nz-dropdown-menu>
        
        
                        <!--<ng-container *ngIf="betaUsers.includes(userId)">-->
                        <ng-container>
                          <button *ngIf="[sharedService.facebookProvider,sharedService.instagramProvider,sharedService.threadsProvider, sharedService.tiktokProvider, sharedService.twitterProvider].includes(currentProvider.provider)"
                                  type="button"  (click)="platFormSpecificDrawer()">
                              <img src="../../../assets/images/mention_icon.svg" alt="">
                          </button>
                        </ng-container>
        
                        <button class="beta-label" type="button" [disabled]="selectedAccounts.length===0 || isLoading" (click)="isImageGenerateModal=true">
                          <span class="beta-tag" >Beta</span>
                          <img src="../../../assets/images/chipset.svg" style="height: 24px;" alt="">
                        </button>
                      </div>

                    <div *ngIf="errorMessages[currentProvider.provider]" class="error-color text-14 upload_img_error">
                      <img src="../../../assets/images/error_icon.svg" alt="">
                      <span>{{ this.errorMessages[currentProvider.provider] }}</span>
                    </div>
                    
                    <ng-container *ngIf="uploadImgError?.length > 0 && (currentProvider.provider === sharedService.instagramProvider)"> 
                      <div *ngFor="let errorObj of uploadImgError" class="error-color text-14 upload_img_error">
                        <img src="../../../assets/images/error_icon.svg" alt="">
                          <span>{{ errorObj.message }}</span>
                      </div>
                    </ng-container>
                    </div>
                  </div>
                  
                </div>
        
                <!-- Post Images -->
                <div class="app_upload_div">
        
                  <app-gallery-preview *ngIf="postMedias.length > 0"
                    [isForUpdate]="false" [initLoadGallery]="false" [allAccountProvider]="allAccountProvider"
                    [postMedias]="postMedias"
                    [galleryMediaData]="galleryMediaData"
                    [maxLength]="maxLength"
                    [type]="type"
                    (deleteChange)="deleteChange($event)" [youtubeThumbnails]="youtubeThumbnails" [isFeedPost]="isFeedPost"
                    [instagramType]="currentProviderControls?.value.instagram_type" [instaReelThumbnails]="instaReelThumbnails"
                    (editImageChanges)="editImageChanges($event)" [page]="'create_post'" [isPosting]="isLoading"
                    (imagesChanged)="updateImagesIndex($event)" [multi_images]="multiImages" [isSinglePlatformMode]="isSinglePlatformMode">
                  </app-gallery-preview>
        
                  <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false"
                    *ngFor="let uploading of currentUploading">
                    <ng-container>
                      <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
                        *ngIf="uploading.loading"></nz-progress>
                      <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
                    </ng-container>
                  </nz-upload>

                  <!--YouTube Thumbnail-->
                  <ng-container *ngIf="currentProvider.provider === sharedService.youtubeProvider && postMedias.length > 0 && postMedias[0].file_type=== 'video'">
                    <div class="upload_img_wrp yt_thumbnail preview_thumbnail">
                      <div class="margin-top-2">
                        <h3>YouTube Video Thumbnail</h3>
                        <div class="upload_img" [class.disabled_while_loading]="isLoading">
                          <ng-container *ngIf="youtubeThumbnails?.length > 0">
                            <div class="upload_img_in multi_image_upload" *ngFor="let image of youtubeThumbnails; let i = index">

                              <div class="hover_option" [class.disabled_while_loading]="isLoading">
                                <div class="hover_option_icon">
                                  <img src="../../../assets/images/option_dots.svg" alt="">
                                </div>
                                <ul>
                                  <li *ngIf="!isFeedPost">
                                    <a href="javascript:;" (click)="deleteThumbnailImage(i, images[i])">
                                      Remove
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
                              </app-gallery-image-preview>
                            </div>
                          </ng-container>
                        </div>
                        <nz-upload *ngIf="youtubeThumbnails?.length === 0" nzName="post" nzListType="picture-card"
                          [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadThumbnail" [nzDisabled]="isLoading"
                          [class.disabled_while_loading]="isLoading" [nzAccept]="supportedThumbnailFileTypes.toString()">
                          <ng-container>
                            <i class="upload-icon" nz-icon [nzType]="isLoading ? 'loading' : 'plus'" *ngIf="isLoading"></i>
                            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!isLoading" alt="">
                            <div class="ant-upload-text">Upload</div>
                          </ng-container>
                        </nz-upload>
                      </div>
                    </div>
                  </ng-container>

                  <!--Instagram Reels Thumbnail && && currentProviderControls.controls?.instagram_type?.value != 'reel_post' --> 
                  <ng-container *ngIf="currentProvider.provider === sharedService.instagramProvider && currentProviderControls.controls?.instagram_type?.value === 'reel_post' && postMedias.length > 0 && postMedias[0].file_type === 'video'">
                    <div class="upload_img_wrp yt_thumbnail preview_thumbnail">
                      <div class="margin-top-2">
                        <h3>Instagram Reel Thumbnail</h3>
                        <div class="upload_img" [class.disabled_while_loading]="isLoading">
                          <ng-container *ngIf="instaReelThumbnails?.length > 0">
                            <div class="upload_img_in multi_image_upload" *ngFor="let image of instaReelThumbnails; let i = index">
                              <div class="hover_option" [class.disabled_while_loading]="isLoading">
                                <div class="hover_option_icon">
                                  <img src="../../../assets/images/option_dots.svg" alt="">
                                </div>
                                <ul>
                                  <li *ngIf="!isFeedPost">
                                    <a href="javascript:;" (click)="deleteThumbnailImage(i, sharedService.instagramProvider)">
                                      Remove
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
                              </app-gallery-image-preview>
                            </div>
                          </ng-container>
                        </div>
                        <nz-upload *ngIf="instaReelThumbnails?.length === 0" nzName="post" nzListType="picture-card"
                                  [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadInstagramReelThumbnail"
                                  [nzDisabled]="isLoading"
                                  [class.disabled_while_loading]="isLoading"
                                  [nzAccept]="supportedThumbnailFileTypes.toString()">
                          <ng-container>
                            <i class="upload-icon" nz-icon [nzType]="isLoading ? 'loading' : 'plus'" *ngIf="isLoading"></i>
                            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!isLoading" alt="">
                            <div class="ant-upload-text">Upload</div>
                          </ng-container>
                        </nz-upload>
                      </div>
                    </div>
                  </ng-container>
                </div>

                

                
                <!-- Youtube Controls -->
                <div class="provider_collapse">
                  <div class=" provider_collapse_in" *ngIf="currentProvider.provider === sharedService.instagramProvider">
                    <nz-collapse class="instagram_form">
                      <nz-collapse-panel nzHeader="Instagram" [nzActive]="true">
                        <div class="form_input_wrp">
                          <div class="title_sec">
                            <h5 class="text-24">Instagram Post Type</h5>
                          </div>
                          <div class="form_input_in">
                            <nz-form-item class="description_input">
                              <nz-input-group>
                                <nz-form-control [formGroup]="currentProviderControls">
                                  <nz-radio-group formControlName="instagram_type" (ngModelChange)="isCommentDisplay()" [class.disabled_while_loading]="isLoading">
                                    <label nz-radio nzValue="feed_post">
                                      Standard Post
                                    </label>
                                    <label nz-radio nzValue="reel_post">
                                      Reel Post
                                    </label>
                                    <label nz-radio nzValue="story_post">
                                      Story
                                    </label>
                                  </nz-radio-group>
                                </nz-form-control>
                              </nz-input-group>
                            </nz-form-item>
                          </div>
                        </div>
                        <div class="form_input_wrp" *ngIf="currentProviderControls.controls.instagram_type.value ==='reel_post'">
                          <div class="title_sec">
                            <h5 class="text-24">In Instagram do you want to post it as a standard post also?</h5>
                          </div>
                          <div class="form_input_in">
                            <nz-form-item class="inst_stad_post">
                              <nz-input-group [formGroup]="currentProviderControls">
                                <nz-form-control>
                                  <label nz-checkbox nzDisabled formControlName="is_instagram_reels_post"
                                    [class.disabled_while_loading]="isLoading">
                                    <span>Reels</span>
                                  </label>
                                </nz-form-control>
                                <nz-form-control>
                                  <label nz-checkbox formControlName="is_instagram_standard_post"
                                    [class.disabled_while_loading]="isLoading">
                                    <span>Standard post</span>
                                  </label>
                                </nz-form-control>
                              </nz-input-group>
                            </nz-form-item>
                          </div>
                        </div>
                        <!--Collaborators-->
                        <div class="form_input_wrp" *ngIf="currentProviderControls.controls.instagram_type.value !=='story_post'">
                          <div class="title_sec">
                            <h5 class="text-24">Collaborators</h5>
                            <span>You can use Instagram Collabs to co-author posts with other accounts.
                              The creator of the original post can invite another account as a collaborator.
                              If the account accepts the invite, their username will be added to the post and the post will be shared with their followers.
                              Simply add the co-authors IG username below (without the @ symbol) and they will be notified through Instagram.</span>
                          </div>
                          <div class="form_input_in">
                            <div class="insta_collaborators post_to_input">
                              <div class="insta_message">
                                  <span>Limited to 3 accounts</span>
                              </div>
                              <div [formGroup]="currentProviderControls">
                                <nz-select class="inst_stad_post" nzPlaceHolder="Enter Instagram username"
                                  formControlName="instagram_collaborators" [nzMaxMultipleCount]="3"
                                  nzMode="tags" nzAllowClear style="width: 100%;"></nz-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                  <div class=" provider_collapse_in" *ngIf="currentProvider.provider === sharedService.facebookProvider">
                    <nz-collapse class="instagram_form">
                      <nz-collapse-panel nzHeader="Facebook" [nzActive]="true">
                        <div class="form_input_wrp">
                          <div class="title_sec">
                            <h5 class="text-24">Facebook Post Type</h5>
                          </div>
                          <div class="form_input_in">
                            <nz-form-item class="description_input">
                              <nz-input-group>
                                <nz-form-control [formGroup]="currentProviderControls">
                                  <nz-radio-group formControlName="facebook_type" (ngModelChange)="isCommentDisplay()" [class.disabled_while_loading]="isLoading">
                                    <label nz-radio nzValue="feed_post">
                                      Standard Post
                                    </label>
                                    <label nz-radio nzValue="story_post">
                                      Story
                                    </label>
                                  </nz-radio-group>
                                </nz-form-control>
                              </nz-input-group>
                            </nz-form-item>
                          </div>
                        </div>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                  <ng-container *ngIf="creatorInfo.length && currentProvider.provider === sharedService.tiktokProvider" [formGroup]="currentProviderControls">
                    <ng-container formArrayName="tiktok_creator_info">
                      <div *ngFor="let account of creatorInfo; let i=index">
                        <div class="provider_collapse_in" *ngIf="account" [formGroupName]="i">
                          <nz-collapse class="instagram_form">
                            <nz-collapse-panel [nzHeader]="'TikTok Options (' + getAccountNameLevel(account.value.social_id) +')'"
                              [nzActive]="true">
                              <div class="tiktok_input">
                                <nz-form-item>
                                  <label>Who can view this video</label>
                                  <!--[nzLabel]="getFormatedLable(level)" [nzValue]="level"-->
                                  <nz-select formControlName="privacy_level" [nzPlaceHolder]="'Select Privacy Level'" nzSize="large">
                                    <nz-option *ngFor="let level of getAccountPrivacyLevel(account.value.social_id)"
                                      [nzLabel]="level.name" [nzValue]="level.id">
                                    </nz-option>
                                  </nz-select>
                                </nz-form-item>
                                <div class="social_max_legth_msg form-validation" >
                                  <div *ngIf="currentProviderControls.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.invalid && 
                                  (currentProviderControls.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.touched)"
                                  class="error-msg-style error-color Tiktok_error" style="margin-bottom: 15px;">
                                  <img src="../../../assets/images/providers/medium/TIKTOK.png" alt="TikTok Logo" class="icon" />
                                    TikTok Privacy level is required.
                                  </div>
                                </div>
                                <label nz-checkbox formControlName="allow_comment" nzValue="comments">Allow comments</label>
                                <label nz-checkbox formControlName="allow_duet" nzValue="duets">Allow duets</label>
                                <label nz-checkbox formControlName="allow_stitch" nzValue="stitches">Allow stitches</label>
                              </div>
                            </nz-collapse-panel>
                          </nz-collapse>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                
                <!-- First Comment -->
                <div class="form_input_wrp" *ngIf="(currentProvider.provider === sharedService.facebookProvider && currentProviderControls.controls.facebook_type.value ==='feed_post'
                || (currentProvider.provider === sharedService.instagramProvider && currentProviderControls.controls.instagram_type.value ==='feed_post')
                || currentProvider.provider === sharedService.linkedinProvider
                || (currentProvider.provider === sharedService.youtubeProvider && currentProviderControls.controls.audience.value === 'false' ))  
                ">
                  <div class="title_sec">
                    <h5 class="text-24">First Comment <i nz-icon nzType="info-circle" nzTheme="fill"
                        nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
                        nzTooltipTitle="You can only add first comment on facebook, instagram, linkedin and youtube."></i>
                    </h5>
                    <div class="content-length">
                      <ul>
                        <li> {{ firstCommentControl?.value === null ? 0 : tweetWordCount(firstCommentControl?.value) }} </li>
                        <span>/</span>
                        <ng-container>
                          <li>
                              <img [src]="'assets/images/providers/medium/'+currentProvider.provider+'.png'" class="icon" alt="" />
                              <span [class.warning-color]="isCommentWarning[currentProvider.provider]">
                                {{ remainingCommentCharacters[currentProvider.provider] }}
                              </span>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="form_input_in">
                    <nz-form-item class="description_input">
                      <nz-input-group>
                        <nz-form-control>
                          <div [formGroup]="currentProviderControls">
                            <textarea class="margin-top-1" nz-input placeholder="Write Your First Comment" formControlName="first_comment" 
                            [class.disabled_while_loading]="isLoading"></textarea>
                          </div>
                          <div class="social_max_legth_msg">
                            <div *ngIf="currentProviderControls.controls.first_comment?.hasError('instagramCommentMaxlength')" class="error-color error-msg-style Instagram_error">
                              <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="Instagram Logo" class="icon " />
                              {{ currentProviderControls.controls.first_comment?.getError("instagramCommentMaxlength") }}
                            </div>
                            <div *ngIf="currentProviderControls.controls.first_comment?.hasError('facebookCommentMaxlength')" class="error-color error-msg-style Facebook_error">
                              <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="Facebook Logo" class="icon " />
                              {{ currentProviderControls.controls.first_comment?.getError("facebookCommentMaxlength") }}
                            </div>
                            <div *ngIf="type==='image' && currentProviderControls.controls.first_comment?.hasError('linkedinCommentMaxlength')"
                              class="error-color error-msg-style LinkedIn_error">
                              <img src="../../../assets/images/providers/medium/LINKEDIN.png" alt="LinkedIn Logo" class="icon" />
                              {{ currentProviderControls.controls.first_comment?.getError("linkedinCommentMaxlength") }}
                            </div>
                            <div
                              *ngIf="currentProviderControls.controls.first_comment?.hasError('youtubeCommentMaxlength') && singleProviderForm.value.audience === false"
                              class="error-color error-msg-style YouTube_error">
                              <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                              {{ currentProviderControls.controls.first_comment?.getError("youtubeCommentMaxlength") }}
                            </div>
                          </div>
                        </nz-form-control>
                      </nz-input-group>
                    </nz-form-item>
                  </div>
                </div>

                <!-- Post Title -->
                <div class="form_input_wrp" *ngIf="currentProvider.provider === sharedService.youtubeProvider || currentProvider.provider === sharedService.pinterestProvider">
                    <div class="title_sec">
                      <h5 class="text-24">Title <i nz-icon nzType="info-circle" nzTheme="fill"
                          nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
                          nzTooltipTitle="You can only add title on YouTube and Pinterest."></i></h5>
                      <div class="content-length">
                        <ul>
                          <li>
                            <span>{{ titleControl?.value === null ? 0 : titleControl.value.trimStart().length }}</span>
                          </li>
                            <span>/</span>
                          <li>
                            <img [src]="'assets/images/providers/medium/'+currentProvider.provider+'.png'" alt="YouTube Logo" class="icon" />
                            <span *ngIf="titleControl?.value !== null" [class.error-color]=" titleControl.value !== null &&
                              this.sharedService.getTitleLength(currentProvider.provider) - titleControl.value.trimStart().length <= 0">
                                {{ this.sharedService.getTitleLength(currentProvider.provider) - titleControl.value.trimStart().length }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="form_input_in">
                      <nz-form-item class="title_input">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div [formGroup]="currentProviderControls">
                                <input
                                  nz-input
                                  formControlName="title"
                                  placeholder="Enter Title"
                                  class="form-control-input"
                                  [class.disabled_while_loading]="isLoading"
                                  (keydown.enter)="prevent($event)"
                                />
                            </div>
                        </nz-form-control>
                        <div class="social_max_legth_msg form-validation">
                          <div class="error-msg-style error-color YouTube_error" *ngIf="currentProviderControls.controls.title.hasError('youtubeTitleMaxlength')" >
                            <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                            {{ currentProviderControls.controls.title?.getError("youtubeTitleMaxlength") }}
                          </div>
                          <div class="error-msg-style error-color YouTube_error" *ngIf="currentProviderControls.controls.title?.touched && currentProviderControls.controls.title?.hasError('required')" >
                            <img src="../../../assets/images/providers/medium/YOUTUBE.png" alt="YouTube Logo" class="icon" />
                            <span *ngIf="currentProviderControls.controls.title?.hasError('required')">Title is required.</span>
                          </div>
                          <div class="error-msg-style error-color Pinterest_error" *ngIf="currentProviderControls.controls.title.hasError('pinterestTitleMaxlength') " >
                            <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
                            {{ currentProviderControls.controls.title?.getError("pinterestTitleMaxlength") }}
                          </div>
                        </div>
                      </nz-form-item>
                    </div>
                </div>
                
                <div class="provider_collapse">
                  <div class="provider_collapse_in" *ngIf="currentProvider.provider === sharedService.youtubeProvider">
                    <nz-collapse class="youtube_form">
                      <nz-collapse-panel nzHeader="YouTube" [nzActive]="true">
                        <nz-form-item class="youtube_form_item">
                          <nz-form-label>YouTube Video Tags</nz-form-label>
                          <nz-form-control [nzSm]="24" [nzXs]="24" [formGroup]="currentProviderControls">
                            <nz-select nzMode="tags" [nzTokenSeparators]="[',']" [(ngModel)]="listOfVideoTags"
                            nzPlaceHolder="Enter comma-separated values" formControlName="youtube_video_tag"
                            [nzDisabled]="isLoading">
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label>Privacy Settings</nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                              <div [formGroup]="currentProviderControls">
                                <nz-select formControlName="privacy" [nzDisabled]="isLoading">
                                  <nz-option nzValue="public" nzLabel="Public"></nz-option>
                                  <nz-option nzValue="private" nzLabel="Private"></nz-option>
                                  <nz-option nzValue="unlisted" nzLabel="Unlisted"></nz-option>
                                </nz-select>
                              </div>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label>Category</nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                              <div [formGroup]="currentProviderControls">
                                <nz-select formControlName="category" [nzDisabled]="isLoading">
                                  <nz-option *ngFor="let cat of youtubeCategories" [nzValue]="cat.id" [nzLabel]="cat.title"></nz-option>
                                </nz-select>
                              </div>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label>Audience</nz-form-label>
                          <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div [formGroup]="currentProviderControls">
                              <nz-radio-group formControlName="audience" [nzDisabled]="isLoading">
                                <label nz-radio nzValue="true">Yes, it's made for kids</label>
                                <label nz-radio nzValue="false">No, it's not made for kids</label>
                              </nz-radio-group>
                            </div>
                          </nz-form-control>
                        </nz-form-item>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                  <div class="provider_collapse_in" *ngIf="currentProvider.provider === sharedService.pinterestProvider">
                    <nz-collapse>
                      <nz-collapse-panel nzHeader="Pinterest" [nzActive]="true">
                        <nz-form-item>
                          <nz-form-label>Link</nz-form-label>
                          <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div [formGroup]="currentProviderControls">
                              <input nz-input formControlName="pinterest_link" placeholder="Enter Link" class="input_title"
                                    [class.disabled_while_loading]="isLoading" (keydown.enter)="prevent($event)" />
                            </div>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item *ngFor="let account of pinterestAccounts">
                          <nz-form-label>Board ({{account.name}})</nz-form-label>
                          <nz-select [nzDropdownRender]="renderTemplate" nzPlaceHolder="Select Board"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="account.board_id"
                            (ngModelChange)="changeBoard($event,account.social_id)" [nzDisabled]="isLoading" name="board_id" required #boardId="ngModel">
                              <nz-option *ngFor="let board of pinterestBoards[account.social_id]" [nzValue]="board.id"
                                         [nzLabel]="board.name"></nz-option>
                          </nz-select>
                          <div class="social_max_legth_msg form-validation">
                            <div *ngIf="boardId.invalid && (boardId.dirty || boardId.touched)" class="error-msg-style error-color Pinterest_error" style="margin-top: 15px;">
                              <img src="../../../assets/images/providers/medium/PINTEREST.png" alt="Pinterest Logo" class="icon" />
                              <span *ngIf="boardId.errors?.required"> Board is required. </span>
                            </div>
                          </div>
                          
                          <ng-template #renderTemplate>
                            <nz-divider></nz-divider>
                            <div class="container select_input_container">
                              <input type="text" nz-input #inputElement placeholder="Enter Board Name" [disabled]="isBoardCreating" />
                              <button type="button" nz-button nzType="primary"
                                (click)="createPinterestBoard(inputElement, account.social_id)"
                                [disabled]="isLoading || isBoardCreating">
                                Create Board
                                <ng-container *ngIf="isBoardCreating">
                                  <span nz-icon nzType="loading"></span>
                                </ng-container>
                              </button>
                            </div>
                          </ng-template>
                        </nz-form-item>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                </div>
              </form>
            </div>
  
            <!-- Preview Post -->
            <div class="create_content_right">
              <!-- <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 8 }" *ngIf="this.platformSelectedAccounts.length === 0"></nz-skeleton> -->
              <ng-container>
                <app-preview-post *ngIf="this.platformSelectedAccounts?.length > 0" [userData]="this.platformSelectedAccounts"
                                [page]="'create_post_new_flow'"
                                [title]="currentProviderControls?.value?.title"
                                [firstComment]="currentProviderControls?.value?.first_comment"
                                [profilePic]="selectedAccounts[0]?.image"
                                [name]="selectedAccounts[0]?.name"
                                [content]="sharedService.linkifyContent(contentControl.value,currentProvider.provider)" 
                                [images]="images"
                                [mediaData]="mediaData"
                                [postMedias]="postMedias"
                                [galleryMediaData]="galleryMediaData"
                                [multi_images]="multiImages"
                                [type]="type"
                                [audience]="currentProviderControls?.value.audience" [edit]="false"
                                [delete]="false"
                                [id]="null"
                                [validationErrors]="platformValidationError"
                                [postErrors]="postFailed"
                                [from]="''"
                                [isSinglePlatformMode]="true"
                                [shortUrls]="platformWiseShortUrls[this.currentProvider.provider]"
                                [isContentSubscribe] = true
                                [YTThumbnail]="youtubeThumbnails?.length>0 ? youtubeThumbnails[0] : ''"
                                [instaReelThumbnail]="instaReelThumbnails?.length>0 ? instaReelThumbnails[0] : ''"
                                [instagramType]="currentProviderControls?.value.instagram_type"
                                [facebookType]="currentProviderControls?.value.facebook_type"
                                [isCommentVisible]="isCommentVisibleForLinkedIn"
                                [boardName]="currentProviderControls?.value.pinterest_board_names"
                                [selectedLocation]="facebookLocationControl?.value"
                                [facebookMentions]="selectedFacebookMentions"
                                [instagramMentions]="selectedInstagramMentions"
                                [twitterMentions]="selectedTwitterMentions"
                                [threadsMentions]="selectedThreadsMentions"
                                [tiktokMentions]="selectedTiktokMentions"></app-preview-post>
              </ng-container>

              <div class="fe_validation">
                <ul class="social_error_list new_social_error_list" >
                  <li class="error-color" *ngIf="currentProviderControls.controls.title?.hasError('required') && sharedService.youtubeProvider">
                    <div class="info-icon">
                      <img src="../../../assets/images/error-info.png" alt="">
                    </div>
                    <span>YouTube title is required.</span>
                  </li>
                  <li class="error-color" *ngIf="currentProviderControls.controls.tiktok_creator_info?.status === 'INVALID' && sharedService.tiktokProvider">
                    <div class="info-icon">
                      <img src="../../../assets/images/error-info.png" alt="">
                    </div>
                    <span>TikTok privacy level is not set.</span>
                  </li>
                  <li class="error-color" *ngIf="isEmptyObject(currentProviderControls.controls.pinterest_board_ids?.value) && sharedService.pinterestProvider">
                    <div class="info-icon">
                      <img src="../../../assets/images/error-info.png" alt="">
                    </div>
                    <span>Pinterest boards must be selected.</span>
                  </li>
                </ul>
              </div>
              
              <!--Assign details-->
              <div class="cp_details_main" *ngIf="sharedService.canAccessTeam() && (isAssigneeUsersLoading ||  assigneeUsers.length>0) && !user.is_super_admin">
        
                <ng-container *ngIf="isAssigneeUsersLoading">
                  <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
                </ng-container>
        
                <ng-container *ngIf="!isAssigneeUsersLoading && assigneeUsers.length>0">
                  <div class="title_wrp">
                    <h5>Details</h5>
                  </div>
                  <div class="cp_details_body">
                    <div class="cp_details_in">
                      <label>Assignee</label>
                      <div class="cp_details_form drop_down_arrow">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select nzPlaceHolder="Please select Assignee" nzDropdownClassName="cp_assignee"
                              [nzCustomTemplate]="cp_assignee" nzMode="multiple" [(ngModel)]="selectedAssigneeUsers">
                              <nz-option nzCustomContent *ngFor="let user of assigneeUsers" [nzValue]="user">
                                <div class="cp_team_main">
                                  <div class="img_sec">
                                    <app-image-preview [imageUrl]="user?.profile_pic" [class]="'profile-image'" [isVideo]="false"></app-image-preview>
                                  </div>
                                  <div class="content_sec">
                                    <h5>{{user.name}}</h5>
                                  </div>
                                </div>
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                          <ng-template #cp_assignee let-selected>
                            <div class="ant-select-selection-item-content">
                              <div class="cp_team_main">
                                <div class="img_sec">
                                  <app-image-preview [imageUrl]="selected.nzValue?.profile_pic" [class]="'profile-image'" [isVideo]="false"></app-image-preview>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </nz-form-item>
                      </div>
                    </div>
                    <div class="cp_details_in">
                      <label>Created by</label>
                      <div class="cp_details_form">
                        <div class="crted_by">
                          <div class="img_wrp">
                            <app-image-preview [imageUrl]="user.profile_pic" [class]="'profile-image'" [isVideo]="false"></app-image-preview>
                          </div>
                          <div class="dtl_wrp">
                            <h5>{{user.name}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cp_details_in">
                      <label>Priority</label>
                      <div class="cp_details_form">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select nzPlaceHolder="Please select priority" nzDropdownClassName="cp_priority"
                              [nzCustomTemplate]="cp_priority" [(ngModel)]="selectedPriority">
                              <nz-option nzCustomContent *ngFor="let priority of sharedService.priorities; let i=index" [nzValue]="priority">
                                <div class="cp_prior_wrp">
                                  <img [src]="'../../../assets/images/'+priority.image" [alt]="priority.name">
                                  <span [class]="priority.class">{{priority.name}}</span>
                                </div>
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                          <ng-template #cp_priority let-selected>
                            <div class="ant-select-selection-item-content">
                              <div class="cp_prior_wrp">
                                <img [src]="'../../../assets/images/'+selected.nzValue.image" [alt]="selected.nzValue.name">
                                <span [class]="selected.nzValue.class">{{selected.nzValue.name}}</span>
                              </div>
                            </div>
                          </ng-template>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="current === previewProviders.length">
        <div class="platform_review">
          <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 8 }" *ngIf="this.selectedAccounts.length === 0"></nz-skeleton>
          <ng-container *ngFor="let providerData of nzProviderData.value">
            <ng-container *ngFor="let accounts of providerData.accounts_id">
              <app-preview-post *ngIf="this.selectedAccounts?.length > 0" [userData]="[accounts]"
                [title]="providerData.title"
                [profilePic]="selectedAccounts[0]?.image"
                [name]="selectedAccounts[0]?.name"
                [content]="providerData.content" [firstComment]="providerData.first_comment"
                [images]="images"
                [mediaData]="mediaData"
                [postMedias]="(providerData.media || []).concat(providerData.gallery_media || [])"
                [galleryMediaData]="providerData.gallery_media"
                [multi_images]="multiImages"
                [type]="type"
                [audience]="providerData.audience" [edit]="false"
                [delete]="false"
                [id]="null"
                [validationErrors]="validationErrors"
                [postErrors]="postFailed"
                [from]="''"
                [shortUrls]="platformWiseShortUrls[providerData.provider]"
                [isContentSubscribe] = true
                [YTThumbnail]="youtubeThumbnails?.length>0 ? youtubeThumbnails[0] : ''"
                [instaReelThumbnail]="instaReelThumbnails?.length>0 ? instaReelThumbnails[0] : ''"
                [instagramType]="providerData.instagram_type"
                [facebookType]="providerData.facebook_type"
                [isCommentVisible]="isCommentVisibleForLinkedIn"
                [boardName]="providerData?.pinterest_board_names"
                [selectedLocation]="providerData?.facebook_location"
                [facebookMentions]="selectedFacebookMentions"
                [instagramMentions]="selectedInstagramMentions"
                [twitterMentions]="selectedTwitterMentions"
                [threadsMentions]="selectedThreadsMentions"
                [tiktokMentions]="selectedTiktokMentions"></app-preview-post>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="steps-action" >
        <div class="steps-action-in" [class.disabled_while_loading]="currentUploading.length>0 || isLoading || isCheckValidation">
          <div class="left-action">
            <button class="btn_primary" (click)="prePreviewProvider()" *ngIf="current != 0">
              <span>Previous</span>
              <div class="next_loader" *ngIf="isCheckValidation">
                <span nz-icon nzType="loading"></span>
              </div>
            </button>
            <button class="btn_danger" *ngIf="current < previewProviders.length && previewProviders.length > 1" (click)="removePreviewProvider(currentProvider)">
              <img src="../../../assets/images/trash-icon.svg" alt="">
              <span>Remove {{ currentProvider.provider == sharedService.twitterProvider ? 'X': currentProvider.provider | titlecase }}</span>
            </button>
          </div>
          <div class="right-action">
            <button class="btn_primary btn-default" (click)="skipPreviewPost()"><span>Cancel</span></button>
            <button class="btn_primary btn-default" [class.disabled_while_loading]="platformWisePublish()" *ngIf="current != previewProviders.length" (click)="singleProviderSubmit('queue')" ><span>Post Now</span></button>
            <button class="btn_primary btn-default" [class.disabled_while_loading]="platformWisePublish()" (click)="openScheduleModel()"><span>Schedule Post</span></button>
            <button class="btn_primary" (click)="nextPreviewProvider()" *ngIf="current < previewProviders.length">
              <span>Next</span>
              <div class="next_loader" *ngIf="isCheckValidation">
                <span nz-icon nzType="loading"></span>
              </div>
            </button>
            <!-- [class.disabled_while_loading]="platformWisePublish()" -->
            <button class="btn_primary pointer-event-visiable" 
            (mouseover)="showTooltipMessage()"
            nz-tooltip
            [nzTooltipTitle]="platformWisePublish() === true ? titleTemplate : ''" 
            nzTooltipOverlayClassName="post-tooltip-message"
            [disabled]="platformWisePublish()"
            *ngIf="current === previewProviders.length" 
            (click)="singleProviderSubmit('queue')">
              <span>Post All Now</span>
            </button>
            <ng-template #titleTemplate>
                <div >
                  <ul>
                    <li *ngFor="let errorMessage of tooltipErrorMessages">
                      {{ errorMessage }}
                    </li>
                  </ul>
                </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- ChatGpt Write for me drawer -->
<nz-drawer [nzClosable]="false" [nzVisible]="isWriteForMe" nzPlacement="right" nzWrapClassName="wfm_drawer">
  <ng-container *nzDrawerContent>
    <div class="wfm_header">
      <div class="wfm_header_left">
        <h3>Write for Me</h3>
      </div>
      <div class="wfm_header_right">
        <div class="powred_by">
          <img src="../../../assets/images/icon-chatgpt-blue.svg" alt="">
          <div class="powred_by_dtl">
            <span>Powered by</span>
            <h4>ChatGPT</h4>
          </div>
        </div>
        <a href="javascript:;" class="close_wfm_drawer" (click)="closeWfmDrawer()">
          <img src="../../../assets/images/close_icon.svg" alt="">
        </a>
      </div>
    </div>

    <div class="wfm_body">
      <div class="wfm_form" *ngIf="articles.length===0">
        <ng-container *ngIf="total_chatgpt_article_limit !== -1">
          <div class="wfm_warning" [class.noplan]="remaining_chatgpt_article_limit === 0">
            <div class="wfm_warning_chart">
              <ng-container *ngIf="remaining_chatgpt_article_limit > 0;else noPlan">
                <nz-progress [nzStrokeColor]="'#423fd1'" nzType="circle" [nzFormat]="formatProgress"
                  [nzPercent]="getPercentage()" [nzWidth]="70"></nz-progress>
              </ng-container>
              <ng-template #noPlan>
                <img src="../../../assets/images/icon-chatgpt-red.svg" alt="">
              </ng-template>
            </div>
            <div class="wfm_warning_msg">
              <label *ngIf="remaining_chatgpt_article_limit === 0">You have reached the prompt limit for
                this month. Buy Addon to get more prompts.</label>
              <label *ngIf="remaining_chatgpt_article_limit > 0">Remaining number of prompts you can use
                this month</label>
            </div>
          </div>
        </ng-container>
        <!-- <div class="wfm_form_in">
          <label>What type of content are you looking to create?</label>
          <nz-select nzPlaceHolder="Facebook post, Twitter post, Insta caption, etc."
            [(ngModel)]="selectedPlatformText" [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let platformText of platformTexts" [nzLabel]="platformText"
              [nzValue]="platformText"></nz-option>
          </nz-select>
        </div> -->
        <div class="wfm_form_in">
          <label>
            What are the main points or ideas you would like to cover in your post?
            <span nz-icon nzType="info-circle" nzTheme="fill" nz-tooltip nzTooltipTitle="For ChatGPT, the more detail you give it on what you want, the better it will do. If you want to limit character count, give it that as part of the prompt. Example: Tell me a knock knock joke in 200 characters or less."></span>
          </label>
          <textarea nz-input [(ngModel)]="articleText" [ngModelOptions]="{standalone: true}"
                    placeholder="Describe up to 3 key points for your post. Add the character limit for brevity and/or the target platform(s) (X, TikTok, etc.). You may want to leave additional room for hashtags. The more descriptive you are, the more effective your post will be.">
          </textarea>

          <span class="wfm_count">{{articleText.length + '/' + articleTextLimit}}</span>
          <span class="wfm_content_error" *ngIf="articleText.length>articleTextLimit">
            Text description exceeds the limit of {{articleTextLimit}} characters. Please shorten your
            input and try again.
          </span>
        </div>
        <div class="wfm_form_in">
          <label>What type of content are you looking to create?</label>
          <nz-select nzPlaceHolder="😄 Positive & Upbeat" nzDropdownClassName="tone_of_voice_main"
            [(ngModel)]="selectedTone" [nzCustomTemplate]="defaultTemplate"
            [ngModelOptions]="{standalone: true}">
            <nz-option *ngFor="let tone of toneTypes" nzCustomContent [nzLabel]="tone" [nzValue]="tone">
              <div class="tone_of_voice">
                <span>{{tone}}</span>
              </div>
            </nz-option>
          </nz-select>
            <ng-template #defaultTemplate let-selected>
                {{ selected.nzLabel }}
            </ng-template>
        </div>
        <div class="wfm_form_in">
          <ng-container *ngIf="remaining_chatgpt_article_limit !== 0 else subscription">
            <button class="btn_primary"
              [disabled]="isArticleLoading || articleText.length==0 || articleText.length>articleTextLimit"
              (click)="getArticles()">
              Write Now
            </button>
          </ng-container>
          <ng-template #subscription>
            <button class="btn_primary" *ngIf="!sharedService.currentSubscription?.is_trial"
              (click)="isPaymentModalVisible=true">
              Buy Addon
            </button>
          </ng-template>
        </div>
      </div>

      <div class="wfm_loader" *ngIf="isArticleLoading">
        <video playsinline="" autoplay="" muted="" loop="">
          <source src="../../../assets/images/wfm_loader.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="writing_list" *ngIf="!isArticleLoading">
        <div class="writing_list_in" *ngFor="let article of articles">
            <ng-container *ngIf="article.text.length>0">
                <div class="content_dtl">
                    <p>{{article.text}}</p>
                </div>
                <div class="content_action">
                    <button class="btn_primary" (click)="copyText(article.text)">
                        <span nz-icon nzType="snippets" nzTheme="outline"></span>
                        <span>Add to Post</span>
                    </button>
              <div class="dtl_count">
                <span>{{article.text.length}}</span>
              </div>
            </div>
          </ng-container>
        </div>
          <div class="writing_list_in" *ngIf="articles.length>0">
            <button class="btn_primary" (click)="regenerateArticles()">Try Again</button>
          </div>
      </div>
    </div>
  </ng-container>                    
</nz-drawer>                        

<!-- Templates & Hashtags drawer -->
<nz-drawer nzWrapClassName="hash_temp_drawer" [nzClosable]="true" [nzVisible]="hashTag" nzPlacement="right"
  nzTitle="Templates" (nzOnClose)="close()">
  <ng-container *nzDrawerContent>
    <!-- <nz-tabset class="tabset_default">
      <nz-tab nzTitle="Suggestions" (nzClick)="suggestion()"> -->
        <!-- Search Start -->
        <!-- <div class="search_temp">
          <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
              fill="#676DF4" />
          </svg>
          <nz-input-group nzSearch [nzPrefix]="suffixIconSearch" nzSize="large">
            <input type="text" nz-input placeholder="Search Hashtag" #searchInput
              (keyup)="onKeyUp(searchInput.value, 1)" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
                fill="#676DF4" />
            </svg>
          </ng-template>
        </div> -->
        <!-- Search end -->

        <!-- Search static start -->
        <!-- <ng-container *ngIf="connectTwitter">
          <div class="search_temp"> -->
            <!-- <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
              fill="#676DF4" />
          </svg>
          <input nz-input /> -->

              <!-- <nz-input-group nzSearch [nzPrefix]="suffixIconSearch" nzSize="large">
                  <input type="text" nz-input placeholder="Search Hashtag" #searchInput
                         (keyup)="onKeyUp(searchInput.value, 1)" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z" fill="#676DF4" />
              </svg>
            </ng-template>
          </div> -->

            <!-- Search static end -->
            <!-- <div class="has_temp_input_wrp">
            <div class="has_temp_input_main">
              <ng-container *ngIf="searchText !== ''">
                  <div class="has_temp_input">
                  <ng-container *ngIf="connectTwitter && isLoadingHas">
                      <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container"
                      (scrolledIndexChange)="nextBatch($event)">
                          <ng-container *ngFor="let hashTag of hashList">
                              <ng-container *ngIf="searchResult && searchInput.value !== ''">
                          <label nz-checkbox [(ngModel)]="hashTag.checked" (ngModelChange)="SingleCheckedHash()">
                            <div class="has_temp_input_in">
                              <h5>#{{hashTag.name}}</h5>
                            </div>
                          </label>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="isSpinning">
                        <div class="msg_list_skeleton">
                          <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                            *ngFor="let hashtag of staticConversationHash">
                          </nz-skeleton>
                        </div>
                      </ng-container>
                    </cdk-virtual-scroll-viewport>
                  </ng-container>
                  <ng-container *ngIf="emptyData">
                    <div class="demo-infinite-container">
                      <span style="align-items: center;">
                        <app-empty [message]="'No Hashtag found !'"></app-empty>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

                <ng-container *ngIf="searchText === ''">
                    <div class="search_hastag">
                        <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
                        <span>Start Search for <strong>#Hashtags</strong> !</span>
                    </div>
                </ng-container>
            </div> -->
            <!-- && searchText != '' -->
            <!-- <ng-container *ngIf="connectTwitter && searchResult.length > 0">
              <div class="has_temp_btn_group">
                <div class="filter_count_header">
                  <strong>Selected ({{selectedHash}})</strong>
                  <a href="javascript:;" [class.disabled_while_loading]="selectedHashList.length == 0"
                    (click)="deselectHash()">Deselect All</a>
                </div>
                <div class="btn_group">
                  <button type="button" class="btn" nz-dropdown [nzDropdownMenu]="menu"
                    [nzDisabled]="selectedHashList.length == 0">Save <span nz-icon nzType="down"></span></button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                      <li nz-menu-item (click)="showModal()">
                        As new template
                      </li>
                      <li nz-menu-item (click)="existingTemp()" [nzDisabled]="allTemplate.length==0">
                        To existing template
                      </li>
                    </ul>
                  </nz-dropdown-menu> -->
                  <!-- <button type="button" nz-button nzType="primary" (click)="addToPostDirect()" [nzLoading]="selectedHashList.length == 0" >Add to Post</button> -->
                  <!-- <button nz-button nzType="primary" class="btn btn_apply" (click)="addToPostDirect()"
                    [disabled]="selectedHashList.length === 0">Add to Post
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!connectTwitter"> -->
          <!-- <div class="hashtag_limitation">
        <div class="hashtag_limitation_left">
          <i nz-icon nzType="warning" nzTheme="fill"></i>
        </div>
        <div class="hashtag_limitation_right">
          <h4> Hashtag Limitation</h4>
          <div class="lmted_msg">
            <i nz-icon nzType="twitter" nzTheme="outline"></i>
            <p>To get the suggestion for hashtag , you need to connect twitter account.</p>
          </div>
        </div>
      </div> -->
          <!-- <div class="twitr_con_msg"> -->
            <!--<div class="icon_sec">
              <span nz-icon nzType="twitter" nzTheme="outline"></span>
              <strong>#</strong>
            </div>-->
            <!-- <div class="search_hastag" style="margin: 0;border:none;">
              <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
              <div class="con_sec">
                <p>To get the suggestion for <strong>hashtag</strong> , you need to connect
                  <strong>X (Formerly Twitter)</strong> account.
                </p>
              </div>
            </div>
          </div>
        </ng-container>

      </nz-tab> 
      <nz-tab nzTitle="Templates" (nzClick)="getAllTemplate()">

        <div class="has_temp_input_wrp">
          <div class="new_temp_area">
            <a href="javascript:;" (click)="showModal()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 9C17 9.55228 16.5523 10 16 10H10V16C10 16.5523 9.55228 17 9 17V17C8.44772 17 8 16.5523 8 16V10H2C1.44772 10 1 9.55228 1 9V9C1 8.44772 1.44772 8 2 8H8V2C8 1.44772 8.44772 1 9 1V1C9.55228 1 10 1.44772 10 2V8H16C16.5523 8 17 8.44772 17 9V9Z" fill="#423FD1" />
                <path d="M10 10V9.5H9.5V10H10ZM8 10H8.5V9.5H8V10ZM8 8V8.5H8.5V8H8ZM10 8H9.5V8.5H10V8ZM16 9.5H10V10.5H16V9.5ZM9.5 10V16H10.5V10H9.5ZM8.5 16V10H7.5V16H8.5ZM8 9.5H2V10.5H8V9.5ZM2 8.5H8V7.5H2V8.5ZM8.5 8V2H7.5V8H8.5ZM9.5 2V8H10.5V2H9.5ZM10 8.5H16V7.5H10V8.5ZM9 1.5C9.27614 1.5 9.5 1.72386 9.5 2H10.5C10.5 1.17157 9.82843 0.5 9 0.5V1.5ZM8.5 2C8.5 1.72386 8.72386 1.5 9 1.5V0.5C8.17157 0.5 7.5 1.17157 7.5 2H8.5ZM1.5 9C1.5 8.72386 1.72386 8.5 2 8.5V7.5C1.17157 7.5 0.5 8.17157 0.5 9H1.5ZM9 16.5C8.72386 16.5 8.5 16.2761 8.5 16H7.5C7.5 16.8284 8.17157 17.5 9 17.5V16.5ZM9.5 16C9.5 16.2761 9.27614 16.5 9 16.5V17.5C9.82843 17.5 10.5 16.8284 10.5 16H9.5ZM2 9.5C1.72386 9.5 1.5 9.27614 1.5 9H0.5C0.5 9.82843 1.17157 10.5 2 10.5V9.5ZM16 8.5C16.2761 8.5 16.5 8.72386 16.5 9H17.5C17.5 8.17157 16.8284 7.5 16 7.5V8.5ZM16 10.5C16.8284 10.5 17.5 9.82843 17.5 9H16.5C16.5 9.27614 16.2761 9.5 16 9.5V10.5Z" fill="#423FD1" />
              </svg>
              <span>Create New Template</span>
            </a>
          </div>
          <div class="has_temp_input_main temp_container">
            <ng-container *ngIf="isHashLoading">
              <div class="msg_list_skeleton">
                <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                  *ngFor="let hashtag of staticConversationHash">
                </nz-skeleton>
              </div>
            </ng-container>
            <ng-container *ngIf="allTemplate.length>0 && !isHashLoading">
              <perfect-scrollbar style="max-height: 100%;">
                <div class="has_temp_input btn_edit_main">
                  <nz-radio-group [(ngModel)]="templateContent" nzName="radiogroup">
                    <ng-container *ngFor="let template of allTemplate">
                      <label nz-radio nzValue="{{template.content}}">
                        <div class="has_temp_input_in">
                          <h5>{{template.name}}</h5>
                          <span>{{template.content}}</span>
                          <div class="has_temp_action">
                            <a href="javascript:;" (click)="editModal(template)">
                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.1163 4.13023L17.8698 6.88238L15.1163 4.13023ZM16.887 1.70569L9.44173 9.15092C9.05703 9.53507 8.79467 10.0245 8.68771 10.5575L8 14L11.4425 13.311C11.9755 13.2044 12.4643 12.9431 12.8491 12.5583L20.2943 5.11305C20.518 4.88932 20.6955 4.62371 20.8166 4.33139C20.9377 4.03907 21 3.72577 21 3.40937C21 3.09296 20.9377 2.77966 20.8166 2.48734C20.6955 2.19502 20.518 1.92942 20.2943 1.70569C20.0706 1.48196 19.805 1.30448 19.5127 1.1834C19.2203 1.06232 18.907 1 18.5906 1C18.2742 1 17.9609 1.06232 17.6686 1.1834C17.3763 1.30448 17.1107 1.48196 16.887 1.70569V1.70569Z" stroke="#232C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.7652 15.4119V18.7649C17.7652 19.3578 17.5296 19.9263 17.1104 20.3455C16.6912 20.7648 16.1227 21.0003 15.5298 21.0003H3.23535C2.6425 21.0003 2.07393 20.7648 1.65472 20.3455C1.23551 19.9263 1 19.3578 1 18.7649V6.47046C1 5.87761 1.23551 5.30904 1.65472 4.88983C2.07393 4.47062 2.6425 4.23511 3.23535 4.23511H6.58838" stroke="#232C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                            <a href="javascript:;" (click)="deleteTemplate(template._id)">
                              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z" stroke="#232C89" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </label>
                    </ng-container>
                  </nz-radio-group>
                </div>
              </perfect-scrollbar>
            </ng-container>
            <ng-container *ngIf="allTemplate.length == 0 && !isHashLoading">
              <div class="no_templte_empty">
                <app-empty [message]="'No Template Found !'"></app-empty>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="allTemplate.length > 0 && !isHashLoading">

            <div class="has_temp_btn_group"> -->
              <!-- <div class="filter_count_header">
              <strong>Selected (2)</strong>
              <a href="javascript:;">Deselect All</a>
            </div> -->
              <!-- <div class="btn_group">
                <button type="button" class="btn btn_apply" (click)="AddToPost(templateContent)"
                  [disabled]="templateContent === ''">Add to Post</button>
              </div>
            </div>
          </ng-container>
        </div>
      </nz-tab>
    </nz-tabset> -->
    <div class="has_temp_input_wrp">
      <div class="new_temp_area">
        <a href="javascript:;" (click)="showModal()">
          <img src="../../../assets/images/icon-plus-xs.svg" alt="">
          <span>Create New Template</span>
        </a>
      </div>
      <div class="has_temp_input_main temp_container">
        <ng-container *ngIf="isHashLoading">
          <div class="msg_list_skeleton">
            <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
              *ngFor="let hashtag of staticConversationHash">
            </nz-skeleton>
          </div>
        </ng-container>
        <ng-container *ngIf="allTemplate.length>0 && !isHashLoading">
          <perfect-scrollbar style="max-height: 100%;">
            <div class="has_temp_input btn_edit_main">
              <nz-radio-group [(ngModel)]="templateContent" nzName="radiogroup">
                <ng-container *ngFor="let template of allTemplate">
                  <label nz-radio nzValue="{{template.content}}">
                    <div class="has_temp_input_in">
                      <h5>{{template.name}}</h5>
                      <span>{{template.content}}</span>
                      <div class="has_temp_action">
                        <a href="javascript:;" (click)="editModal(template)">
                          <img src="../../../assets/images/edit_icon_2.svg" alt="">
                        </a>
                        <a href="javascript:;" (click)="deleteTemplate(template._id)">
                          <img src="../../../assets/images/icon-trash-blue.svg" alt="">
                        </a>
                      </div>
                    </div>
                  </label>
                </ng-container>
              </nz-radio-group>
            </div>
          </perfect-scrollbar>
        </ng-container>
        <ng-container *ngIf="allTemplate.length == 0 && !isHashLoading">
          <div class="no_templte_empty">
            <app-empty [message]="'No Template Found !'"></app-empty>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="allTemplate.length > 0 && !isHashLoading">

        <div class="has_temp_btn_group">
          <!-- <div class="filter_count_header">
          <strong>Selected (2)</strong>
          <a href="javascript:;">Deselect All</a>
        </div> -->
          <div class="btn_group">
            <button type="button" class="btn btn_apply" (click)="AddToPost(templateContent)"
              [disabled]="templateContent === ''">Add to Post</button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</nz-drawer>

<!-- AutoHashTag drawer -->
<nz-drawer nzWrapClassName="auto_hash_tag_drawer" [nzClosable]="false" [nzVisible]="autoHashTag" nzPlacement="right">
  <!-- (nzOnClose)="closeAutoHashtag()" -->
  <ng-container *nzDrawerContent>
    <div class="auto_hash_new_hdr">
      <div class="auto_hash_new_hdr_top">
        <h5>Auto Hashtags</h5>
        <a href="javascript:;" (click)="closeAutoHashtag()">
          <img src="../../../assets/images/close_icon.svg" alt="">
        </a>
      </div>
      <div class="auto_hash_new_hdr_btm">
        <img src="../../../assets/images/auto_hash_tag.png" alt="">
        <div class="dtl_wrp_in">
          <h5>Unleash the power of A.I.</h5>
          <p>These suggested hashtags are generated to maximize your post! Make your selection now to increase your views and engagement!</p>
        </div>
      </div>
    </div>

    <!-- <div class="hastag_list_header" *ngIf="hashtagsFromText.length > 0">
      <span>Exposure Per Hour</span>
    </div> -->
    <div class="hastag_list">
        <ng-container *ngIf="isAutoHashtagLoading">
            <div class="msg_list_skeleton">
                <nz-skeleton *ngFor="let hashtag of staticConversationHash"
                             [nzAvatar]="false" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
        </div>
      </ng-container>

        <ng-container *ngIf="!isAutoHashtagLoading">
          <ng-container *ngIf="hashtagsFromText.length === 0">
          <div class="search_hastag" style="margin: 0;">
            <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
            <span>No <strong>#Hashtags</strong> Suggestions Found!</span>
          </div>
        </ng-container>

        <ng-container *ngFor="let autoHash of hashtagsFromText;let i=index">
          <label nz-checkbox [(ngModel)]="autoHash.is_checked" (ngModelChange)="updateAutoHashtag()">
            <div class="has_temp_input_in">
              <h5>#{{autoHash.hashtag}}</h5>
              <span class="btn-top-pick" *ngIf="i === 0">Top Pick</span>
              <!-- <div class="tag_count">
                <span>{{autoHash.exposure}} exposures per hour</span>
              </div> -->
              <!-- <div class="tag_progress">
                <nz-progress nzStrokeColor="#423fd1" [nzShowInfo]="false"
                  [nzPercent]="i==0?100:sharedService.getPercentage(autoHash.exposure_number,hashtagsFromText[0]?.['exposure_number'])"></nz-progress>
              </div> -->
            </div>
          </label>
        </ng-container>
        </ng-container>
    </div>
    <div class="has_temp_btn_group" *ngIf="hashtagsFromText.length > 0">
      <div class="filter_count_header">
        <strong>Selected ({{selectedAutoHashtags.length}})</strong>
          <ng-container *ngIf="selectedAutoHashtags.length==0">
          <a href="javascript:;" (click)="selectAllAutoHashtags()">Select All</a>
        </ng-container>
        <ng-container *ngIf="selectedAutoHashtags.length!=0">
          <a href="javascript:;" (click)="deselectAllAutoHashtags()">Deselect All</a>
        </ng-container>
      </div>
        <div class="btn_group">
            <button nz-button nzType="primary" class="btn btn_apply" (click)="addAutoHashtagToPost()"
                    [disabled]="selectedAutoHashtags.length==0">Add to Post
            </button>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<!-- Platform-Specific Tagging -->
<nz-drawer nzWrapClassName="auto_hash_tag_drawer platform_specific_drawer" [nzClosable]="false" [nzVisible]="isPlatFormSpecific" nzPlacement="right">
  <ng-container *nzDrawerContent>
    <div class="auto_hash_new_hdr">
      <div class="auto_hash_new_hdr_top">
        <h5>Platform-Specific Tagging</h5>
        <span class="tag_beta">Beta</span>
          <a href="javascript:;" (click)="closePlatFormSpecificDrawer()">
          <img src="../../../assets/images/close_icon.svg" alt="">
        </a>
      </div>
      <div class="auto_hash_new_hdr_btm">
        <img src="../../../assets/images/choose-user-icon.png" alt="">
        <div class="dtl_wrp_in">
          <h5>Choose users for each platform to tag them.</h5>
          <p>Tags are platform-specific and will only be included in posts for that platform.</p>
        </div>
      </div>
    </div>
    <div class="platform_body">
      <div class="platform_body_in">

        <!--Facebook-->
        <div class="platform_form_group" *ngIf="isSinglePlatformMode ? (currentProvider?.provider === sharedService.facebookProvider) : (allAccountProvider.includes(sharedService.facebookProvider))">
          <div class="label">
            <img src="../../../assets/images/providers/medium/FACEBOOK.png" alt="">
            <span>{{ this.sharedService.facebookProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Search and select Facebook Page</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false" nzPlaceHolder="@"
                       nzMode="multiple" [nzCustomTemplate]="multipleTemplate"
                       nzServerSearch (nzOnSearch)="facebookPageSearch($event)" [(ngModel)]="facebookSearchPagesList">
                <nz-option *ngIf="isFacebookPageSearchLoading" nzDisabled nzCustomContent>
                    <span nz-icon nzType="loading" class="loading-icon"></span>Searching...
                </nz-option>

                <ng-container *ngFor="let page of facebookPages">
                    <nz-option nzCustomContent *ngIf="!isLocationLoading" [nzLabel]="page.name" [nzValue]="page">
                        <div class="account_info">
                            <span class="selected_icon"></span>
                    <div class="account_img">
                        <img [src]="page.image" alt="">
                    </div>
                    <div class="account_content">
                      <span>{{ page.name }}
                        <img *ngIf="page.is_verified"
                             src="../../../assets/images/blue_verified.svg" alt="" style="width:15px" />
                      </span>
                    </div>
                  </div>
                </nz-option>
              </ng-container>
            </nz-select>

              <ng-template #multipleTemplate let-selected>
              <div class="ant-select-selection-item-content">
                <div class="selected_platform">
                  <div class="account_img">
                    <img [src]="selected.nzValue?.image" alt="">
                  </div>
                  <div class="account_content">
                    <span>{{ selected.nzValue?.name }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <!--Instagram-->
        <div class="platform_form_group" *ngIf="isSinglePlatformMode ? (currentProvider?.provider === sharedService.instagramProvider) : (allAccountProvider.includes(sharedService.instagramProvider))">
          <div class="label">
            <img src="../../../assets/images/providers/medium/INSTAGRAM.png" alt="">
            <span>{{ this.sharedService.instagramProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid Instagram username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="instagramSearchPagesList"
                       (ngModelChange)="validateInstagramSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Twitter-->
        <div class="platform_form_group" *ngIf="isSinglePlatformMode ? (currentProvider?.provider === sharedService.twitterProvider) : (allAccountProvider.includes(sharedService.twitterProvider))">
          <div class="label">
            <img src="../../../assets/images/providers/medium/TWITTER.png" alt="">
            <span>X(Twitter)</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid X(Twitter) username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="twitterSearchPagesList"
                       (ngModelChange)="validateTwitterSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Threads-->
        <div class="platform_form_group" *ngIf="isSinglePlatformMode ? (currentProvider?.provider === sharedService.threadsProvider) : (allAccountProvider.includes(sharedService.threadsProvider))">
          <div class="label">
            <img src="../../../assets/images/providers/medium/THREADS.png" alt="">
            <span>{{ this.sharedService.threadsProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid Threads usern`zame (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="threadsSearchPagesList"
                       (ngModelChange)="validateThreadsSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Tiktok-->
        <div class="platform_form_group" *ngIf="isSinglePlatformMode ? (currentProvider?.provider === sharedService.tiktokProvider) : (allAccountProvider.includes(sharedService.tiktokProvider))">
          <div class="label">
            <img src="../../../assets/images/tiktok.svg" alt="">
            <span>{{ this.sharedService.tiktokProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid TikTok username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="tiktokSearchPagesList"
                       (ngModelChange)="validateTiktokSearchInput($event)">
            </nz-select>
          </div>
        </div>


      </div>

      <div class="has_temp_btn_group">
        <div class="btn_group">
          <button type="button" class="btn btn_apply" (click)="addTags()">Add Tags</button>
        </div>
      </div>

    </div>
  </ng-container>
</nz-drawer>

<nz-modal nzClassName="create_temp_modal" [(nzVisible)]="isVisible" nzTitle="{{modalTitle}}" [nzFooter]="null"
  (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <div class="create_temp_form">
      <form nz-form [formGroup]="templateForm">
        <div>
          <label>Template Name</label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Template name is required">
              <input nz-input formControlName="template_name" placeholder="Enter Template Name" required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div>
          <label>Description</label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Template Description is required">
              <textarea class="temp_des" nz-input formControlName="template_description" placeholder="Start to Write"
                required></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </div>
    <div class="btn_group">
      <button nz-button nztype="primary" class="ant-btn ant-btn-primary" (click)="SaveTemplate()"
        [disabled]="isHashLoading">
        <span> {{buttonName}} </span>
        <ng-container *ngIf="isHashLoading ">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>

<nz-modal nzClassName="create_temp_modal" [(nzVisible)]="isexistingTemp" nzTitle="To existing template"
  [nzFooter]="null" (nzOnCancel)="existingTempCancel()">
  <ng-container *nzModalContent>
    <div class="create_temp_form">
      <label>Select template</label>
      <nz-select nzShowSearch [nzPlaceHolder]="'Select template'" [(ngModel)]="selectedValue"
        (ngModelChange)="updateTemplate($event)">

        <nz-option *ngFor="let item of allTemplate" [nzLabel]='item.name' [nzValue]='item.name'></nz-option>
        <!-- <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option> -->

      </nz-select>
    </div>
    <div class="btn_group">
      <button nz-button nztype="primary" class="ant-btn ant-btn-primary" [disabled]="isHashLoading"
        (click)="updateTemplateData()">
        <span> Save
          <ng-container *ngIf="isHashLoading ">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </span>
      </button>

    </div>
  </ng-container>
</nz-modal>

<app-payment-modal *ngIf="isPaymentModalVisible" [isVisible]="isPaymentModalVisible" [modalTitle]="'Buy Addon'"
  [plan]="selectedAddon" [submitText]="'Pay now'" (onCancel)="cancelPayment()"
  (onSubmit)="submitHandelForAddon($event)"></app-payment-modal>

<!--Post Modal-->
<nz-modal [(nzVisible)]="isPostingModal" [nzTitle]="titlePosts" nzClassName="posting_modal" [nzFooter]="null">
  <ng-template #titlePosts let-visible="visible">
    <ng-container *ngIf="validationErrors.length == 0">
      {{ isLoading === true ? 'Posting in Progress 🚀' : countKeys(platformPostStatus)===0
      ? "Oops, Something went wrong" : 'Posting in Progress 🚀'
      }}
    </ng-container>      
    <ng-container *ngIf="validationErrors.length > 0">
      Oops, Something went wrong
    </ng-container>
  </ng-template>

  <ng-container *nzModalContent>
    <div class="posting_warning">

      <img src="../../../assets/images/info_icon.svg" alt="">
      <!-- Top warning-->
      <ng-container *ngIf="validationErrors.length == 0">
        <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
          <!--post-->
          <ng-container *ngIf="loadingPostType==='post'">
            <p *ngIf="isLoading">Your Post is being shared to the selected platforms. Here is the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully shared across all platforms:</p>
          </ng-container>

          <!--schedule-->
          <ng-container *ngIf="loadingPostType==='schedule' || loadingPostType==='repeat'">
            <p *ngIf="isLoading">Your Post is being scheduling to the selected platforms. Here's the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully scheduled across all platforms:</p>
          </ng-container>

          <!--draft-->
          <ng-container *ngIf="loadingPostType==='draft'">
            <p *ngIf="isLoading">Your Post is being drafting to the selected platforms. Here's the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully drafted across all platforms:</p>
          </ng-container>

          <!--post-->
          <ng-container *ngIf="loadingPostType === 'queue'">
            <p *ngIf="isLoading">Your Post is being shared to the selected platforms. Here is the current status:</p>
            <p *ngIf="!isLoading">Your post is in the queue and will be shared shortly across all platforms:</p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
          Something went wrong
        </ng-container>
      </ng-container>
      <ng-container *ngIf="validationErrors.length > 0">
        <p>Unable to post, Please resolve the errors first</p>
      </ng-container>
    </div>


    <div class="posting_body">
      <div class="platform_progress">

        <ng-container *ngIf="validationErrors.length===0">
          <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
            <div *ngFor="let provider of allAccountProvider; let i = index" class="platform_progress_in"
              [class.post_completed]="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.success"
              [class.post_failed]="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.failed"
              [class.post_warning]="!isLoading && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.success
               && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.failed">
              <div class="icon_sec">
                <img *ngIf="provider === sharedService.facebookProvider" src="../../../assets/images/providers/medium/FACEBOOK.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.twitterProvider" src="../../../assets/images/providers/medium/TWITTER.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.instagramProvider" src="../../../assets/images/providers/medium/INSTAGRAM.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.youtubeProvider" src="../../../assets/images/providers/medium/YOUTUBE.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.pinterestProvider" src="../../../assets/images/providers/medium/PINTEREST.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.linkedinProvider" src="../../../assets/images/providers/medium/LINKEDIN.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.tiktokProvider" src="../../../assets/images/providers/medium/TIKTOK.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.threadsProvider" src="../../../assets/images/providers/medium/THREADS.png"
                  [alt]="provider">
              </div>
              <div class="detail_sec">
                <div class="title_sec">
                  <a class="btn_toggle" href="javascript:;" (click)="isToggle(i)"
                    [class.disabled_while_loading]="isLoading" [class.selected]="isSelectedAccountToggle === i">
                    <!-- <span nz-icon nzType="down" nzTheme="outline"></span> -->
                    <img src="../../../assets/images/down_arrow.svg" alt="">
                  </a>
                  <h5>{{ sharedService.platformName(provider) | titlecase }}</h5>

                  <div class="post_status_main">

                    <ng-container
                      *ngIf="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.success">
                      <span>Completed</span>
                      <img src="../../../assets/images/status_complete.svg" alt="">
                    </ng-container>

                    <ng-container
                      *ngIf="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.failed">
                      <span>Fail</span>
                      <img src="../../../assets/images/status_fail.svg" alt="">
                    </ng-container>

                    <ng-container *ngIf="!isLoading && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.success
                    && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.failed">
                      <span>Warning</span>
                      <img src="../../../assets/images/status_warning.svg" alt="">
                    </ng-container>

                  </div>
                </div>
                <div class="platform_progressbar">
                  <div class="platform_progressbar_in">
                    <span></span>
                  </div>
                </div>
                <ng-container *ngIf="isSelectedAccountToggle === i">
                  <div class="selected_account_list" *ngFor="let account of this.selectedAccounts">
                    <div class="selected_account_list_in" *ngIf="account.provider === provider">
                      <div class="left_sec">
                        <app-image-preview [imageUrl]="account.image" [class]="'preview-post-img'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="right_sec">
                        <div class="right_upper">
                          <div class="detl_sec">
                            <span>{{account.name}}</span>
                          </div>
                          <div class="platform_status">
                            <span>{{ postAccountStatus[account.id]?.status === true ? 'Completed' : 'Failed' }}</span>
                            <img
                              src=" {{ postAccountStatus[account.id]?.status ===true ? '../../../assets/images/status_complete.svg' : '../../../assets/images/status_fail.svg' }}"
                              alt="">
                          </div>
                        </div>
                        <div class="subplatform_progress">
                          <div
                            class="subplatform_progress_in {{ postAccountStatus[account.id]?.status ===true ? 'status_completed' : 'status_fail' }} ">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
            <div class="gen_error">
              <img src="../../../assets/images/complited_bulk_post.png" />
              <span>{{ postResponseError }}</span>
            </div>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="validationErrors.length!==0">
          <ng-container *ngFor="let provider of allAccountProvider; let i = index">
            <div class="platform_progress_in" *ngIf="platformPostStatus.hasOwnProperty(provider)"
              [class.post_warning]="!isLoading && platformPostStatus[provider]?.failed">
              <div class="icon_sec">
                <img *ngIf="provider === sharedService.facebookProvider" src="../../../assets/images/providers/medium/FACEBOOK.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.twitterProvider" src="../../../assets/images/providers/medium/TWITTER.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.instagramProvider" src="../../../assets/images/providers/medium/INSTAGRAM.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.youtubeProvider" src="../../../assets/images/providers/medium/YOUTUBE.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.pinterestProvider" src="../../../assets/images/providers/medium/PINTEREST.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.linkedinProvider" src="../../../assets/images/providers/medium/LINKEDIN.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.tiktokProvider" src="../../../assets/images/providers/medium/TIKTOK.png"
                  [alt]="provider">
              </div>
              <div class="detail_sec">
                <div class="title_sec">
                  <a class="btn_toggle" href="javascript:;" (click)="isToggle(i)"
                    [class.disabled_while_loading]="isLoading" [class.selected]="isSelectedAccountToggle === i">
                    <!-- <span nz-icon nzType="down" nzTheme="outline"></span> -->
                    <img src="../../../assets/images/down_arrow.svg" alt="">
                  </a>
                  <h5>{{ sharedService.platformName(provider) | titlecase }}</h5>

                  <div class="post_status_main">
                    <ng-container *ngIf="!isLoading && platformPostStatus[provider]?.failed">
                      <span>Validation Error</span>
                      <img src="../../../assets/images/status_warning.svg" alt="">
                    </ng-container>

                  </div>
                </div>
                <div class="platform_progressbar">
                  <div class="platform_progressbar_in">
                    <span></span>
                  </div>
                </div>
                <ng-container *ngIf="isSelectedAccountToggle === i">
                  <div class="selected_account_list" *ngFor="let account of this.selectedAccounts">
                    <div class="selected_account_list_in" *ngIf="account.provider === provider">
                      <div class="left_sec">
                        <app-image-preview [imageUrl]="account.image" [class]="'preview-post-img'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="right_sec">
                        <div class="right_upper">
                          <div class="detl_sec">
                            <span>{{account.name}}</span>
                          </div>
                          <div class="platform_status">
                            <span>{{ postAccountStatus[account.id]?.status === true ? '' : 'Validation Error' }}</span>
                            <img src="../../../assets/images/status_warning.svg" alt="">
                          </div>
                        </div>
                        <div class="subplatform_progress">
                          <div class="subplatform_progress_in status_warning "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </div>
    </div>


    <div class="posting_footer">
      <!-- Footer warning-->

      <ng-container *ngIf="validationErrors.length == 0">
        <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
          <!--post-->
          <ng-container *ngIf="loadingPostType==='post'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully shared!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now live on all selected platforms.
            </p>
          </ng-container>

          <!--schedule-->
          <ng-container *ngIf="loadingPostType==='schedule' || loadingPostType==='repeat'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully scheduled!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now scheduled on all selected
              platforms.</p>
          </ng-container>

          <!--draft-->
          <ng-container *ngIf="loadingPostType==='draft'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully drafted!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now drafted on all selected platforms.
            </p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
          <p>You need to fix the issue before process</p>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="validationErrors.length > 0">
        <p>Validation failed please resolve and try again</p>
      </ng-container>




      <!-- Footer buttons-->
      <div class="btn_sec btn_posting_wrapper">
        <ng-container *ngIf="validationErrors.length===0">

          <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
            <button class="btn_primary" *ngIf="postFailed.length>0" (click)="tryAgainPost()">
              Try Again
            </button>

            <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
              Close Window
            </button>

            <button class="btn_primary" [disabled]="isLoading" (click)="viewPost()" *ngIf="postFailed.length==0">
              View Post
            </button>
          </ng-container>

          <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
            <button class="btn_primary" (click)="tryAgainPost()">
              Try Again
            </button>
            <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
              Close Window
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="validationErrors.length>0">
          <button class="btn_primary" (click)="resolveError()">
            Resolve Error
          </button>
          <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
            Close Window
          </button>
        </ng-container>

      </div>

    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isShowLocation" nzClassName="user_location_modal" nzMaskClosable="false" nzTitle="Add Location" (nzOnCancel)="hideLocationModal()" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="title_area">
      <p>Posts with a tagged location result in 79% higher engagement than posts without a tagged location</p>
    </div>
    <div class="search_location_area">
      <!-- <div class="user_info">
        <app-image-preview >
         <img src="https://basal-social-dev.s3.us-east-2.amazonaws.com/inbox/user-33/1696589189.589298.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATYI4QHWZVC4ECPRH%2F20240709%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240709T101555Z&X-Amz-Expires=86400&X-Amz-Signature=16329a60f10145d7b904528697f72bee36a8ededab6dca6003d08fee5a6f5e40&X-Amz-SignedHeaders=host" alt="">
        </app-image-preview>
        <app-provider>
          <img src="../../../assets/images/Facebook.png" alt="">
        </app-provider>
      </div> -->
      <div class="location_input">
        <nz-select nzShowSearch nzAllowClear nzServerSearch nzPlaceHolder="Search for location"
                   nzDropdownClassName="team_social_networks"
                   [(ngModel)]="selectedLocation" (nzOnSearch)="facebookLocationSearch($event)">

          <nz-option *ngIf="isLocationLoading" nzDisabled nzCustomContent>
            <span nz-icon nzType="loading" class="loading-icon"></span>
            Searching...
          </nz-option>

          <ng-container *ngFor="let location of postLocations">
            <nz-option  *ngIf="!isLocationLoading" nzCustomContent [nzLabel]="location.name" [nzValue]="location">
              <h6>{{ location.name }}
                <img *ngIf="location.is_verified"
                     src="../../../assets/images/blue_verified.svg"
                     alt="" style="width:15px" />
              </h6>
              <span>{{ location.address }}</span>
            </nz-option>
          </ng-container>

        </nz-select>
      </div>
    </div>
    <div class="btn_location">
      <button type="button" class="btn_cancel" (click)="hideLocationModal()">Cancel</button>
      <button type="button" class="btn_primary" (click)="selectLocation()">Submit</button>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isImageGenerateModal" nzClassName="ai_image_prompt" nzMaskClosable="false" (nzOnCancel)="closeaiImagePrompt()" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="ai_img_prompt">
      <div class="ai_img_prompt_left">
        <div class="title_sec">
            <h2>
              AI Image Generator
              <span class="beta-tag">Beta</span>
            </h2>
            <div class="po">
               Powered By: <img src="../../../assets/images/amazon-bedrock.svg" alt=""><strong> AWS Bedrock</strong>
            </div>
          <!--<div class="generation_count">
            <div>
              <strong>96 / 100 generations</strong>
              <span>
                Auto-refill:
                <a href="javascript:;">
                  On
                  <img src="../../../assets/images/question-mark-circle-outline.svg" alt="">
                </a>
              </span>
            </div>
          </div>-->
        </div>

        <div class="ai_prompt_form">
          <label>Prompt</label>
          <div class="input_area" [class.disabled]="isImageGenerating || isImageRegenerating">
            <textarea placeholder="Enter your prompt here…" [(ngModel)]="textToImage"
                       maxlength="500" [disabled]="isImageGenerating || isImageRegenerating"></textarea>
            <span>{{textToImage.length}}/500</span>
          </div>
          <div class="action_area">
            <button class="btn_primary" (click)="generateImages()"
                    [disabled]="textToImage.length==0 || (isImageGenerating || isImageRegenerating)">
              <img src="../../../assets/images/generate-icon.svg" alt="">
              <span>Generate</span>
            </button>
          </div>
        </div>

        <div class="ai_style_selection">
          <div class="title_sec">
            <h5>Style Selector</h5>
          </div>

          <nz-radio-group [(ngModel)]="styleSelection">
            <label nz-radio *ngFor="let style of styles" [nzValue]="style.value" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_style_radio">
                <div class="img_sec">
                  <img [src]="'../../../assets/images/style-selector/'+style.imageUrl" alt="style">
                </div>
                <span>{{ style.name }}</span>
              </div>
            </label>
          </nz-radio-group>
        </div>

        <div class="ai_style_ratio">
          <div class="title_sec">
            <h5>Aspect Ratio</h5>
          </div>
          <nz-radio-group [(ngModel)]="styleAspectRatio">
            <label nz-radio nzValue="1:1" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_1_1"></span>
                <span>1:1</span>
              </div>
            </label>
            <label nz-radio nzValue="16:9" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_16_9"></span>
                <span>16:9</span>
              </div>
            </label>
            <label nz-radio nzValue="4:5" [nzDisabled]="isImageGenerating || isImageRegenerating" *ngIf="!allAccountProvider.includes(sharedService.instagramProvider)">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_9_16"></span>
                <span>4:5</span>
              </div>
            </label>
          </nz-radio-group>
        </div>

      </div>

      <div class="ai_img_prompt_right">
        <!-- Title -->
        <div class="title_message">
          <div class="img_sec">
            <img src="../../../assets/images/describe_ai_image.png" alt="">
          </div>
          <div class="des_sec">
            <strong>Describe Your Image</strong>
            <p>Enter a prompt to start creating stunning visuals. Be as detailed as you like!</p>
          </div>
        </div>

        <!-- Results -->
        <div class="default_result" *ngIf="generatedImages.length === 0 && !isImageGenerating">
          <div class="default_result_hdr">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Results</span>
            </h5>
          </div>
          <div class="default_result_body">
            <img src="../../../assets/images/ai-image.svg" alt="">
          </div>
        </div>

        <!-- Generating -->
        <div class="default_result" *ngIf="isImageGenerating">
          <div class="default_result_hdr">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Generating...</span>
            </h5>
            <!--<span>33/100 steps | 6.1/14.2s</span>-->
          </div>
          <div class="default_result_body">
            <div class="generating_message">
              <div class="message_wrapper">
                <img src="../../../assets/images/painting-default.svg" alt="">
                <h5>Creating Your Masterpiece</h5>
                <p>This will only take a moment...</p>
                <!-- <strong>33.0%</strong> -->
              </div>
              <div class="message_progress">
                <div class="progress-wrapper">
                  <span class="progress-bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Select an Image -->
        <div class="ai_img_selection" *ngIf="generatedImages.length > 0 && !isImageGenerating">
          <div class="title_sec">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Select an Image</span>
            </h5>
          </div>

          <div class="ai_img_check">
            <nz-checkbox-wrapper (nzOnChange)="generatedImagesEvent($event)">
              <label nz-checkbox *ngFor="let img of generatedImages" [nzValue]="img" [nzDisabled]="isImageRegenerating">
                <div class="img_sec">
                  <a href="javascript:;" class="img_preview_icon" (click)="viewGeneratedImage(img)">
                    <img src="../../../assets/images/info_icon_filled.svg" alt="">
                  </a>
                  <ng-container *ngIf="isImageRegenerating">
                    <ng-container *ngIf="selectedGeneratedImage != img">
                      <div class="ai_img_skeleton">
                        <nz-skeleton [nzActive]="true"></nz-skeleton>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedGeneratedImage == img">
                      <img src="data:image/png;base64,{{ img }}" alt="">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!isImageRegenerating">
                    <img src="data:image/png;base64,{{ img }}" alt="">
                  </ng-container>

                </div>
              </label>
            </nz-checkbox-wrapper>
            <div class="po">
              Powered By: <img src="../../../assets/images/amazon-bedrock.svg" alt="">
              <strong>
              AWS Bedrock</strong>
            </div>
          </div>

          <div class="ai_img_input" *ngIf="needRegenerate">
            <div class="input_area">
              <label>Refined AI Prompt</label>
              <textarea placeholder="Enter your prompt here…" [(ngModel)]="refineTextToImage"
                        [class.disabled_while_loading]="isImageRegenerating" maxlength="500"></textarea>
            </div>
            <span>{{refineTextToImage.length}}/500</span>
            <div class="ai_img_input_action">
              <button class="btn_primary" (click)="generateVariantImage()" [class.disabled_while_loading]="selectedGeneratedImage.length !== 1 || refineTextToImage === '' || isImageRegenerating">
                <img src="../../../assets/images/regenerate.svg" alt="">
                Refine Image
              </button>
            </div>
          </div>


          <div class="ai_img_action">
            <ng-container *ngIf="!needRegenerate">
              <button type="button" (click)="needRegenerate = !needRegenerate" [class.disabled_while_loading]="selectedGeneratedImage.length!==1 || isImageRegenerating"
                [class.cancelRegeneration]="needRegenerate">
                <div class="icon_sec">
                  <img src="../../../assets/images/regenerate.svg" alt="">
                </div>
                <span>Regenerate</span>
              </button>
            </ng-container>
            <ng-container *ngIf="needRegenerate">
              <button type="button" (click)="needRegenerate = !needRegenerate" [class.disabled_while_loading]="isImageRegenerating"
              [class.cancelRegeneration]="needRegenerate">
                <div class="icon_sec">
                  <img src="../../../assets/images/status_fail.svg" alt="">
                </div>
                <span>Cancel</span>
              </button>
            </ng-container>
            <button type="button" (click)="uploadGeneratedImages()"  [class.disabled_while_loading]="selectedGeneratedImage.length === 0 || isImageRegenerating" [disabled]="selectedGeneratedImage.length===0 || isImageRegenerating">
              <div class="icon_sec">
                <img src="../../../assets/images/share.svg" alt="">
              </div>
              <span>Share</span>
            </button>
            <button type="button" (click)="downloadGeneratedImages()" [class.disabled_while_loading]="selectedGeneratedImage.length === 0 || isImageRegenerating" [disabled]="selectedGeneratedImage.length===0 || isImageRegenerating">
              <div class="icon_sec">
                <img src="../../../assets/images/save-ai-image.svg" alt="">
              </div>
              <span>Save</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </ng-container>
</nz-modal>

<!-- Hashtag Generate Start -->
<nz-modal nzClassName="hashTagDetectionModal" [(nzVisible)]="isShowHashTagGenerate" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="header_main">
      <h4>
        <img src="../../../assets/images/hashtagDetection.svg" alt="">
        <span>Hashtag Detection</span>
        <span class="beta-tag">Beta</span>
      </h4>
      <a href="javascript:;" (click)="hasTagGenerationCloseModal()">
        <img src="../../../assets/images/close-darkblue.svg" alt="">
      </a>
    </div>

    <div class="detection_warning">
      <div class="img_sec">
        <img src="../../../assets/images/describe_ai_image.png" alt="">
      </div>
      <div class="dtl_sec">
        <h5>Easily generate relevant hashtags from your images</h5>
        <p>Our AI-powered tool automatically detects and suggests tags based on your image to enhance your post’s visibility and engagement across all platforms.</p>
      </div>
    </div>

  <div class="img_detection_area">
    <div class="img_sec">
      <app-image-preview [isVideo]="isVideoHashTag" [imageUrl]="hashTagImg" ></app-image-preview>
    </div>
    <div class="tags_main">
      <ng-container *ngIf="!isHashTagLoading">
        <div class="tags_in">
          <div class="result_title">
            <h5>Results</h5>
            <div class="po">
               Powered By: <img src="../../../assets/images/aws_rekognition.png" alt="">
               <strong>AWS Rekognition</strong>
            </div>
          </div>
          <div class="tags_selection">
            <perfect-scrollbar style="max-height: 490px;">
              <nz-checkbox-wrapper (nzOnChange)="getHashTags($event)">
                <ul>
                  <li *ngFor="let hashtag of hashtags">
                    <label nz-checkbox nzValue="#{{ hashtag.Name }}">
                      <div class="tags_label">
                        <div class="label_sec">
                          <h4>#{{ hashtag.Name }}</h4>
                        </div>
                        <div class="percentage">
                          <span>{{ hashtag.Confidence | number:'1.2-2' }}%</span>
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </nz-checkbox-wrapper>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="btn_sec">
          <button type="button" class="btn_primary" [class.disabled_while_loading]="selectedHashtags.length == 0" (click)="addHashtagsToPost()">Add Hashtags to Post</button>
        </div>
      </ng-container>
      <ng-container *ngIf="isHashTagLoading">
        <div class="hash_tag_skeleton" *ngFor="let item of dummyItems">
          <nz-skeleton [nzAvatar]="false" [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
          <nz-skeleton [nzAvatar]="false" [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
        </div>
      </ng-container>
    </div>
  </div>
  </ng-container>
</nz-modal>
<!-- Hashtag Generate End -->

<!-- Preview of AI Generated image -->
<nz-modal nzClassName="preGeneratedImage" [(nzVisible)]="isPreviewGeneratedImageModal" [nzFooter]=null >
  <ng-container *nzModalContent>
    <a href="javascript:;" class="closeModal" (click)="isPreviewGeneratedImageModal=false">
      <img src="../../../assets/images/modal_close.svg" alt="">
    </a>
    <div>
      <img src="data:image/png;base64,{{ previewGeneratedImage }}" alt="">
    </div>
  </ng-container>
</nz-modal>
<!-- Preview of AI Generated image -->

<!-- Live post modal -->

<nz-modal nzClassName="livePostModal" [(nzVisible)]="isLivePost" [nzFooter]=null >
  <ng-container *nzModalContent>
    <div class="content_wrap">
      <img src="../../../assets/images/success-your-posts-are-live.png" alt="">
      <h3>🎉 Success, Your Posts Are 
        <ng-container *ngIf="loadingPostType === 'schedule'; else livePost">Scheduled!</ng-container>
        <ng-template #livePost>in Queue and Will Be Live Soon!</ng-template>
      </h3>
      <p>You’ve just taken a big step in building your online presence. Keep up the momentum and watch your audience grow!</p>
      <button class="btn_primary" (click)="closeWindow()">Create Another Post</button>
    </div>
  </ng-container>
</nz-modal>



<nz-modal nzClassName="shorten-modal" [(nzVisible)]="isShortenModal" nzTitle="Link Shortener" (nzOnCancel)="isShortenModal = false">
  <ng-container *nzModalContent>
    <a href="javascript:;" class="close-modal" (click)="isShortenModal = false">
      <img src="../../../assets/images/close_icon.svg" alt="">
    </a>
    <div class="shorten-url-wrapper">
      <perfect-scrollbar style="max-height: 400px">
        <div class="shorten-url-list">
          <div class="shorten-url-list-in" *ngFor="let url of matches">
            <label nz-checkbox [nzValue]="url.url" [(ngModel)]="url.selected" (ngModelChange)="updateSelectedUrls()">
              <div class="url-wrapper">
                <div class="url-title">
                  <img src="../../../assets/images/icon-shorten.svg" alt="">
                  <span>{{ url.url }}</span>
                </div>
                <div class="url-label">
                  <span class="unselect">Select</span>
                  <span class="select">Selected</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="shortener_action">
      <span>Are you sure you want to Shorten this URL?</span>
      <div class="btn_group">
        <button type="button" class="btn" (click)="isShortenModal = false"> Cancel </button>
        <button type="button" class="btn btn_apply" (click)="shortenUrls()" [class.disabled_while_loading]="selectedUrls.length === 0"> Shorten URL </button>
      </div>
    </div>
  </ng-container>
</nz-modal>