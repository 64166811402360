<ng-container *ngIf="isDisplayChart">
  <div class="overview_chart">

    <div class="top_chart">
      <apx-chart [series]="[previousCountPercentage]" [chart]="radialBarChart1.chart"
                 [plotOptions]="radialBarChart1.plotOptions" [labels]="radialBarChart1.labels"
                 [fill]="radialBarChart1.fill">
      </apx-chart>
    </div>
    <div class="bottom_chart">
      <apx-chart [series]="[currentCountPercentage]" [chart]="radialBarChart2.chart"
                 [plotOptions]="radialBarChart2.plotOptions" [labels]="radialBarChart2.labels" [fill]="radialBarChart2.fill">
      </apx-chart>
    </div>

  </div>
  <div class="overview_chart_dtl">

    <!--Icon-->
    <img src="../../../assets/images/icon-circle-user.svg" alt="">

    <!--Counts-->
    <h3>{{ previousCount | transformNumber }}</h3>
    <div class="current-count">
      <!--{{ sharedService.getPlusMinus(currentCount,previousCount) }}-->
      <span>
        {{ currentCount | transformNumber }}
      </span>
    </div>

    <!--Count Type Name-->
    <p>
      <span>
        {{ type }}
        <i *ngIf="tooltipTitle" nz-icon nzType="info-circle" nzTheme="fill"
           nzTooltipOverlayClassName="information" nz-tooltip
           nzTooltipPlacement="right" nzTooltipTrigger="hover"
           [nzTooltipTitle]="tooltipTitle"></i>
      </span>
    </p>
  </div>
</ng-container>
