<div class="seting_card">
  <div class="seting_card_header">
    <h3>Your Profile</h3>
  </div>
  <div class="edit_profile_main">
    <form nz-form [formGroup]="editProfileForm" (ngSubmit)="submitForm()">
      <div class="profile_form">
        <div class="profile_img_main">
          <div class="upload_img" *ngIf="!avatarUrl">
            <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false"
              [nzDisabled]="loading || isLoading" [nzBeforeUpload]="beforeUpload">

              <ng-container>
                <img src="../../../assets/images/Profile.png" alt="">
              </ng-container>

            </nz-upload>
          </div>
          <div *ngIf="avatarUrl" class="image-wrap">
            <div class="img">
              <app-image-preview [imageUrl]="avatarUrl" [class]="'edit-profile-image'" [isVideo]="false">
              </app-image-preview>
            </div>
            <div class="icon">
              <i nz-icon nzType="delete" nzTheme="outline" class="delete-icon" (click)="deleteImage()"
              [class.disabled_while_loading]="isLoading"></i>
            </div>
          </div>
          <div class="icon_sec">
            <img src="../../../assets/images/icon-camera.svg" alt="icon-camera">
          </div>
        </div>
        <div class="profile_form_in">
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.name?.length>0">
              <nz-form-control [nzErrorTip]="nameError">
                <input nz-input formControlName="name" id="name" [class.disabled_while_loading]="isLoading" />
                <ng-template #nameError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Name Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Name must include character only and maximum 30 character.
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Full Name</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="user.email.length>0">
              <nz-form-control [nzErrorTip]="emailError">
                <input nz-input formControlName="email" id="email" />
                <ng-template #emailError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Email Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('email')">
                    Please Enter Valid Email!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label nzFor="email">Email</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.phone?.length>0">
              <nz-form-control [nzErrorTip]="phoneError">
                <input nz-input formControlName="phone" id="phone" [class.disabled_while_loading]="isLoading" />
                <ng-template #phoneError let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Please enter valid phone number
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Phone Number</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.dob!==null">
              <nz-form-control [nzErrorTip]="dobError">
                <nz-date-picker formControlName="dob" nzFormat="MM/dd/yyyy" [nzPlaceHolder]="' '"
                  [nzDisabledDate]="disabledDate" [nzDefaultPickerValue]="displayStartDate" id="dob" [class.disabled_while_loading]="isLoading">
                </nz-date-picker>
                <ng-template #dobError let-control>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">
                      {{serverError }}
                    </div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Date Of Birth</nz-form-label>
          </nz-form-item>

          <!-- <nz-date-picker nzFormat="MM/dd/yyyy"></nz-date-picker> -->

          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.gender?.length>0">
              <nz-select formControlName="gender" nzPlaceHolder="Select Gender"
              [nzDisabled]="isLoading">
                <nz-option nzValue="Male" nzLabel="Male"></nz-option>
                <nz-option nzValue="Female" nzLabel="Female"></nz-option>
                <nz-option nzValue="Transgender" nzLabel="Transgender"></nz-option>
                <nz-option nzValue="Prefer not to answer" nzLabel="Prefer not to answer"></nz-option>
              </nz-select>
            </nz-input-group>
            <nz-form-label>Gender</nz-form-label>
          </nz-form-item>
        </div>
        <div class="action_area ">
          <button type="submit" class="btn-primary" nz-button nzType="primary"
            [disabled]="loading || editProfileForm.invalid || isLoading">
            Update Profile
            <ng-container *ngIf="isLoading">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
