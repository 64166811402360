<ng-container *ngIf="isForUpdate && !selectedImage && !selectedImageURL && !isDirectUpload && !isCropped">
  <div class="gallery_title">
    <div class="selected_media_wrapper" *ngIf="selectedMedias.length>0 && page!=='create_post' ">
      <div class="selected_media_left">
        <span>{{selectedMedias.length}} Media selected</span>
      </div>
      <div class="selected_media_right">
        <ng-container *ngIf="currentTab===0">
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to favorite" (click)="addToFavorite()">
              <img src="../../../assets/images/icon-favorite.svg" alt="icon-favorite">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to album" (click)="addToAlbumVisible=true">
              <img src="../../../assets/images/icon-album.svg" alt="icon-album">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <img src="../../../assets/images/icon-trash-white.svg" alt="icon-trash">
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>

        </ng-container>
        <ng-container *ngIf="currentTab===1">
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to favorite" (click)="addToFavorite()">
              <img src="../../../assets/images/icon-favorite.svg" alt="icon-favorite">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Remove from album" (click)="removeMediaFromAlbum=true">
              <img src="../../../assets/images/icon-album.svg" alt="icon-album">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <img src="../../../assets/images/icon-trash-white.svg" alt="icon-trash">
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>
        </ng-container>
        <ng-container *ngIf="currentTab===2">
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Remove from favorite" (click)="addToFavorite(null,null,true)">
              <img src="../../../assets/images/icon-favorite.svg" alt="icon-favorite">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to album" (click)="addToAlbumVisible=true">
              <img src="../../../assets/images/icon-album.svg" alt="icon-album">
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <img src="../../../assets/images/icon-trash-white.svg" alt="icon-trash">
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>
        </ng-container>
      </div>
    </div>


    <div class="gallery_title_action">
      <a href="javascript:;" (click)="sortModal()"  aria-label="Sort by">
        <img src="../../../assets/images/icon-sort.svg" alt="">
      </a>
      <ng-container *ngIf="removeFileTypeFile">
        <a href="javascript:;" (click)="FilterByModal()" aria-label="Filter by">
          <img src="../../../assets/images/icon-filter.svg" alt="">
        </a>
      </ng-container>
    </div>
  </div>

  <!-- Start demo -->
  <!-- <div>
    <div class="demo_grid">
      <ng-container *ngFor="let media of galleryFiles">
        <ng-container *ngFor="let mediadata of media; let i = index">
          <ng-container *ngFor="let galleryMedia of mediadata[1]; let j = index">
            <div class="demo_grid_iner">
              <app-gallery-image-preview [isVideo]="galleryMedia['file_type'] ==='video' "
              [imageUrl]="galleryMedia.media_file" [class]="'manage-post-video'">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
   </div>
  </div> -->
  <!-- End demo -->

  <div class="gallery_body upload-media-wrapper">
    <button nz-button nztype="primary" nzshape="round" class="btn_upload_media"
            (click)="sharedService.isMobile && currentTab == 1 ? openCreateAlbum() : showUploadModal()"
      *ngIf="page!=='create_post' ">
      <!-- {{permissions.gallery_upload}} -->
      <img src="../../../assets/images/save-ai-image.svg" alt="">
      <span>Upload Media</span>
    </button>

    <div [class]="page === 'create_post'? 'media-list-wrap' : 'gallery_wrapper' ">
      <nz-tabset class="gallery_tab" [nzSelectedIndex]="currentTab" (nzSelectedIndexChange)="tabChanged($event)">

        <!--Media-->
        <nz-tab nzTitle="Media" (nzClick)="openGallery()">

          <!--if condition used for improve speed -->
          <ng-container *ngIf="currentTab===0">

            <!-- skeleton -->
            <div class="empty_gallery no-data" *ngIf="galleryLoading">
                <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                  <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                </div>
            </div>


            <ng-container *ngIf="!galleryLoading">
                <!-- no data -->
                <ng-container *ngIf="galleryFiles.length === 0">
                  <span style="align-items: center;">
                    <app-empty [message]="'No Media Found !'"></app-empty>
                  </span>
                </ng-container>

                <!-- gallery media -->
                <div *ngIf="galleryFiles.length > 0" [class]="scrollClass" nz-col infiniteScroll [infiniteScrollDistance]="2"
                     [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                  <div class="gallery_main" *ngFor="let media of galleryFiles">
                    <div class="media_grid_in">
                      <ng-container *ngFor="let mediadata of media; let i = index">
                        <div class="title_sec">
                          <ng-container *ngIf="galleryDate === mediadata[0];else yesterDay">
                            <h5> Today </h5>
                          </ng-container>
                          <ng-template #yesterDay>
                            <ng-container *ngIf="yesterDayDate === mediadata[0]; else date">
                              <h5> Yesterday </h5>
                            </ng-container>
                          </ng-template>
                          <ng-template #date>
                            <h5>{{ mediadata[0] | date }}</h5>
                          </ng-template>
                        </div>

                        <div class="gallery_grid">

                          <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                                 *ngFor="let galleryMedia of mediadata[1]; let j = index"
                                 [(ngModel)]="galleryMedia.is_checked"
                                 (ngModelChange)="selectMedias($event,galleryMedia,mediadata[1])"
                                 [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia)
                                 && selectedGalleryFiles.length+postMedias.length>0 && selectedGalleryFiles.length+postMedias.length >= 10">
                            <div class="gallery_grid_in" [class]="(this.selectedGalleryFile === galleryMedia && page==='create_post') ? 'post-content-item selected' : 'post-content-item' ">
                              <ng-container *ngIf="page !== 'create_post'">
                                <div class="hover_option">
                                  <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                                    <img src="../../../assets/images/icon-option.svg" alt="">
                                  </div>

                                  <ul>
                                    <li (click)="selectImageFromGallery(galleryMedia,mediadata[0],i,j,mediadata[1].length,$event)">
                                      <a href="javascript:;">View</a>
                                    </li>
                                    <li (click)="addToFavorite(galleryMedia,$event)">
                                      <a href="javascript:;">{{ galleryMedia.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                                    </li>
                                    <li (click)="addToAlbum(galleryMedia?._id,$event)"><a href="javascript:;">Add to album</a></li>
                                    <li (click)="deleteMedia(galleryMedia?._id,$event)"><a href="javascript:;">Delete</a></li>
                                  </ul>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="page === 'create_post'">
                                <div class="media_info">
                                  <a href="javascript:;" (click)="selectImageFromGallery(galleryMedia,mediadata[0],i,j,mediadata[1].length,$event)">
                                    <img src="../../../assets/images/info_icon_filled.svg" alt="">
                                  </a>
                                </div>
                              </ng-container>

                              <!--[imageUrl]="galleryMedia.media_file"-->
                              <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                              <app-gallery-image-preview
                                [isVideo]="galleryMedia['file_type'] ==='video' " [class]="'manage-post-video'"
                                [thumbUrl]="galleryMedia.media_file_thumb">
                              </app-gallery-image-preview>

                              <!-- <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                                *ngIf=" galleryMedia['file_type'] ==='video'"></i> -->
                              <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon" *ngIf=" galleryMedia['file_type'] ==='video'"></i>
                              <!-- <span nz-icon nzType="play-circle" nzTheme="outline" class="media_type_icon" *ngIf=" galleryMedia['file_type'] ==='video'"></span> -->
                            </div>
                          </label>

                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="text-center">
                    <ng-container *ngIf="noGalleryData">
                    <span style="align-items: center;">
                      <app-empty [message]="'No Media Found !'"></app-empty>
                    </span>
                    </ng-container>
                    <ng-template [ngIf]="scrollLoading">
                      <div class="empty_gallery no-data">
                        <ng-template [ngIf]="scrollLoading">
                          <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                            <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                          </div>
                        </ng-template>
                      </div>
                    </ng-template>
                  </div>
                </div>
            </ng-container>

          </ng-container>

        </nz-tab>

        <!--Albums-->
        <nz-tab nzTitle="Albums" (nzClick)="getAlbumList()">
          <!--if condition used for improve speed -->
          <div class="gallery_albums albums_main" *ngIf="currentTab===1">
            <div class="title_sec" *ngIf="!albumData && page !== 'create_post'">
              <h3>Albums</h3>
            </div>
            <div *ngIf="(albumList.length === 0 && page === 'create_post')" class="empty_gallery no-data">
              <ng-container *ngIf="!galleryLoading; else skeleton">
                <span style="align-items: center;">
                  <app-empty [message]="'No Album Found !'"></app-empty>
                </span>
              </ng-container>
              <ng-template #skeleton>
                <ng-container >
                  <!-- *ngIf="galleryLoading" -->
                  <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                  </div>
                </ng-container>
              </ng-template>
            </div>

            <div class="gallery_albums_main" *ngIf="!albumData">
              <div class="create_gallery_albums" *ngIf="page !=='create_post' && !sharedService.isMobile">
                <div class="create_gallery_albums_in">
                  <img src="../../../assets/images/create_gallery_album_img.png" alt="">
                  <button nz-button class="btn_album" (click)="openCreateAlbum()">
                    <img src="../../../assets/images/icon-plus-blue.svg" alt="">
                    Create New Album
                  </button>
                </div>
              </div>

              <!--[class.empty_album]="albumData.album_media_count===0"-->
              <div class="gallery_albums_in" *ngFor="let albumData of albumList">
                <div class="img_sec">
                  <div (click)="allImageFromAlbum(albumData._id,albumData.album_name)">
                    <ng-container *ngIf="albumData.album_media_count?.length !== 0">

                      <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview [isVideo]="albumData.album_preview?.file_type === 'video'" class="manage-post-video"
                        [thumbUrl]="albumData.album_preview?.media_file_thumb"
                      ></app-gallery-image-preview>

                    </ng-container>
                    <ng-container *ngIf="albumData.album_media_count === 0">
                      <img src="../../../assets/images/placeholder_image.png" class="default_album_img" alt="">
                    </ng-container>
                  </div>
                </div>
                <div class="content_sec">
                  <div class="content_sec_left">
                    <h4 [title]="albumData.album_name">{{albumData.album_name}}</h4>
                    <span>{{albumData.album_media_count}} Items</span>
                  </div>
                  <div class="content_sec_right" *ngIf="page !=='create_post'">
                    <div class="user_header_action">
                      <div class="user_header_ac_main">
                        <img src="../../../assets/images/header-dotes.svg" alt="">
                      </div>
                      <ul class="text-base left_side">
                        <li>
                          <a (click)="showAlbumModal(albumData)">
                            Rename album
                          </a>
                        </li>
                        <li>
                          <a (click)="deleteAlbumVisible(albumData._id)">
                            Delete album
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="albumData">
              <div class="title_sec">
                <h5><a (click)="goBack()"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></a>
                  &nbsp;&nbsp;{{currentAlbumName}}</h5>
              </div>

              <div class="empty_gallery no-data">
                <ng-container *ngIf="galleryLoading">
                  <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="!galleryLoading" class="gallery_result" nz-col infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="0" (scrolled)="onScrollAllImageFromAlbum()" [scrollWindow]="false">

                <div class="gallery_main">
                  <div class="gallery_grid">
                    <!--[class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia?.media)
                    && selectedGalleryFiles.length+postMedias.length>0
                    && ( (type === 'image' && (galleryMedia['file_type'] === 'video' || selectedGalleryFiles.length+postMedias.length >= 10)) || (type === 'video'))"-->
                    <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                           *ngFor="let galleryMedia of medialListFromAlbum;let i=index"
                           [class]="(this.selectedGalleryFile === galleryMedia?.media && page==='create_post') ? 'post-content-item selected' : 'post-content-item' "
                           [(ngModel)]="medialListFromAlbum[i]?.media.is_checked"
                           (ngModelChange)="selectMedias($event,galleryMedia?.media,medialListFromAlbum)"
                           [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia?.media)
                              && selectedGalleryFiles.length+postMedias.length >= 10">

                      <div class="gallery_grid_in">
                      <ng-container *ngIf="page !== 'create_post'">
                        <div class="hover_option">
                          <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                            <img src="../../../assets/images/icon-option.svg" alt="">
                          </div>
                          <ul>
                            <li (click)="selectImageFromAlbum(galleryMedia?.media,galleryMedia.length,$event)"><a href="javascript:;">View</a></li>
                            <li (click)="addToFavorite(galleryMedia?.media,$event)">
                              <a href="javascript:;">{{ galleryMedia?.media?.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                            </li>
                            <li (click)="addToAlbum(galleryMedia.media_id,$event)"><a href="javascript:;">Add to album</a></li>
                            <li (click)="deleteMedia(galleryMedia.media_id,$event)"><a href="javascript:;">Delete</a></li>
                          </ul>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="page === 'create_post'">
                        <div class="media_info">
                          <a href="javascript:;" (click)="selectImageFromAlbum(galleryMedia,galleryMedia.length,$event)">
                            <img src="../../../assets/images/info_icon_filled.svg" alt="">
                          </a>
                        </div>
                      </ng-container>

                        <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview [isVideo]="galleryMedia['file_type'] ==='video'" class="manage-post-video"
                        [thumbUrl]="galleryMedia.media_file_thumb">
                      </app-gallery-image-preview>
                      <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                        *ngIf=" galleryMedia['file_type'] ==='video'"></i>
                    </div>
                    </label>

                  </div>
                </div>

                <div class="text-center">
                  <div class="empty_gallery no-data">
                    <ng-template [ngIf]="scrollLoading">
                      <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                        <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <ng-container *ngIf="noGalleryData">
                  <span style="align-items: center;">
                    <app-empty [message]="'No Media Found !'"></app-empty>
                  </span>
                </ng-container>
              </div>
            </div>

          </div>
        </nz-tab>

        <!--Favorites-->
        <nz-tab nzTitle="Favorites" (nzClick)="getIsFavoriteGallery()">
          <!--if condition used for improve speed -->
          <ng-container *ngIf="currentTab===2">
            <!-- Favorite Image Listing Start -->
            <div class="empty_gallery no-data">
              <ng-container *ngIf="galleryLoading">
                <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                  <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!galleryLoading" class="gallery_result" nz-col infiniteScroll [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="0" (scrolled)="onScrollIsFav()" [scrollWindow]="false">
              <div class="gallery_main">
                <div class="gallery_grid">
                  <!-- [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(media)
                              && selectedGalleryFiles.length+postMedias.length>0
                              && ( (type === 'image' && (media['file_type'] === 'video' || selectedGalleryFiles.length+postMedias.length >= 10)) || (type === 'video'))" -->
                  <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                         *ngFor="let media of galleryFilesFav"
                         [class]="(this.selectedGalleryFile === media && page==='create_post') ? 'post-content-item selected' : 'post-content-item' "
                         [(ngModel)]="media.is_checked"
                         (ngModelChange)="selectMedias($event,media,galleryFilesFav)"
                         [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(media)
                            && selectedGalleryFiles.length+postMedias.length >= 10">

                    <div class="gallery_grid_in">
                      <ng-container *ngIf="page !== 'create_post'">
                        <div class="hover_option">
                          <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                            <img src="../../../assets/images/icon-option.svg" alt="">
                          </div>
                          <ul>
                            <li (click)="selectImageFromIsFav(media,$event)"><a href="javascript:;">View</a></li>
                            <li (click)="addToFavorite(media,$event)">
                              <a href="javascript:;">{{ media.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                            </li>
                            <li (click)="addToAlbum(media._id,$event)"><a href="javascript:;">Add to album</a></li>
                            <li (click)="deleteMedia(media._id,$event)"><a href="javascript:;">Delete</a></li>
                          </ul>
                        </div>
                      </ng-container>


                    <ng-container *ngIf="page === 'create_post'">
                      <div class="media_info">
                        <a href="javascript:;" (click)="selectImageFromIsFav(media,$event)">
                          <img src="../../../assets/images/info_icon_filled.svg" alt="">
                        </a>
                      </div>
                    </ng-container>

                      <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview
                        [isVideo]="media['file_type'] ==='video'" class="manage-post-video"
                        [thumbUrl]="media['media_file_thumb']">
                      </app-gallery-image-preview>
                      <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                      *ngIf=" media['file_type'] ==='video'"></i>

                  </div>
                  </label>
                </div>
              </div>
              <div class="text-center">
                <div class="empty_gallery no-data">
                  <ng-template [ngIf]="scrollLoading">
                    <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                      <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                    </div>
                  </ng-template>
                </div>
              </div>
              <ng-container *ngIf="noGalleryData">
                <span style="align-items: center;">
                  <app-empty [message]="'No Favorites Media Found !'"></app-empty>
                </span>
              </ng-container>
            </div>
            <!-- Favorite Image Listring end -->
          </ng-container>
        </nz-tab>

      </nz-tabset>
    </div>

    <div class="media-selected-wrap" *ngIf="page==='create_post' && selectedGalleryFile!== null ">
      <h2>Attachment Details</h2>
      <app-gallery-image-preview [class]="selectedGalleryFile?.file_type ==='video' ? 'manage-post-video':''"
        [isVideo]="selectedGalleryFile?.file_type ==='video'" [preview]="true" [imageUrl]="selectedGalleryFile?.media_file"
        [class]="'play'">
      </app-gallery-image-preview>
      <h4>{{ selectedGalleryFileMeta?.file_name}}</h4>
      <h4>{{ selectedGalleryFile?.created_at | date: 'MMM dd, y'}}</h4>
      <h4> Size : {{ selectedGalleryFileMeta?.file_size}}</h4>
      <h4 *ngIf="selectedGalleryFile?.file_type ==='video' && selectedGalleryFileMeta?.duration">
        Duration : {{ selectedGalleryFileMeta?.duration}}</h4>
      <h4> Resolution : {{ selectedGalleryFileMeta?.width +'px x ' + selectedGalleryFileMeta?.height + 'px' }}</h4>
    </div>
  </div>
  <div class="multi_preview_gallery" *ngIf="this.allAccountProvider.includes(this.sharedService.instagramProvider) && !(this.isSinglePlatformMode && this.singlePlatformProvider != this.sharedService.instagramProvider)">
    <button class="btn_primary_outline" (click)="cancel()">Cancel</button>
    <button class="btn_primary" (click)="cropMultipleImageManuallyFromGallery()" [disabled]="selectedGalleryFiles.length == 0">ok</button>
  </div>
</ng-container>
<!-- preview of media start -->

<nz-modal [(nzVisible)]="galleryModalisVisible" nzClassName="gallery_modal" [nzFooter]=null
  *ngIf="page!=='create_post'">
  <ng-container *nzModalContent>
    <div class="gallery_modal_header">
      <a href="javascript:;" (click)="galleryCloseModal()">
        <img src="../../../assets/images/icon-left-arrow.svg" alt="">
      </a>
    </div>
    <div class="gallery_modal_body">
      <div class="gallery_img_main">
        <nz-carousel [nzEffect]="effect" [nzDots]="false" [nzEnableSwipe]="false">
          <ng-container *ngIf="selectedGalleryFile">
            <div *ngIf="selectedGalleryFile !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedGalleryFile?.file_type ==='video'"
                  [imageUrl]="selectedGalleryFile?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedMediaFromAlbum">
            <div *ngIf="selectedMediaFromAlbum !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedMediaFromAlbum?.file_type ==='video'"
                  [imageUrl]="selectedMediaFromAlbum?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedMediaFromIsFav">
            <div *ngIf="selectedMediaFromIsFav !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedMediaFromIsFav?.file_type ==='video'"
                  [imageUrl]="selectedMediaFromIsFav?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
        </nz-carousel>
        <div class="nzSliderBtnWrapper">
          <button class="prevBtn" nz-button (click)="step('prev')" [disabled]="subIndex===0"><i nz-icon nzType="left"
              nzTheme="outline"></i></button>
          <button class="nextBtn" nz-button (click)="step('next')" [disabled]="subIndex === lastIndex-1"><i nz-icon
              nzType="right" nzTheme="outline"></i></button>
        </div>
      </div>
    </div>
    <div class="gallery_modal_footer">
      <ng-container *ngIf="isFavIcon ">
        <!-- && permissions.gallery_fav -->
        <a (click)="addIsFavorite(selectedGalleryFile?._id || selectedMediaFromAlbum?._id)">
          <ng-container *ngIf="(selectedGalleryFile?.is_favorite || selectedMediaFromAlbum?.is_favorite )===true;else NotFav">
            <img src="../../../assets/images/icon-heart-red.svg" alt="">
          </ng-container>
          <ng-template #NotFav>
            <img src="../../../assets/images/icon-heart.svg" alt="">
          </ng-template>
          <span>Favorite</span>
        </a>
      </ng-container>
      <ng-container *ngIf="isFavIconForFavMedia ">
        <!-- && permissions.gallery_fav -->
        <a (click)="addIsFavorite(selectedMediaFromIsFav._id)">
          <ng-container *ngIf="selectedMediaFromIsFav?.is_favorite===true;else NotFav">
            <img src="../../../assets/images/icon-heart-red.svg" alt="">
          </ng-container>
          <ng-template #NotFav>
            <img src="../../../assets/images/icon-heart.svg" alt="">
          </ng-template>
          <span>Favorite</span>
        </a>
      </ng-container>

      <a (click)="addToAlbum()" >
        <!-- *ngIf="permissions.gallery_albums" -->
         <img src="../../../assets/images/save-ai-image.svg" alt="">
        <span>{{addToAlbumTag}}</span>
      </a>

      <ng-container *ngIf="selectedMediaFromAlbum != null ; else removeMediaElsePart">
        <a (click)="removeMediaToAlbum(selectedMediaFromAlbum?._id )">
          <img src="../../../assets/images/remove-album.svg" alt="">
          <span>Remove from album</span>
        </a>
      </ng-container>
      <ng-template #removeMediaElsePart>
        <a (click)="deleteMedia(selectedGalleryFile?._id || selectedMediaFromIsFav?._id)">

        <!-- <a (click)="deleteMedia(selectedGalleryFile?._id || selectedMediaFromAlbum?.media_id ||  selectedMediaFromIsFav?._id)"> -->
          <!-- *ngIf="permissions.gallery_delete" -->
          <img src="../../../assets/images/icon-trash-one.svg" alt="">
          <span>{{deleteTag}}</span>
        </a>
      </ng-template>
    </div>
  </ng-container>
</nz-modal>
<!-- preview of media end -->

<!-- Start sort modal -->
<nz-modal [(nzVisible)]="visible" nzClassName="sort_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="sort_hdr">
      <h4>Sort by</h4>
    </div>
    <div class="sort_body">
      <div class="sort_option">

        <nz-radio-group [ngModel]="getFilterType()" (ngModelChange)="setFilterType($event)">
          <label nz-radio nzLabel="Newest" nzValue="DESC">Newest</label>
          <label nz-radio nzLabel="Oldest" nzValue="ASC">Oldest</label>
        </nz-radio-group>
      </div>
      <div class="btn_wrap">
        <button type="button" class="btn" (click)="close_sort_modal()">Cancel</button>
        <button type="button" class="btn btn_apply" (click)="filterDateChange()">Apply</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End sort modal -->

<!-- Start filter by modal -->
<nz-modal [(nzVisible)]="filterVisible" nzClassName="sort_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="sort_hdr">
      <h4>Filter by</h4>
    </div>
    <div class="sort_body">
      <div class="sort_option">
        <nz-radio-group [ngModel]="getFilterMediaType()" (ngModelChange)="setFilterMediaType($event)">
          <label nz-radio nzValue="" nzLabel="All">All</label>
          <label nz-radio nzValue="image" nzLabel="Image">Image</label>
          <label nz-radio nzValue="video" nzLabel="Video">Video</label>
          <label nz-radio nzValue="gif" nzLabel="GIF">GIF</label>
        </nz-radio-group>
      </div>
      <div class="btn_wrap">
        <button type="button" class="btn" (click)="close_filter_modal()">Cancel</button>
        <button type="button" class="btn btn_apply" (click)="filterFileTypeChange()">Apply</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End filter by modal -->


<!-- Start upload media -->
<nz-modal [(nzVisible)]="UploadModalisVisible" nzTitle="Upload Media" [nzClassName]="'upload-modal'"
  [nzOkDisabled]="loading" [nzCancelDisabled]="loading" [nzFooter]=null>
  <!-- (nzOnCancel)="handleCancel()" -->
  <ng-container *nzModalContent>
    <a class="close_modal" href="javascript:;" [class.disabled_while_loading]="isLoading" (click)="handleCancel()"><span
        nz-icon nzType="close" nzTheme="outline"></span></a>
    <div class="upload_img_msg">
      <img src="../../../assets/images/info_icon.svg" alt="">
      <p>Recommended video resolution is
        1280 x 720 px aspect ratio between 9:16,
        max. length 4 hours and max. size 2 GB</p>
    </div>
    <div class="upload_img_banner">
      <img src="../../../assets/images/upload_media.png" alt="">
    </div>
    <div class="upload_img_wrp">
      <ng-container>
        <div class="upload_img">
          <ng-container *ngIf="postMedias.length > 0">
            <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">

              <!-- <i class="icon" nz-icon nzType="close-circle" nzTheme="outline"
                (click)="deleteImage(i, image.media_file)"></i> -->
              <!-- <div class="icon" (click)="deleteImage(i, image.media_file)" > -->
              <button nz-button nzType="primary" class="icon" (click)="deleteImage(i, media.media_file)"
                [disabled]="isLoading">
                <img src="../../../assets/images/icon-close.svg" alt="">
              </button>
              <!-- </div> -->
              <!-- <app-gallery-media-preview [imageUrl]="image.media_file" [class]="'post-image uploaded-img'" -->

              <!-- </div> -->
              <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'"
                [isVideo]="media.file_type === 'video' ? true : false">
              </app-gallery-image-preview>
            </div>
          </ng-container>

          <div class="upload_file_main" [class.emptyUpload]="postMedias.length === 0 && currentUploading.length === 0"
            *ngIf="postMedias.length !== maxLength">
            <nz-upload nzName="post" nzListType="picture-card" #userPhoto  [nzShowUploadList]="false"  [nzRemove]="handleRemove"
              [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzDisabled]="isLoading"
              *ngIf="currentUploading.length === 0" (nzChange)="onChangeEventUpload($event)" [nzAccept]="supportedFileTypes.toString()">
              <!-- [nzLimit]="maxLength" -->
              <ng-container>
                <div class="ant-upload-text">
                  <img src="../../../assets/images/upload_media_icon.png" alt="">
                  <span>Add Media</span>
                </div>
              </ng-container>
            </nz-upload>
            <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzAccept]="supportedFileTypes.toString()"
              *ngFor="let uploading of currentUploading">
              <ng-container>
                <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
                  *ngIf="uploading.loading"></nz-progress>
                <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
              </ng-container>
            </nz-upload>
          </div>

        </div>
      </ng-container>
    </div>
    <div class="btn_wrap">
      <button class="btn" nz-button nzType="primary" (click)="handleOk()" [disabled]="loading">
        {{ albumId ? 'Add to Album' : 'Add to Gallery' }}
        <ng-container *ngIf="isLoading && loadingPostType==='addGallery'">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>
<!-- End upload media -->

<!-- Create Album modal start  -->
<nz-modal nzClassName="organization_form_modal" [(nzVisible)]="openCreateAlbumModal" nzTitle="{{modalTitle}}"
  [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="create-team-modal">
      <form nz-form [formGroup]="albumForm">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Album Name</nz-form-label>
          <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Album name is required">
            <input nz-input formControlName="album_name" placeholder="Album Name" required />
          </nz-form-control>
        </nz-form-item>
      </form>
      <div class="organization_form_action">
        <span *ngIf="editAlbumId">Are you sure you want to make changes?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="closeAlbumModal()"
            [disabled]="isLoading">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="saveAlbum()"
            [disabled]="isLoading">{{modalButton}}
            <ng-container *ngIf="isLoading && loadingPostType==='albumChange'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- Create Album modal end  -->

<!-- Add to album modal start -->
<nz-modal nzClassName="add_to_album_modal" [(nzVisible)]="addToAlbumVisible" nzTitle="{{addAlbumTitle}}"
  [nzFooter]=null (nzOnCancel)="closeAlbumModal()">
  <ng-container *nzModalContent>
    <div class="create-team-modal">

      <div *ngIf="albumList.length === 0 && !galleryLoading && !newAlbum">
        <app-empty [message]="'No Album Found !'"></app-empty>
      </div>

      <ng-container *ngIf="!newAlbum">
        <div class="create_new_album">
          <div class="action_wrapper">
            <a href="javascript:;" (click)="createNewAlbum()">
               <img src="../../../assets/images/icon-plus-xs.svg" alt="">
              <span>Create New Album</span>
            </a>
          </div>
        </div>
        <ng-container *ngIf="isAlbumListLoadning">
          <div class="msg_list_skeleton">
            <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                         *ngFor="let hashtag of staticConversationHash">
            </nz-skeleton>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAlbumListLoadning">
          <perfect-scrollbar style="max-height: 300px">
            <!--<ng-container *ngFor="let addToAlbum of albumList">
              <nz-radio-group class="org_optn_list_wrap" [(ngModel)]="albumId">
                <div class="org_optn_list">
                  <div class="org_optn_list_in">
                    <div class="org_optn_list_in_left">
                      <label nz-radio [nzValue]="addToAlbum?._id" [nzDisabled]="isLoading">
                        <div class="org_optn_list_main">
                          <div class="org_optn_list_right">
                            <h3> {{ addToAlbum?.album_name }}</h3>
                          </div>

                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </nz-radio-group>
            </ng-container>-->
            <!--[nzValue]="album?._id"-->

            <div class="album_list_wrap">
              <ng-container *ngFor="let album of albumList">
                <label nz-checkbox [(ngModel)]="album.is_checked" (ngModelChange)="selectAlbum($event)"
                       [nzDisabled]="isLoading || albumId && album._id === albumId">
                  <div class="org_optn_list_main">
                      <h3>{{ album?.album_name }}</h3>
                  </div>
                </label>
              </ng-container>
            </div>
          </perfect-scrollbar>
        </ng-container>


        <div class="organization_form_action" *ngIf="albumList.length>0">
          <span>Are you sure you want to add media into this album?</span>
          <div class="btn_group">

            <button nz-button nzType="primary" class="btn" (click)="closeAddAlbumModal()"
              [disabled]="isLoading">Cancel</button>

            <button nz-button nzType="primary" class="btn btn_apply" (click)="addToAlbumSubmit($event)"
                    [disabled]="isLoading || selectedAlbumIds.length===0">
                Add To Album
                <ng-container *ngIf="isLoading && loadingPostType==='addToAlbum'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
            </button>

          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="newAlbum">
        <div class="new_album_form">
          <form nz-form [formGroup]="albumForm">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Album Name</nz-form-label>
              <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Album name is required">
                <input nz-input formControlName="album_name" placeholder="Album Name" required />
              </nz-form-control>
            </nz-form-item>
          </form>

          <div class="form_action">
            <button nz-button nzType="primary" class="btn" (click)="closeAlbumModal()"
              [disabled]="isLoading">Cancel</button>
            <button nz-button nzType="primary" class="btn btn_apply" (click)="saveAlbum()"
            [disabled]="isLoading">{{modalButton}}
              <ng-container *ngIf="isLoading && loadingPostType==='albumChange'">
                <span nz-icon nzType="loading"></span>
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>



    </div>

  </ng-container>
</nz-modal>
<!-- Add to album modal end -->

<!-- delete album start -->
<nz-modal [(nzVisible)]="albumDeletevisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete this Album?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - when you delete this Album,
        all associated Images will be removed from this Album. </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to delete Album?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteAlbumModalClose()"
            [disabled]="isLoading">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="deleteAlbum(albumDeleteId)"
            [disabled]="isLoading">Yes, Delete It
            <ng-container *ngIf="isLoading && loadingPostType==='deleteAlbum'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- delete album end  -->

<!-- delete image from carosal preview start  -->
<nz-modal [(nzVisible)]="deleteImagevisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete this Media?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - <b>Are you sure you want to delete this media?</b> </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to delete Media?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteImageModalClose()"
            [disabled]="isLoading">Cancel</button>
          <button type="button" nz-button class="btn btn_apply" (click)="deleteImageSelected(deleteImageFromSelected)"
            [disabled]="isLoading">Yes, Delete It
            <ng-container *ngIf="isLoading && loadingPostType ==='deleteMedia'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- delete image from carosal preview end  -->

<!-- Error Message -->
<ng-container *ngIf="error !== null">
  <div class="text-center error-color">
    <span>{{error}}</span>
  </div>
</ng-container>

<!--Crop Tool-->
<ng-container *ngIf="(selectedImage || selectedImageURL) && page==='create_post'">
  <div class="img_crop_wrapper">
    <div class="selected_images_wrapper" *ngIf="isDirectMultiUpload">
      <div class="crop_info">
        <img src="../../../assets/images/crop-guid.png" alt="">
        <div class="crop_info_msg">
          <h5>Image Selection and Cropping Guide</h5>
          <p>Please select each image to crop. After you have cropped each image, then click "OK".</p>
        </div>
      </div>
      <div class="selected_images_title">
        <span>Selected Images</span>
      </div>
      <div class="selected_images">
        <ng-container *ngIf="imagePreviews.length > 0">
          <div class="upload_img_in" [class.active]="i === selectedIndex" *ngFor="let image of imagePreviews; let i = index">
            <ng-container *ngIf="image; else skeleton">
              <img [src]="image.url" alt="" (click)="onImageClick(i)"/>
            </ng-container>
            <ng-template #skeleton>
              <div class="default_skeleton">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <div class="image_radio">
        <div class="radio_title">
          <span>Aspect Ratio</span>
        </div>
        <div class="btn-group d-flex flex-nowrap crop_btns" data-toggle="buttons" *ngIf="cropTool" [class.disabled_while_loading]="!cropImgLoaded">
          <div class="aspec-ratio-btn-wrapper">
            <nz-space>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===1 ? 'primary' : 'default'"
                (click)="changeAspectRatio(1)">
                <img src="../../../assets/images/aspect_ratio_1_1.png">
                <span>1:1</span>
              </button>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===1.91 ? 'primary' : 'default'"
                (click)="changeAspectRatio(1.91)">
                <img src="../../../assets/images/aspect_ratio_1_91.png">
                <span>1.91:1</span>
              </button>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.8 ? 'primary' : 'default'"
                (click)="changeAspectRatio(0.8)">
                <img src="../../../assets/images/aspect_ratio_4_5.png">
                <span>4:5</span>
              </button>

              <ng-container *ngIf="instagramType==='story_post'">
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.5625 ? 'primary' : 'default'"
                        (click)="changeAspectRatio(0.5625)">
                  <img src="../../../assets/images/aspect_ratio_4_5.png">
                  <span>9:16</span>
                </button>
              </ng-container>

            </nz-space>

            <div *ngIf="!isValidAspectRatio" class="error-color upload_img_error">
              <img src="../../../assets/images/error_icon.svg" alt="">
              <span>
                Instagram will not allow a mismatched crop ratio. Please choose another crop ratio.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="img_cropper_wrapper">
      <div class="img-crop-wrap cropTool">

        <div class="title_sec">
          <img src="../../../assets/images/crop.png" alt="">
          <span>Crop</span>
        </div>

        <div class="crop_loader" *ngIf="cropTool && !cropImgLoaded">
          <img [src]="imageLoading">
        </div>
        <image-cropper
          *ngIf="cropTool"
          [imageBase64]="selectedImageBase64"
          [imageFile]="selectedImage"
          [imageURL]="selectedImageURL"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="false"
          [aspectRatio]="aspectRatio" format="jpeg"
          [cropperMinWidth]="200" class="img-cropper-wrap"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoadedForCrop($event)"
          crossorigin="anonymous"></image-cropper>

        <div class="image_radio" style="margin-top: 15px;" *ngIf="!isDirectMultiUpload">
          <div class="btn-group d-flex flex-nowrap text-center crop_btns" data-toggle="buttons" *ngIf="cropTool" [class.disabled_while_loading]="!cropImgLoaded">
            <div class="aspec-ratio-btn-wrapper">
              <nz-space>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===1 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(1)">
                  <img src="../../../assets/images/aspect_ratio_1_1.png">
                  <span>1:1</span>
                </button>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===1.91 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(1.91)">
                  <img src="../../../assets/images/aspect_ratio_1_91.png">
                  <span>1.91:1</span>
                </button>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.8 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(0.8)">
                  <img src="../../../assets/images/aspect_ratio_4_5.png">
                  <span>4:5</span>
                </button>

                <ng-container *ngIf="instagramType==='story_post'">
                  <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.5625 ? 'primary' : 'default'"
                          (click)="changeAspectRatio(0.5625)">
                    <img src="../../../assets/images/aspect_ratio_4_5.png">
                    <span>9:16</span>
                  </button>
                </ng-container>

              </nz-space>

              <div *ngIf="!isValidAspectRatio" class="error-color upload_img_error">
                <img src="../../../assets/images/error_icon.svg" alt="">
                <span>
                  Instagram will not allow a mismatched crop ratio. Please choose another crop ratio.
                </span>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isDirectMultiUpload">
          <div class="btn_crop" [class.disabled_while_loading]="!cropImgLoaded"  style="margin-top: 15px;">
            <!-- *ngIf="cropImgLoaded" -->
            <button class="btn_primary" (click)="multipleCropImage()">Crop</button>
          </div>
        </ng-container>

      </div>
    </div>
  </div>



  <!-- <div class="img-crop-button" *ngIf="cropTool">
      <button nz-button nzType="default" (click)="cancelCrop()" *ngIf="!isDirectUpload && !isEditImage" [class.disabled_while_loading]="!cropImgLoaded">
        <i nz-icon nzType="arrow-left" nzTheme="outline"></i> Back to Gallery
      </button>
      <button *ngIf="isDirectUpload" nz-button nzType="primary" [class.disabled_while_loading]="!cropImgLoaded" (click)="cancel()">Cancel</button>
      <ng-container *ngIf="isDirectMultiUpload; else elseContainer">
        <button nz-button nzType="primary" (click)="cropMultipleImageManually()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">ok</button>
      </ng-container>
      <ng-template #elseContainer>
        <button nz-button nzType="primary" (click)="cropImage()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">Crop</button>
      </ng-template>
  </div> -->

  <div class="img-crop-button" *ngIf="cropTool">
    <button nz-button nzType="default" (click)="cancelCrop()" *ngIf="!isDirectUpload && !isEditImage && !isGalleryMultiUpload" [class.disabled_while_loading]="!cropImgLoaded">
      <i nz-icon nzType="arrow-left" nzTheme="outline"></i> Back to Gallery
    </button>
    <ng-container *ngIf="hideMultiFirstAction">
      <button *ngIf="isDirectUpload || isGalleryMultiUpload" class="btn_primary_outline" [class.disabled_while_loading]="!cropImgLoaded" (click)="cancel()">Cancel</button>
      <ng-container *ngIf="isDirectMultiUpload; else elseContainer">
        <button nz-button nzType="primary" (click)="cropMultipleImageManually()" [class.disabled_while_loading]="!isValidAspectRatio || !cropImgLoaded" [disabled]="!isValidAspectRatio">ok</button>
      </ng-container>
      <ng-template #elseContainer>
        <button class="btn_primary" (click)="cropImage()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">Crop</button>
      </ng-template>
    </ng-container>
  </div>

  <div class="img-crop-button" *ngIf="!cropTool && !isDirectMultiUpload">
      <button nz-button nzType="default" (click)="cancelCrop()">Cancel</button>
      <button nz-button nzType="primary" (click)="cropImageManually()">Ok</button>
  </div>

  <div class="generating_message">
    <div class="message_wrapper">
      <img src="../../../assets/images/upload-file.gif" style="height: 150px;" alt="">
      <h5>Your upload is on its way!</h5>
      <p>This will only take a moment...</p>
    </div>
    <div class="message_progress">
      <div class="progress-wrapper">
        <span class="progress-bar"></span>
      </div>
    </div>
  </div>

  <div class="ready_to_post">
    <img src="../../../assets/images/uploaded-done.png" alt="">
    <h5>Your media is added to post.</h5>
    <div class="allow_once">
      <label nz-checkbox [(ngModel)]="allowOnce" (ngModelChange)="onCheckboxChange($event)">Don't show this again!</label>
    </div>
  </div>

</ng-container>

<!--Cropped Preview-->
<!-- <ng-container *ngIf="isCropped && !selectedImage && !selectedImageURL && page==='create_post'">
  <div class="upload_img text-center">
    <ng-container *ngIf="postMedias.length > 0">
      <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
        <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'" [isVideo]="false">
        </app-gallery-image-preview>
      </div>
    </ng-container>
    <div *ngIf="postMedias.length !== maxLength" class="text-center upload_img_in">
      <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
        [nzDisabled]="loading" *ngFor="let uploading of currentUploading" [nzMultiple]="true">
        <ng-container>
          <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
                  *ngIf="uploading.loading"></nz-progress>
          <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
        </ng-container>
      </nz-upload>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="!isGalleryMultiUpload && !isForUpdate && !isCropped && (page==='create_post' || page === 'bulk_post')">
  <div class="upload_img_wrp" [ngClass]="{upload_img_wrp_height: !isForUpdate}">

    <!--Image-->
   <!-- <ng-container *ngIf="type === 'image'">
      <div class="fef">
        <ng-container *ngIf="postMedias.length > 0">
          &lt;!&ndash; Drap Drop &ndash;&gt;
            <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">

              <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
                <div class="upload_img_action">
                  <i nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Crop" nzTheme="outline" (click)="editImage(i, media.media_file)"
                    [class.disabled_while_loading]="isPosting" *ngIf="page !== 'bulk_post'"></i>
                  <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                    (click)="deleteImage(i, media.media_file)" *ngIf="!isFeedPost" [class.disabled_while_loading]="isPosting"></i>
                </div>
                <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img test-imggg'" [isVideo]="false">
                </app-gallery-image-preview>
              </div>
            </div>
        </ng-container>
      </div>
    </ng-container>-->

    <!--gif-->
    <!--<ng-container *ngIf="type === 'gif'">
      <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">
        <ng-container *ngIf="postMedias.length > 0">
          <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
            <div class="upload_img_action">
              <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                (click)="deleteImage(i, postMedias[i])" *ngIf="!isFeedPost" [class.disabled_while_loading]="isPosting"></i>
            </div>
            <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'" [isVideo]="false">
            </app-gallery-image-preview>
          </div>
        </ng-container>
        <div *ngIf="postMedias.length !== maxLength && isForUpdate" class="text-center upload_img_in">
          <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
            [nzDisabled]="loading">
            <ng-container>
              <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
              <div class="ant-upload-text">Upload</div>
            </ng-container>
          </nz-upload>
        </div>
      </div>
    </ng-container>-->

    <!--video-->
    <!--<ng-container *ngIf="type === 'video'">
      <div class="upload_video">
        <ng-container *ngIf="postMedias.length > 0">
          <div class="upload_video_in">


            <div class="hover_option" [class.disabled_while_loading]="isPosting">
              <div class="hover_option_icon">
                <img src="../../../assets/images/option_dots.svg" alt="">
              </div>
              <ul>
                <li>
                  <a href="javascript:;" (click)="deleteImage(0, images[0])">
                    Remove
                  </a>
                </li>
              </ul>
            </div>

            <app-gallery-image-preview [imageUrl]="images[0]" [class]="'post-video'" [isVideo]="true">
            </app-gallery-image-preview>
          </div>
        </ng-container>
        <div *ngIf="postMedias.length !== maxLength && isForUpdate" class="text-center upload_img_in">
          <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
            [nzDisabled]="loading">
            <ng-container>
              <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
              <div class="ant-upload-text">Upload</div>
            </ng-container>
          </nz-upload>
        </div>
      </div>
    </ng-container>-->


    <!--multi-->
    <!--*ngIf="type === 'multi'"-->
    <ng-container>
      <div class="fef">
        <ng-container *ngIf="postMedias.length > 0">
          <!-- Drap Drop -->
          <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">

            <div class="upload_img_in multi_image_upload" *ngFor="let media of postMedias; let i = index"
                 [ngClass]="{'is_img_ratio_valid': media?.isValid === false}" >

              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="page !== 'bulk_post' && media.file_type!=='video'" >
                    <a href="javascript:;" (click)="editImage(i, media.media_file)">
                      Crop
                    </a>
                  </li>
                  <li *ngIf="!isFeedPost">
                    <a href="javascript:;" (click)="deleteImage(i, media.media_file)">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>


              <a *ngIf="page !== 'bulk_post' && postMedias.length > 0 && media.file_type!=='video'" href="javascript:;"
                 class="btn_hash_tag" nz-tooltip nzTooltipTitle="Hashtag Detection" (click)="hashtagGenerate(i, media.media_file,false)"
                 [class.disabled_while_loading]="isPosting">
                 <!-- <span class="beta-tag">Beta</span> -->
                <img src="../../../assets/images/ai-image-icon.svg" alt="">
              </a>


              <!-- <a *ngIf="page !== 'bulk_post' && postMedias.length > 0 && media.file_type==='video'" href="javascript:;"
                 class="btn_hash_tag" nz-tooltip nzTooltipTitle="Hashtag Detection" (click)="hashtagGenerate(0,media.media_file,true)"
                 [class.disabled_while_loading]="isPosting">
                <img src="../../../assets/images/ai-image-icon.svg" alt="">
              </a> -->

              <div class="img_ratio_warning">
                <span nz-icon nzType="warning" nzTheme="fill"></span>
              </div>

              <app-gallery-image-preview *ngIf="media.file_type==='image'"
                      [imageUrl]="media.media_file" [class]="'post-image uploaded-img '"
                      [isVideo]="false"></app-gallery-image-preview>

              <app-gallery-image-preview *ngIf="media.file_type==='video'"
                      [imageUrl]="media.media_file" [class]="'manage-post-video'"
                      [isVideo]="true"></app-gallery-image-preview>
            </div>

          </div>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <!--YouTube Thumbnail-->
  <!--*ngIf="allAccountProvider.includes(sharedService.youtubeProvider) && (type === 'video' || type==='multi')"-->
  <ng-container *ngIf="!isSinglePlatformMode && allAccountProvider.includes(sharedService.youtubeProvider) && postMedias.length > 0 && postMedias[0].file_type=== 'video'">
    <div class="upload_img_wrp yt_thumbnail">
      <div class="margin-top-2">
        <h3>YouTube Video Thumbnail</h3>
        <div class="upload_img" [class.disabled_while_loading]="isPosting">
          <ng-container *ngIf="youtubeThumbnails?.length > 0">
            <div class="upload_img_in multi_image_upload" *ngFor="let image of youtubeThumbnails; let i = index">

              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="!isFeedPost">
                    <a href="javascript:;" (click)="deleteThumbnailImage(i, images[i])">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>

              <!-- <div class="upload_img_action">
                <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                  (click)="deleteThumbnailImage(i, postMedias[i])" [class.disabled_while_loading]="isPosting"></i>
              </div>
              -->
              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </div>
        <nz-upload *ngIf="youtubeThumbnails?.length === 0" nzName="post" nzListType="picture-card"
          [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadThumbnail" [nzDisabled]="loading"
          [class.disabled_while_loading]="isPosting" [nzAccept]="supportedThumbnailFileTypes.toString()">
          <ng-container>
            <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'" *ngIf="loading"></i>
            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!loading" alt="">
            <div class="ant-upload-text">Upload</div>
          </ng-container>
        </nz-upload>
      </div>
    </div>
  </ng-container>

  <!-- && (type === 'video' || type==='multi')-->
  <!--Instagram Reels Thumbnail-->
  <ng-container *ngIf="!isSinglePlatformMode && allAccountProvider.includes(sharedService.instagramProvider)
    && instagramType ==='reel_post' && postMedias[0].file_type=== 'video'">
    <div class="upload_img_wrp yt_thumbnail">
      <div class="margin-top-2">
        <h3>Instagram Reel Thumbnail</h3>
        <div class="upload_img" [class.disabled_while_loading]="isPosting">
          <ng-container *ngIf="instaReelThumbnails?.length > 0">
            <div class="upload_img_in multi_image_upload" *ngFor="let image of instaReelThumbnails; let i = index">
              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="!isFeedPost">
                    <a href="javascript:;" (click)="deleteThumbnailImage(i, sharedService.instagramProvider)">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>
              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </div>
        <nz-upload *ngIf="instaReelThumbnails?.length === 0" nzName="post" nzListType="picture-card"
                   [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadInstagramReelThumbnail"
                   [nzDisabled]="isInstaThumbLoading"
                   [class.disabled_while_loading]="isPosting"
                   [nzAccept]="supportedThumbnailFileTypes.toString()">
          <ng-container>
            <i class="upload-icon" nz-icon [nzType]="isInstaThumbLoading ? 'loading' : 'plus'" *ngIf="isInstaThumbLoading"></i>
            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!isInstaThumbLoading" alt="">
            <div class="ant-upload-text">Upload</div>
          </ng-container>
        </nz-upload>
      </div>
    </div>
  </ng-container>



<!-- remove image from album start  -->
<nz-modal [(nzVisible)]="removeMediaFromAlbum" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Remove this Media?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - <b>Are you sure you want to remove this media?</b> </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to remove Media?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="cancelRemoveMideaFromAlbum()" [disabled]="isLoading">
            Cancel
          </button>
          <button type="button" nz-button class="btn btn_apply" (click)="removeSelectedMediaFromAlbum()" [disabled]="isLoading">
            Yes, Remove It
            <ng-container *ngIf="isLoading ">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- remove image from album end  -->
</ng-container>
